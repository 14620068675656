import { renderMoney } from 'client/tools';
import t from 'i18n';

export const getPieOptions = (data = [], options = {}) => ({
	title: {
		text: options.title || '',
		left: 'center',
		subtext: data[0] ? renderMoney(data[0].total, 2) : '0',
		subtextStyle: {
			fontWeight: 'bold',
			fontSize: 16,
			color: 'black',
		},
		itemGap: 115,
	},

	tooltip: {
		trigger: 'item',
	},
	series: [
		{
			type: 'pie',
			radius: ['70%', '90%'],
			itemStyle: {
				borderRadius: 4,
				borderColor: '#fff',
				borderWidth: 1,
			},
			label: {
				show: false,
			},
			data: data,
		},
	],
});

export const getChartOptions = (bankAccountData = []) => ({
	title: {
		text: !bankAccountData[0] ? t('operation.notFound') : '',
		left: 'center',
		top: '30%',
	},
	xAxis: {
		type: 'time',
	},

	yAxis: {
		type: 'value',

		max: function (value) {
			return Math.floor(value.max / 1000000) * 1000000 * 1.2;
		},
	},
	tooltip: {
		trigger: 'axis',
	},

	series: bankAccountData.map((i, index) => ({
		type: 'line',
		smooth: true,
		showSymbol: false,
		name: i.bankAccount?.name || i.bankAccount?.bank || t('total'),
		data: i.data.map(({ balance, date }, index) => [new Date(date).getTime() + 1, balance]),
	})),
	legend: {
		left: 0,
	},
	dataZoom: [
		{
			type: 'inside',
			start: 0,
			end: 100,
		},
		{
			start: 0,
			end: 10,
		},
	],
	textStyle: {
		fontSize: 8,
	},
});

