import React from 'react';
import { observer } from 'mobx-react';
import { Field, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import { CreateButton } from '../../../../components/buttons';
import { withRouter } from 'react-router';
import t from 'i18n';
import store from 'client/store';

@withRouter
@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.setContragentId = this.setContragentId.bind(this);
	}

	setContragentId(record) {
		record['contragentId'] = this.props.contragentId;
		return record;
	}

	renderButtons = (path) => <CreateButton text={t('cancel')} variant='default' url={path} />;

	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);
		const buttons = this.renderButtons(path);

		return (
			<div className='fixed-page'>
				<ModelEdit
					beforeSave={this.setContragentId}
					model={store.model[modelName]}
					id={id}
					path={path}
					title='Банковские реквизиты'
					noDelete
					controls={buttons}
				>
					<div className='row'>
						<div className='col-4'>
							<Field property='personName' label='ФИО' />
							<Field property='personRole' label='Должность' />
							<div className='phone-email'>
								<Field property='phone' label='Телефон' />
								<Field property='email' label='E-mail' />
							</div>
							<Field property='comment' label='Комментарий'>
								<textarea />
							</Field>
						</div>
					</div>
				</ModelEdit>
			</div>
		);
	}
}
