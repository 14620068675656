import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { RecordSelect, Checkbox, DatePicker, ManyToManySelect } from '@smartplatform/ui';
import { DEFAULT_PRIORITY } from 'client/constants';
import { Attachments, BoxLabel, DeleteButton } from 'components';
import { renderStatus, renderUser } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('taskStore')
@observer
export default class SideBar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.taskStore;
		this.task = this.store.task;
	}

	onBoardListChange = async (boardList) => {
		const topTask = await store.model.Task.find({
			where: { boardListId: boardList.id, priority: { neq: null } },
			fields: ['id', 'priority'],
			order: 'priority desc',
			limit: 1,
		});
		this.task.priority = topTask.length > 0 ? topTask[0].priority * 2 : DEFAULT_PRIORITY;
		this.task.boardList = boardList;
		this.task.closed = boardList.closing;
		if (boardList.closing) this.task.closedAt = new Date();
		await this.store.onSave?.(['boardListId', 'priority', 'closed', 'closedAt']);
	};

	onChange = (prop) => async (value) => {
		this.task[prop] = value;
		await this.store.onSave?.([prop]);
	};

	onAttachmentsChange = async () => await this.store.onSave?.(['updatedAt']);

	delete = async () => {
		await this.task.delete();
		store.route.push({ path: `/projects/${this.task.projectId}` });
	};
	renderLabel = (label) => <BoxLabel label={label} />;

	render() {
		const { task, onUserChange, onLabelChange } = this.store;
		const isOwner = store.model.user.id === task.ownerId;
		const isEditable = store.isAdmin || isOwner;
		const listsIds = task.project ? task.project.lists().map((list) => list.id) : [];
		const membersIds = task.project ? task.project.members().map((m) => m.userId) : [];

		return (
			<div className='task-sidebar'>
				<div className='sidebar-content'>
					<div className='name-project'>
						<label>{t('project.title')}</label>
						{task.project ? (
							<Link to={`/projects/${task.projectId}`}>{task.project.name}</Link>
						) : (
							<div className='error'>
								<FontAwesomeIcon icon={faExclamationTriangle} /> {t('task.noProject')}
							</div>
						)}
					</div>
					<div className='sidebar-content'>
						<div className='sidebar__main-information'>
							<div className='user-boardList'>
								<div className='user block'>
									<label>{t('task.assignedTo')}</label>
									<RecordSelect
										model={store.model.User}
										value={task.user}
										onChange={onUserChange}
										computed={renderUser}
										showValue={
											task.user ? (
												renderUser(task.user)
											) : (
												<span className='hint'>{t('document.card.responsible')}</span>
											)
										}
										searchFields={['username', 'lastName', 'firstName', 'middleName']}
										filter={{ where: { id: { inq: membersIds } }, order: 'lastName asc, firstName asc, username asc' }}
										width='100%'
									/>
								</div>
								<div className='boardList block'>
									<label>{t('task.boardList')}</label>
									<RecordSelect
										model={store.model.BoardList}
										value={task.boardList}
										onChange={this.onBoardListChange}
										computed={renderStatus}
										searchFields={['name']}
										filter={{ where: { id: { inq: listsIds } }, order: 'priority desc' }}
										isRequired
										width='100%'
									/>
								</div>
							</div>
							<div className='date-interval'>
								{['startDate', 'dueDate'].map((name) => (
									<div className='block' key={name}>
										<label>{t('task.' + name)}</label>
										<DatePicker
											value={task[name]}
											onChange={this.onChange(name)}
											showTimeSelect={true}
											placeholder={t('datePickerPlaceholder')}
										/>
									</div>
								))}
							</div>
							<div className='block'>
								<Checkbox value={task.private} onChange={this.onChange('private')} label={t('private')} />
							</div>
							<div className='block'>
								<label>{t('label.plural')}</label>
								<ManyToManySelect
									record={task}
									relation='labels'
									computed={this.renderLabel}
									itemTag={this.renderLabel}
									label={t('labels')}
									className='box-labels-multiselect'
									immediatelySave
									noSearch
									onChange={onLabelChange}
								/>
							</div>
						</div>

						<div className='attachment-files'>
							<Attachments record={task} canUpload={true} onChange={this.onAttachmentsChange} />
						</div>
						{isEditable && (
							<div className='task-delete-block'>
								<div className='task-delete'>
									<DeleteButton
										onConfirm={this.delete}
										confirmMessage={t('task.confirmDelete')}
										text={t('task.delete')}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

