import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	return <ModelList model={store.model[modelName]} path={path} match={match} title={t('client.plural')}>
		<Column width={200} property="name" label={t('name')} />
		<Column property='personName' label={t('client.personName')} />
		<Column property='personRole' label={t('client.personRole')} />
		<Column property='phone' label={t('phone')} />
		<Column property='email' label={t('email.title')} />
		<Column property='comment' label={t('comment.title')} />
	</ModelList>;
};
