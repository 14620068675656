import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faInfoCircle,
	faBalanceScale
} from "@fortawesome/free-solid-svg-icons";
import Info from "./info";
import Balance from "./balance";
import { default as translate } from 'i18n';

const t = slug => translate(`contragents.card.tabs.${slug}`);

export default path => ([
	{
		title: <><FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;Общая информация</>,
		path: `${path}/:nomenclatureId`,
		exact: true,
		component: Info
	},
	{
		title: <><FontAwesomeIcon icon={faBalanceScale} />&nbsp;&nbsp;Товародвижение</>,
		path: `${path}/:nomenclatureId/balance`,
		component: Balance,
	}
]);