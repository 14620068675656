import React from 'react';
import { RecordSelect, Select, DatePicker, GroupSelect } from '@smartplatform/ui';
import { CleanButton } from 'components';
import { PERIODS } from '../constants';
import appStore from 'client/store';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { renderBankAccount } from 'client/tools/renders';

@inject('store')
@observer
export class Filters extends React.Component {
	render() {
		const { store } = this.props;
		const { onChange, contragent, bankAccounts, cleanFilters, cashFlows, period, onPeriodChange, project, myCompanies } = store;

		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;

		const showBankAccountValue = bankAccounts.length ? (
			t('payment.selectedBankAccounts') + ': ' + bankAccounts.length
		) : (
			<span className='placeholder'>{t('payment.allBankAccounts')}</span>
		);

		const showCashFlow = cashFlows.length ? (
			t('payment.selectedCashFlows') + ': ' + cashFlows.length
		) : (
			<span className='placeholder'>{t('payment.allCashFlows')}</span>
		);

		const selectProps = { noTotal: true, maxItems: 10, itemsPerPage: 1000, width: 200 };
		return (
			<div className='filters'>
				<Select
					key={period}
					items={PERIODS.map((name, i) => ({ value: name, label: t('datePeriod.' + name) }))}
					value={period}
					onChange={onPeriodChange}
					showValue={period ? t('datePeriod.' + period) : t('datePeriod.select')}
					noSearch
					isRequired
					width={200}
				/>
				{period === 'specified' &&
					['startDate', 'endDate'].map((date) => <DatePicker key={date} value={store[date]} onChange={onChange(date)} />)}

				<GroupSelect
					groups={myCompanies.map((item) => ({ title: item.name, items: item.bankAccounts() }))}
					selected={bankAccounts}
					onChange={onChange('bankAccounts')}
					computed={renderBankAccount}
					showValue={showBankAccountValue}
					width={200}
					comparator={(a, b) => a.id === b.id}
				/>
				<RecordSelect
					model={appStore.model.Contragent}
					showValue={showValue(t('payment.allContragents'), contragent?.name)}
					property='name'
					onChange={onChange('contragent')}
					filter={{ order: 'name asc', where: { or: [{ myCompany: false }, { myCompany: null }] } }}
					value={contragent}
					width={200}
				/>
				<RecordSelect
					model={appStore.model.Project}
					showValue={showValue(t('payment.allProjects'), project?.name)}
					property='name'
					onChange={onChange('project')}
					value={project}
					width={200}
				/>
				<RecordSelect
					{...selectProps}
					model={appStore.model.CashFlow}
					property='name'
					onChange={onChange('cashFlows')}
					filter={{ order: 'name asc' }}
					value={cashFlows}
					showValue={showCashFlow}
				/>
				<CleanButton onClick={cleanFilters} />
			</div>
		);
	}
}

