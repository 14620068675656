import React from 'react';
import { observer } from 'mobx-react';
import { RecordSelect, Select, Pager, ButtonGroup, Button } from '@smartplatform/ui';
import TaskItem from './TaskItem';
import { TasksStore } from './store';
import { ALL, CLOSED_BUTTONS_ITEMS, NONE, ORDER_BUTTONS_ITEMS } from './constants';
import { renderStatus, renderUser, renderPlaceholder } from 'client/tools';
import { CleanButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('tasks');
		this.store = new TasksStore();
		this.store.fetchRecords();
	}

	userComparator = (a, b) => a === b;

	render() {
		const { records, page, order, boardLists, closed, user, onChange, cleanFilters } = this.store;
		const showUserValue = renderPlaceholder(
			this.store.user === ALL ? t('task.All') : this.store.user === NONE ? t('task.notAssigned') : undefined
		);
		const showBoardListValue = boardLists.length
			? `${t('boardList.selected')} : ${boardLists.length}`
			: renderPlaceholder(t('boardList.all'));

		return (
			<div className='fixed-page task-list'>
				<div className='filters'>
					<ButtonGroup>
						{CLOSED_BUTTONS_ITEMS.map(({ name, value }, index) => (
							<Button
								variant={closed === value ? 'primary' : 'default'}
								key={index}
								onClick={() => this.store.onChange('closed')(value)}
							>
								{t('task.' + name)}
							</Button>
						))}
					</ButtonGroup>

					<RecordSelect
						model={store.model.User}
						value={user}
						onChange={onChange('user')}
						isRequired
						prependItems={[
							{ label: t('task.All'), value: ALL },
							{ label: t('task.notAssigned'), value: NONE },
						]}
						showValue={showUserValue}
						computed={renderUser}
						searchFields={['username', 'lastName', 'firstName', 'middleName']}
						filter={{ order: 'lastName asc, firstName asc, username asc' }}
						comparator={this.userComparator}
					/>
					<RecordSelect
						model={store.model.BoardList}
						value={boardLists}
						onChange={onChange('boardLists')}
						showValue={showBoardListValue}
						computed={renderStatus}
						filter={{ order: 'name asc' }}
					/>
					<Select value={order} onChange={onChange('order')} isRequired noSearch items={ORDER_BUTTONS_ITEMS} width={200} />
					<CleanButton onClick={cleanFilters} />
				</div>
				<Pager current={page} totalCount={records.totalCount} itemsPerPage={this.perPage} onChange={onChange('page')} />
				<div className='fixed-page tasks'>
					{records.map((task) => (
						<TaskItem key={task.id} task={task} />
					))}
				</div>
			</div>
		);
	}
}

