import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@smartplatform/ui';
import t from 'i18n';

import store from 'client/store';

import './style.scss';

export default class AddButton extends React.Component {
	static propTypes = {
		onClick: PropTypes.func,
		url: PropTypes.string,
		text: PropTypes.string,
		variant: PropTypes.string,
		type: PropTypes.string,
		className: PropTypes.string,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		text: t('add'),
		variant: 'primary',
		type: '',
		create: '',
		disabled: false,
	};

	create = () => store.route.push({ path: this.props.url });

	render() {
		const { variant, onClick, className, text, type, disabled } = this.props;

		return (
			<Button
				variant={variant}
				className={classNames({
					'toolbar-create-btn': type === 'create' ? true : false,
					[`${className}`]: className ? true : false,
				})}
				onClick={onClick ? onClick : this.create}
				disabled={disabled}
			>
				<span>{text}</span>
				{type === 'create' ? <FontAwesomeIcon icon={faPlusCircle} /> : null}
			</Button>
		);
	}
}
