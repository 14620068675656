import React from "react";
import store from 'client/store';
import t from 'i18n';
import { ModelList } from 'components';
import { Column, ColorValue } from '@smartplatform/ui';


const List = ({ modelName, path }) => {
    return (
        <ModelList model={store.model[modelName]} path={path} title="Объекты(справочники)">
            <Column property="active" label="Использовать в обмене" width={150}/>
            <Column relation="version" property="number" label="Версия формата" width={150}/>
            <Column property="name" label="Наименование объекта(справочника) как в 1С" width={500}/>
            <Column property="dispatch" label="Отправка" />
            <Column property="recive" label="Приём" />
        </ModelList>
    );
}

export default List;