import React from 'react';
import PropTypes from 'prop-types';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { DropDown, Trigger, Select, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const items = [
	{ id: 1, text: 'one' },
	{ id: 2, text: 'two' },
	{ id: 3, text: 'three' },
	{ id: 4, text: 'four' },
	{ id: 5, text: 'five' },
	{ id: 6, text: 'six' },
	{ id: 7, text: 'seven' },
	{ id: 8, text: 'eight' },
	{ id: 9, text: 'nine' },
	{ id: 10, text: 'ten' },
	// { id: 11, text: 'eleven' },
	// { id: 12, text: 'twelve' },
];

@observer
export default class DropDownTest extends React.Component {

	@observable selected = null;//[];
	@observable items = [...items];
	@observable search = '';
	@observable test = null;
	@observable test2 = null;
	@observable record = null;

	onSelect = selected => {
		console.log('onSelect', JSON.stringify(toJS(selected)));
		this.selected = selected;
	};

	onSearch = str => {
		console.log('onSearch', str);
		const trimmed = str.trim();
		this.items = trimmed.length > 0 ?
			items.filter(item => item.id === Number(str) || new RegExp(trimmed, 'ig').test(item.text))
			:
			[...items];
	};

	renderItem = item => <span className="my-item"><strong>{item.text}</strong> <em>{item.id}</em></span>;

	renderItem2 = item => {
		console.log('renderItem2', item);
		if (item) {
			return item.text;
		}
		return '-';
	};

	render() {
		return <div className="trigger-family">
			<div className="block">
				<label>Trigger</label>
				<div>simple trigger:{' '}
					<Trigger popup="popup"><u style={{ cursor: 'default' }}>hover</u></Trigger> trigger,{' '}
					<Trigger popup="popup" trigger="click"><u style={{ cursor: 'pointer' }}>click</u></Trigger> trigger.{' '}
					<Trigger
						popup={
							<Trigger popup="popup" trigger="click"><u style={{ cursor: 'pointer' }}>nested</u></Trigger>
						}
						trigger="click">
						<u style={{ cursor: 'pointer' }}>nesting</u>
					</Trigger> trigger.
				</div>
			</div>
{/*
			<div className="block">
				<label>DropDown</label>
				<div>
					this is a dropdown: {' '}
					<DropDown
						// items={this.items}
						selected={this.selected}
						onSelect={this.onSelect}
						// onSearch={this.onSearch}
						computed={this.renderItem}
						size="md"
						maxItems={5}
						placeholder="Выберите значение"
						searchPlaceholder="Поиск"
						className="my-dropdown"
						before={<>before</>}
						after={<>after</>}
						// position="topRight"
						// style={{ width: 200 }}
					/>
					{' '}...continuing line.
				</div>
			</div>
*/}
			<div className="block">
				<label>Select</label>

				<Select
					value={this.test2}
					// computed={this.renderItem2}
					computed={r => r.text}
					showValue={this.test2 ? this.test2.text : 'test'}
					items={[
						{ label: 'one', value: { id: 1, text: 'one' }},
						{ label: 'two', value: { id: 2, text: 'two' }},
						{ label: 'three', value: { id: 3, text: 'three' }},
					]}
					onChange={v => this.test2 = v}
				/>

				<Select
					items={[
						'asdfl dk fqw efqgtw',
						'3456435sdfg aslkjhsd wiuyqt',
						'asdasdfasdf woiuey aslklk',
						'xcmnbvcc asdfas asdlfkasdf asdlfkaasd89769876',
						1,2,3,4,5,6
					]}
					value={this.test}
					onChange={v => this.test = v}
					itemsPerPage={5}
				/>
				<RecordSelect
					model={store.model.OrderList}
					value={this.record}
					onChange={record => this.record = record}
					property="name"
					placeholder={t('order.status')}
					searchPlaceholder={t('search')}
					itemsPerPage={5}
					size={40}
					fontSize={16}
					// empty={t('all')}
				/>

{/*
				<div>
					<Select
						items={this.items.map(item => ({ label: this.renderItem(item), value: item }))}
						value={this.selected}
						onSelect={this.onSelect}
						onSearch={this.onSearch}
						placeholder="Выберите значение"
						searchPlaceholder="Поиск"
						className="my-dropdown"
						// position="topRight"
						// style={{ width: 200 }}
					/>
				</div>
*/}
			</div>
		</div>;
	}

}
