import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Create from "./Create";
import SinglePage from './single-page';
import './style.scss';


const path = '/contragents';
const modelName = 'Contragent';

export const Contragents = () => (
	<Switch>
		<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />} />
		<Route path={`${path}/create`} exact children={({ match }) => <Create match={match} modelName={modelName} path={path} />}/>
		<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} />} />
		<Route path={`${path}/:id`} children={({ match }) => <SinglePage match={match} path={path} modelName={modelName} />}/>
	</Switch>
);
