import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Field, Form, RelationSelect, Row, Column } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { renderStatus } from 'client/tools';

@observer
export default class CreateTask extends React.Component {
	@observable task = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		let defaultBoardList = await store.model.BoardList.find({ where: { default: true } });
		defaultBoardList = defaultBoardList.length ? defaultBoardList[0].id : undefined;
		this.task = new store.model.Task({ projectId: this.props.projectId, boardListId: defaultBoardList });
	};

	onSave = async () => store.route.push({ path: `/tasks/${this.task.id}` });

	render() {
		if (!this.task) return null;
		return (
			<div className='create-task'>
				<Form record={this.task} onSave={this.onSave} noDelete stay disableSave={!this.task.name || !this.task.boardListId}>
					<Row>
						<div>
							<Field property='name' label={t('task.name')} isRequired>
								<input placeholder='Введите название задачи' />
							</Field>
							<Field
								relation='project'
								property='name'
								label={t('project.title')}
								isRequired
								disabled={this.props.projectId}
							/>
						</div>
						<Field relation='boardList' property='name' label={t('boardList.title')} computed={renderStatus} isRequired>
							<RelationSelect isRequired />
						</Field>
					</Row>
				</Form>
			</div>
		);
	}
}

