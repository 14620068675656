import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getChartOptions } from './options';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { COSTS, INCOME } from '../constants';

@inject('store')
@observer
export class Charts extends React.Component {
	render() {
		const { isLoading } = this.props.store;

		return (
			<div className='charts'>
				{this.props.store.chartRecords.map((chart, index) => {
					if (isLoading) return <div className='chart-loading' />;
					const noData = !chart[COSTS].length && !chart[INCOME].length;
					const component = noData ? (
						<div className='chart-no-data'>{t('operation.notFound')}</div>
					) : (
						<ReactECharts option={getChartOptions(chart)} />
					);
					return (
						<div className='chart' key={index}>
							<h2>
								{t('bankAccount.title')}: {chart.bankAccount?.bankAccountName || chart.bankAccount?.bankAccountNumber}
							</h2>
							{component}
						</div>
					);
				})}
			</div>
		);
	}
}

