import React, { Component } from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList, Toolbar } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const filter = {
	include: [
		{
			relation: 'priceCategoryFeatures',
			scope: {
				include: [{ relation: 'category', scope: { fields: ['id', 'name'] } }],
				order: 'id asc',
			},
		},
	],
};

@observer
export default class List extends Component {
	@observable count = null;
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model[this.props.modelName].count();
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	renderCategories = (record) =>
		record
			? record
					.priceCategoryFeatures()
					.filter((r) => !!r.category)
					.map((r) => r.category.name)
					.join(', ')
			: '-';

	render() {
		const { modelName, path } = this.props;
		console.log(this.count);

		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url='/features/create' innerText={t('category.new')} />
					<Toolbar.Search value={this.search} onSearch={this.onSearch} />
					<Toolbar.Count text={t('category.total')} count={this.count} />
					<Toolbar.ExportBtn />
				</Toolbar>
				<ModelList
					noFilters
					noHeader
					searchValue={this.search}
					model={store.model[modelName]}
					path={path}
					title={t('feature.plural')}
					filter={filter}
				>
					<Column property='name' label={t('name')} />
					<Column label={t('feature.products')} computed={this.renderCategories} />
					<Column width={100} property='type' label={t('feature.type')} computed={(record) => t(`feature.${record.type}`)} />
				</ModelList>
			</>
		);
	}
}

