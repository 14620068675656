import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import TaskLog from './TaskLog';
import { Comment, PostComment } from 'components';
import Popup from './Popup';
import t from 'i18n';
import './style.scss';

@inject('taskStore')
@observer
export default class History extends React.Component {
	@observable popup = null;
	@observable items = [];

	constructor(props) {
		super(props);
		this.store = this.props.taskStore;
	}

	onSubmitComment = async () => await this.store.onSave?.(['updatedAt']);

	onCommentDelete = async (comment) => {
		const attachments = await comment.attachments();
		for (let attachment of attachments) {
			await comment.attachments.remove(attachment.id);
			await attachment.deleteFile('filename');
			await attachment.delete();
		}
		await comment.delete();
		this.store.fetchHistory(true);
	};

	showDelta = (field, index) => {
		this.popup = {
			task: this.store.task,
			logs: this.store.logs,
			index,
			field,
			onClose: () => (this.popup = null),
		};
	};

	render() {
		const { history, task } = this.store;

		if (!history.length) return null;

		const logs = history.map((item) => {
			const { type, record, index } = item;
			if (type === 'comment') {
				return <Comment key={'comment-' + record.id} comment={record} className='task-comment' onDelete={this.onCommentDelete} />;
			}
			if (type === 'taskLog') {
				return (
					<TaskLog key={'tasklog-' + record.id} logs={this.store.logs} index={index} task={task} onShowPopup={this.showDelta} />
				);
			}
			return (
				<div key={'inknown-' + record.id} className='unknown-item'>
					{type}, id: {record.id}
				</div>
			);
		});

		return (
			<div className='task-history'>
				<div className='history-information'>
					<div className='title'>{t('history.title')}</div>
					<div className='logs'>{logs}</div>
				</div>
				<PostComment record={task} onSubmit={this.onSubmitComment} />
				{this.popup && <Popup {...this.popup} />}
			</div>
		);
	}
}

