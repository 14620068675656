import React from "react";
import { Switch, Route } from 'react-router-dom';
import store from 'client/store';
import List from './List';
import Edit from './Edit';
import Create from './Create';
import t from '../../../i18n';

const path = '/contragent-types';
const modelName = 'ContragentType';

export class ContragentTypes extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		store.ui.title = t('contragentTypes');
	}

	render() {
		return (
			<Switch>
				<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />} />
				<Route path={`${path}/create`} exact children={({ match }) => <Create match={match} modelName={modelName} path={path} />} />
				<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />} />
			</Switch>
		);
	}
};
