import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Label } from 'components';
import pStore from '../store';
import store from 'client/store';

@observer
export default class LabelsFilter extends React.Component {

	@observable labels = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.labels = await store.model.Label.find({ order: 'id asc' });
	};

	onClick = async (label) => {
		const index = pStore.labels.findIndex(l => l.id === label.id);
		if (index === -1) {
			pStore.labels.push(label);
		}
		else {
			pStore.labels.splice(index, 1);
		}
		const id = parseInt(pStore.project.id);
		if (id) await pStore.load(id);
		if (pStore.kanban) {
			pStore.kanban.reload();
		}
	};

	render() {
		return <div className="labels-filter">
			{this.labels.map(label => {
				const index = pStore.labels.findIndex(l => l.id === label.id);
				const className = index === -1 ? '' : 'selected';
				return <Label key={label.id} className={className} size={14} label={label} onClick={() => this.onClick(label)}/>;
			})}
		</div>;
	}

}
