import { Button, Column, Popup, RecordSelect, Table } from '@smartplatform/ui';
import store from 'client/store';
import { Section } from 'components';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { renderList } from '..';
import Contacts from './contacts/Contacts';
import { ORDERS_LIMIT } from './store';
import NewClient from './NewClient';

const NEW_CLIENT = 'NEW_CLIENT';

@inject('store') @observer
export default class Client extends React.Component {

	@observable editField = null;
	@observable confirmPopup = false;
	@observable newContragentPopup = false;

	existingContacts = [];
	newContragent = null;

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onContragentChange = async (contragent) => {
		this.existingContacts = await store.model.OrderContact.find({
			where: { orderId: this.store.order.id },
		});
		if (this.existingContacts.length > 0) {
			this.newContragent = contragent;
			this.confirmPopup = true;
		}
		else {
			await this.changeContragent(contragent);
		}
	};

	changeContragent = async (contragent) => {
		await Promise.all(this.existingContacts.map(c => c.delete()));
		if (contragent === NEW_CLIENT) {
			this.newContragentPopup = true;
		} else {
			this.store.order.contragent = contragent;
			await this.store.save();
		}
	};

	confirmContragent = async () => {
		await this.changeContragent(this.newContragent);
		this.closePopup();
	};

	closePopup = () => this.confirmPopup = false;

	gotoOrder = order => store.route.push({ path: `/orders/${order.id}` });

	onNewContragentPopupClose = () => {
		this.newContragentPopup = false;
	};

	render() {
		const { order } = this.store;
		const orders = order.contragent && order.contragent.orders() || [];
		const appendItems = store.model.Contragent.INFO.WRITE ?
			[{ label: t('client.new'), value: NEW_CLIENT, className: 'new-user' }]
			:
			undefined;

		return <Section className="order-client">
			<header>{t('order.client')}</header>

			<div className="form-field">
				<label>{t('order.client')}</label>
				{/*<div>{JSON.stringify(order.client)}</div>*/}
				<RecordSelect
					// key={order.clientId || 'empty'}
					value={order.contragent}
					property="name"
					onChange={this.onContragentChange}
					model={store.model.Contragent}
					filter={{ include: { relation: 'orders', scope: { order: 'id desc', limit: ORDERS_LIMIT } } }}
					placeholder={t('order.selectClient')}
					width="100%"
					itemsPerPage={9}
					className="with-new-record"
					// appendItems={appendItems}
				/>
				{order.client && <div className="client-link">
					<Link to={`/contragents/${order.contragentId}`}>{t('order.gotoClient')}</Link>
				</div>}
			</div>

			{order.contragent && <Contacts key={order.contragentId} client={order.contragent} order={order} />}

			{this.confirmPopup && <Popup onClose={this.closePopup} className="confirm-client">
				<div className="message">{t('order.confirmClientChange')}</div>
				<Button variant="primary" onClick={this.confirmContragent}>{t('yes')}</Button>
				<Button onClick={this.closePopup}>{t('cancel')}</Button>
			</Popup>}


			{orders.length > 0 && <div className='client-orders'>
				<div className='title'>{t('client.orders')}</div>
				<Table rows={orders} onRowClick={this.gotoOrder}>
					<Column width={20} property="id" label={t('order.n')} className="text-center" />
					<Column property="description" label={t('order.description')} />
					<Column width={160} relation="list" computed={renderList} label={t('order.status')} />
				</Table>
			</div>
			}
			{this.newContragentPopup && <NewClient onClose={this.onNewContragentPopupClose} order={this.store.order} />}
		</Section>;
	}

}
