import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import CategoryTree from './CategoryTree';
import './style.scss';

/**
 * Кнопка выбора категорий. Помимо своих пропов принимает пропы для <Button> и <button> (кроме onClick).
 */

@observer
export default class CategoryBtn extends React.Component {

	static propTypes = {
		/** Коллбэк, в аргументе будет передан id выбранной категории */
		onSelect: PropTypes.func,
		/** id категории, которую надо исключить из списка */
		exclude: PropTypes.number,
		/** Запретить выбор корня */
		disableRoot: PropTypes.bool,
	};

	static defaultProps = {
		disableRoot: false,
	};

	@observable active = false;

	activate = () => this.active = true;
	close = () => this.active = false;

	render() {
		const { onSelect, exclude, confirmMsg, disableRoot, ...rest } = this.props;
		return <>
			<Button {...rest} onClick={this.activate} />
			{this.active && <CategoryTree {...{ onSelect, exclude, confirmMsg, disableRoot }} onClose={this.close} />}
		</>;
	}
	
}
