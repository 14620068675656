import React from 'react';
import { observer } from 'mobx-react';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { renderUser, renderPlaceholder } from 'client/tools';
import t from 'i18n';

export const ToolbarUser = observer(({ user, onChange }) => {
	const showUserValue = user ? renderUser(user) : renderPlaceholder(t('user.all'));
	return (
		<RecordSelect
			className='toolbar-user'
			model={store.model.User}
			value={user}
			onChange={onChange}
			showValue={showUserValue}
			computed={renderUser}
			searchFields={['username', 'lastName', 'firstName', 'middleName']}
			filter={{ order: 'lastName asc, firstName asc, username asc' }}
		/>
	);
});

