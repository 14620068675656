import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { User } from 'components';
import { formatDate } from 'client/tools';
import t from 'i18n';
import './style.scss';

@observer
export default class PaymentCard extends React.Component {

	onClick = () => this.props.onClick(this.props.record);
	
	render() {
		const { record, type } = this.props;
		
		return <div className="payment-card" onClick={this.onClick}>
			<div className="payment-info">
				<div className="person">
					{type === 'outgoing' ?
						(record.person ? <User user={record.person} size={18} /> : '-')
						:
						<><FontAwesomeIcon icon={faPlusCircle}/> {t('payment.incomingSingle')}</>
					}
				</div>
				<div className="role">
					{record.role ? <><FontAwesomeIcon icon={faCog}/> {record.role.name}</> : ''}
					{record.agent ? <> {t('budget.agent')}</> : ''}
				</div>
			</div>
			<div className="payment-numbers">
				<div className="block sum">
					<label>{t('sum')}</label>
					{record.sum ? <><strong>{record.sum}</strong> <em>{t('rub')}</em></> : '-'}
				</div>
				<div className="block">
					<label>{t('date')}</label>
					{record.date ? <strong>{formatDate(record.date)}</strong> : '-'}
				</div>
			</div>
		</div>;
	}
	
}
