import React from 'react';

import { Field, ColorPicker, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title="Узел обмена">
		<Row>
			<Field property="exchangePlanName" label="Имя плана обмена" />
			<Field property="configName" label="Имя формата обмена" />
		</Row>
		<Row>
			<Field property="baseName" label="Имя данной базы" />
			<Field property="nodeCode" label="Код данной базы" />
		</Row>
		<Row>
			<Field property="secondBaseName" label="Имя второй базы" />
			<Field property="secondBaseNodeCode" label="Код узла второй базы" />
		</Row>
		<Row>
			<Field property="basePrefix" label="Префикс данной базы" />
			<Field property="codePredefinedNode" label="Код предопределённого узла" />
		</Row>
		<Row>
			<Field property="compressedOutgoingMessage" label="Сжимать исходящие сообщения" />
			<Field property="useEmailTransport" label="Использовать Email" />
			<Field property="useFileTransport" label="Использовать File" />
			<Field property="useFtpTransport" label="Использовать Ftp" />
		</Row>
	</ModelEdit>;
};
