import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';
import './style.scss';

export const Workshops = ({ path, modelName, title, searchValue }) => (
	<Switch>
		<Route
			path={path}
			exact
			children={({ match }) => <List title={title} match={match} modelName={modelName} searchValue={searchValue} />}
		/>
		<Route path={`${path}/page/:page`} children={({ match }) => <List title={title} match={match} modelName={modelName} />} />
		<Route path={`${path}/:id`} children={({ match }) => <Edit title={title} match={match} path={path} modelName={modelName} />} />
	</Switch>
);
