import React from 'react';

import { Avatar, Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

const avatar = (user) => <Avatar user={user} size={24} />;

export default ({ modelName, path, match }) => {
	return <ModelList model={store.model[modelName]} path={path} match={match} title={t('person.plural')} order="lastName, firstName, middleName">
		<Column computed={avatar} width={34} />
		<Column computed={fio} property='lastName' label={t('lastName')} />
		<Column rich={true} property='paymentInfo' label={t('paymentInfo')} />
		<Column property='comment' label={t('comment.title')} />
	</ModelList>;
}
