import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Avatar, Popover, Loader } from '@smartplatform/ui';
import MemberAvatar from './MemberAvatar';
import { fio } from 'client/tools';
import './style.scss';

const LOADING = <div className="loading"><Loader size={18} /></div>;

@observer
export default class MembersAvatars extends React.Component {

	static propTypes = {
		project: PropTypes.object.isRequired,
		size: PropTypes.number,
		avatarsCount: PropTypes.number,
	};

	static defaultProps = {
		avatarsCount: 3,
		size: 18,
	};

	@observable popup = LOADING;

	constructor(props) {
		super(props);
	}

	loadMember = async (member) => {
		const projectRole = await member.role;
		this.popup = <>
			<div className="avatar"><Avatar user={member.user} size={20} /></div>
			<div className="info">
				<div className="fio">{fio(member.user)}</div>
				{projectRole && <div className="role">{projectRole.name}</div>}
			</div>
		</>;
	};

	onClose = () => this.popup = LOADING;

	render() {
		const { project, size, avatarsCount } = this.props;

		const members = [...project.members()].reverse().slice(0, avatarsCount);
		return <div className="project-members-avatars">
			<div className="avatars">
				{members.map(member => <MemberAvatar key={member.id} member={member} size={size} style={{ marginLeft: -size / 3 }} />)}
			</div>
			{project.membersCount > project.members().length && <span className="more">+{project.membersCount - project.members().length}</span>}
		</div>;
	}

}
