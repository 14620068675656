import { observable } from 'mobx';
import { debounce } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';
import store from 'client/store';

export const ALL = 'ALL';

export default class OrdersStore {

    @observable search = '';
    @observable price0 = null;
    @observable price1 = null;
    @observable orderList = null;
    @observable paid = null;
    @observable startDate0 = null;
    @observable startDate1 = null;
    @observable endDate0 = null;
    @observable endDate1 = null;
    @observable category = null;
    //для нового заказа
    @observable list = null;
    @observable description = '';

    constructor() {
        this.user = store.model.user;
        this.doSearch = debounce(this.doSearch, 500, { leading: true, trailing: true });
        this.init();
    }

    init = async () => {
        // this.count = await store.model.Order.count();
        const INCLUDE = {
            relation: 'orders',
            scope: {
                limit: 1,
                order: 'priority desc',
            }
        }

        if (this.list) {
            this.list = await store.model.OrderList.findById(this.list.id, { include: INCLUDE })
        } else {
            const list = await store.model.OrderList.find({
                where: { default: true },
                include: INCLUDE
            });
            this.list = list.length > 0 ? list[0] : null;
        }
    };
    //для нового заказа
    onListChange = (e) => {
        this.list = e;
        this.init();
    };

    onDescriptionChange = (e) => {
        this.description = e.target.value;
    };

    clearOrderData = () => {
        this.list = null;
        this.description = '';
        this.init();
    };
    //

    onSearch = (e) => {
        this.search = e.target.value;
        this.compileFilter();
        this.doSearch();
    };

    onPrice0Change = value => {
        // console.log('onPrice0Change', value);
        this.price0 = value;
        this.doSearch();
    };

    onPrice1Change = value => {
        // console.log('onPrice1Change', value);
        this.price1 = value;
        this.doSearch();
    };

    onOrderListChange = value => {
        // console.log('onOrderListChange', value);
        this.orderList = value;
        this.doSearch();
    };

    onPaidChange = value => {
        // console.log('onOrderListChange', value);
        this.paid = value;
        this.doSearch();
    };

    onStartDate0Change = value => {
        this.startDate0 = value ? startOfDay(value) : null;
        console.log('onStartDate0Change', this.startDate0);
        this.doSearch();
    };

    onStartDate1Change = value => {
        this.startDate1 = value ? startOfDay(value) : null;
        console.log('onStartDate1Change', this.startDate1);
        this.doSearch();
    };

    onEndDate0Change = value => {
        this.endDate0 = value ? endOfDay(value) : null;
        console.log('onEndDate0Change', this.endDate0);
        this.doSearch();
    };

    onEndDate1Change = value => {
        this.endDate1 = value ? endOfDay(value) : null;
        console.log('onEndDate1Change', this.endDate1);
        this.doSearch();
    };

    onCategoryChange = category => {
        this.category = category;
        this.doSearch();
    };

    doSearch = () => {
        if (this.reload) this.reload();
    };

    compileFilter = (availableListIds) => {
        const idFilter = availableListIds ? { inq: availableListIds } : { neq: null }
        console.log(idFilter)
        const where = {
            and: [
                { listId: idFilter },
            ],
        };

        const trimmed = this.search.trim();
        if (trimmed.length > 0) {
            const words = trimmed.split(' ');
            words.forEach(word => where.and.push({
                or: [
                    { id: { like: `${word}%` } },
                    { description: { ilike: `%${word}%` } },
                    { clientName: { ilike: `%${word}%` } },
                    { fio: { ilike: `%${word}%` } },
                    { username: { ilike: `%${word}%` } },
                ]
            }));
        }

        if (parseFloat(this.price0)) {
            where.and.push({ total: { gte: parseFloat(this.price0) } });
        }

        if (parseFloat(this.price1)) {
            where.and.push({ total: { lte: parseFloat(this.price1) } });
            if (!parseFloat(this.price0)) {
                where.and.push({ total: { gt: 0 } });
            }
        }

        if (this.orderList) {
            where.and.push({ listId: this.orderList.id });
        }

        if (this.paid !== null) {
            where.and.push({ paid: this.paid });
        }

        if (this.startDate0) {
            where.and.push({ startDate: { gte: this.startDate0 } });
        }

        if (this.startDate1) {
            where.and.push({ startDate: { lte: this.startDate1 } });
        }

        if (this.endDate0) {
            where.and.push({ endDate: { gte: this.endDate0 } });
        }

        if (this.endDate1) {
            where.and.push({ endDate: { lte: this.endDate1 } });
        }

        if (this.category) {
            where.and.push({ categoryIds: { like: `%"${this.category.id}"%` } })
        }

        return where;
    };

    areFiltersEmpty = () => {
        return this.search.trim().length === 0
            && this.price0 === null
            && this.price1 === null
            && this.orderList === null
            && this.paid === null
            && this.startDate0 === null
            && this.startDate1 === null
            && this.endDate0 === null
            && this.endDate1 === null
            && this.category === null;
    };

    clearFilters = () => {
        this.search = '';
        this.price0 = null;
        this.price1 = null;
        this.orderList = null;
        this.paid = null;
        this.startDate0 = null;
        this.startDate1 = null;
        this.endDate0 = null;
        this.endDate1 = null;
        this.category = null;
        this.doSearch();
    };
}
