import React from 'react';

import { Field, ColorPicker, Row, RecordSelect } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title={`Редактирование объекта`}>
		<Row>
			<div>
				<Row>
					<Field 
						relation="version" 
						property="number" 
						label="Номер версии формата обмена" 
					/>
					<Field property="name" label="Имя объекта(справочника)" />
				</Row>
				<Row>
					<Field property="scheme" label="Схема разбора">
						<textarea rows={10}/>
					</Field>
				</Row>
				<Row>
					<Field property="active" label="Использовать в обмене" />
					<Field property="dispatch" label="Отправка" />
					<Field property="recive" label="Получение" />
				</Row>
			</div>
			<div></div>
		</Row>
	</ModelEdit>;
};
