import { Field, Form, NumberInput, Popover, Popup, RecordSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { getChildrenRelation } from 'client/tools';
import Positions from '../Positions';
import { NotPhone, Phone } from '../../../../../components';
@inject('store')
@observer
export default class EditProcess extends React.Component {
	@observable record = null;
	@observable position = null;
	@observable materialGroup = null;
	@observable isLoading = true;
	groupIds = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = this.props.record;
		this.isNew = !this.record?.id;
		if (this.isNew) {
			this.record = new store.model.MaterialLog();
			this.record.orderId = this.props.orderId;
		} else {
			if (this.record.material) {
				this.record.material = await store.model.ViewNomenclature.findById(this.record.material.id, { include: ['group'] });
				this.materialGroup = this.record.material?.group;
			}

			this.position = this.record.position;
		}

		await this.getGroups();
		this.isLoading = false;
	};
	getGroups = async () => {
		let parentMeterialGroup = await store.model.NomenclatureGroup.find({
			where: { name: 'СКЛАД УЧЕТА МАТЕРИАЛОВ' },
			...getChildrenRelation(),
		});
		parentMeterialGroup = parentMeterialGroup.length > 0 ? parentMeterialGroup[0] : null;
		if (parentMeterialGroup) this.getGroupIds(parentMeterialGroup);
	};
	getGroupIds = (parent) => {
		const children = parent.children();
		if (children) {
			for (const child of children) {
				this.groupIds.push(child.id);
				this.getGroupIds(child);
			}
		}
	};

	onClose = () => this.props.onClose();
	onPositionSelect = (position) => {
		if (this.popover) this.popover.close();
		this.position = position;
	};
	getPopoverInstance = (instance) => (this.popover = instance);

	onWorkshopChange = (workshop) => {
		this.record.workshop = workshop;
	};
	onMaterialChange = (material) => {
		this.record.material = material;
	};

	onMaterialGroupChange = (group) => {
		this.materialGroup = group;
		this.record.material = null;
	};

	calcTotal = () => {
		this.record.totalNumber = parseInt(this.record.copiesNumber || 0) + parseInt(this.record.adjustmentsNumber || 0) || null;
	};

	onCopiesNumberChange = (copiesNumber) => {
		this.record.copiesNumber = copiesNumber;
		this.calcTotal();
	};

	onAdjustmentsNumberChange = (adjustmentsNumber) => {
		this.record.adjustmentsNumber = adjustmentsNumber;
		this.calcTotal();
	};

	beforeSave = () => {
		this.record.position = this.position;
	};

	onSave = async () => {
		if (this.isNew) {
			const orderLog = new store.model.OrderLog();
			orderLog.orderId = this.props.orderId;
			orderLog.recordId = this.record.id;
			orderLog.updated = ['newMaterial'];
			await orderLog.save();
		}
		this.props.onClose();
	};

	renderMaterial = ({ name, totalQuantity, measure }) => (
		<div className='d-flex justify-content-between'>
			<div>{name}</div>
			<div>
				<b>{totalQuantity + ' ' + measure}</b>
			</div>
		</div>
	);

	render() {
		if (this.isLoading) return null;
		const popup = <Positions onSelect={this.onPositionSelect} />;
		return (
			<Popup className='edit-material-popup' onClose={this.onClose} header={t('material.title')}>
				<Popover trigger='click' position='bottom' className='popover' getInstance={this.getPopoverInstance} content={popup}>
					{this.position ? (
						<span>{(this.position.category && this.position.category.name) || t('material.noPositionCategory')} </span>
					) : (
						t('material.selectPosition')
					)}
				</Popover>
				<Form beforeSave={this.beforeSave} record={this.record} stay noDelete onSave={this.onSave}>
					<Field relation='workshop' property='name' onChange={this.onWorkshopChange} label={t('material.workshop')} />
					<Row>
						<div className='form-field'>
							<label>{t('material.group')}</label>
							<RecordSelect
								model={store.model.NomenclatureGroup}
								onChange={this.onMaterialGroupChange}
								property='name'
								value={this.materialGroup}
								filter={{ where: { id: { inq: this.groupIds } }, order: 'name asc' }}
							/>
						</div>
						<div className='form-field'>
							<label>{t('material.title')}</label>
							<RecordSelect
								model={store.model.ViewNomenclature}
								computed={this.renderMaterial}
								onChange={this.onMaterialChange}
								value={this.record.material}
								filter={{
									where: {
										groupId: this.materialGroup ? this.materialGroup.id : { inq: this.groupIds },
										totalQuantity: { gt: 0 },
									},
								}}
							/>
						</div>
					</Row>
					<NotPhone>
						<Row>
							<Field property='feature' label={t('material.feature')} />
							<Field property='format' label={t('material.format')} />
							<Field property='units' label={t('material.units')} />
						</Row>
						<Row>
							<Field property='copiesNumber' label={t('material.copiesNumber')}>
								<NumberInput onChange={this.onCopiesNumberChange} positiveOnly integerOnly />
							</Field>
							<Field property='adjustmentsNumber' label={t('material.adjustmentsNumber')}>
								<NumberInput onChange={this.onAdjustmentsNumberChange} positiveOnly integerOnly />
							</Field>
							<Field property='totalNumber' label={t('material.totalNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
					</NotPhone>
					<Phone>
						<Row>
							<Field property='feature' label={t('material.feature')} />
							<Field property='format' label={t('material.format')} />
						</Row>
						<Row>
							<Field property='units' label={t('material.units')} />
							<Field property='copiesNumber' label={t('material.copiesNumber')}>
								<NumberInput onChange={this.onCopiesNumberChange} positiveOnly integerOnly />
							</Field>
						</Row>
						<Row>
							<Field property='adjustmentsNumber' label={t('material.adjustmentsNumber')}>
								<NumberInput onChange={this.onAdjustmentsNumberChange} positiveOnly integerOnly />
							</Field>
							<Field property='totalNumber' label={t('material.totalNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
					</Phone>
				</Form>
			</Popup>
		);
	}
}
