import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { Button, Popup, Popconfirm } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class ProjectDelete extends React.Component {

	@observable popup = false;

	constructor(props) {
		super(props);
	}

	showPopup = () => this.popup = true;
	closePopup = () => this.popup = false;

	delete = async () => {
		console.warn('--- Deleting project', this.props.project.name, '!!!');
		await this.props.project.delete();
		store.route.push({ path: `/projects` });
	};

	render() {
		return <div className="project-delete">
			<Button onClick={this.showPopup} variant="link"><FontAwesomeIcon icon={faTrash} /> Удаление проекта</Button>
			{this.popup && <Popup onClose={this.closePopup} className="project-delete-popup">
				<div className="msg">{t('project.deleteMsg')}</div>
				<div className="actions">
					<Popconfirm onConfirm={this.delete} content={t('delete') + '?'}><Button variant="danger">{t('delete')}</Button></Popconfirm>
					<Button onClick={this.closePopup}>{t('cancel')}</Button>
				</div>
			</Popup>}
		</div>;
	}

}
