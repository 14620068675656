import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup, FormPlain, Row, Field, NumberInput } from '@smartplatform/ui';
import { User } from 'components';
import { fio } from 'client/tools';
import t from 'i18n';

@observer
export default class MemberEdit extends React.Component {

	constructor(props) {
		super(props);
	}
	
	renderPerson  = person => <User user={person} size={20} />;
	
	render() {
		const { record, onClose } = this.props;
		
		const update = !record.id;
		
		return <Popup className="member-edit" onClose={onClose}>
			<FormPlain record={record} onSave={() => onClose(update)} onDelete={() => onClose(true)}>
				<Row>
					<Field relation="person" computed={this.renderPerson} property='lastName' label={t('person.title')}/>
					<Field relation="budget" property="name" label={t('budget.title')} disabled />
					<Field relation="role" property="name" label={t('budgetRole.role')}/>
				</Row>
				<Row>
					<Field property="sum" label={t('sum')}><NumberInput type="currency" /></Field>
					<Field property="percent" label={t('percent')}><NumberInput type="percent" /></Field>
					<Field property="price" label={t('price')}><NumberInput type="currency" /></Field>
				</Row>
				<Row>
					<Field property="paid" label={t('budgetRole.paid')}><NumberInput type="currency" /></Field>
					<Field property="agent" label={t('agent')}/>
					<div/>
				</Row>
				<Field property='comment' label={t('comment.title')}><textarea rows={5}/></Field>
			</FormPlain>
		</Popup>;
	}
	
}
