import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Avatar } from '@smartplatform/ui';
import { fioShort } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class User extends React.Component {

	@observable menuActive = false;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}

	onMouseDown = e => {
		if (this.menuNode && !this.menuNode.contains(e.target)) {
			this.menuActive = false;
		}
	};

	onMount = (el) => this.menuNode = el;

	profile = (e) => {
		e.preventDefault();
		store.route.push({ path: '/profile' });
	}

	toggleMenu = () => {
		this.menuActive = !this.menuActive;
	}

	logout = e => {
		e.preventDefault();
		store.model.logout();
	};
	
	render() {
		return store.model.user ?
			<div className="user-section" ref={this.onMount}>
				<Link to="/profile" className="profile-link"><Avatar user={store.model.user} size={20} /> <span className="fio">{fioShort(store.model.user)}</span></Link>
				<a className="logout" href="#" onClick={this.logout}>{t('logout')}</a>
				{this.menuActive && <div className="user-menu">
					<a href="#" onClick={this.profile}>
						<span><FontAwesomeIcon icon={faUser} /></span> {t('user.profile')}</a>
					<a href="#" onClick={this.logout}>
						<span><FontAwesomeIcon icon={faSignOutAlt} /></span> {t('logout')}</a>
				</div>}
			</div>
			:
			null;
	}
	
}
