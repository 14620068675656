import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faInfoCircle, faUniversity, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { default as translate } from 'i18n';
import Bank from './bankaccounts';
import Projects from './projects';
import Staff from './staff';
import Info from './info';
import IconTitle from '../../../components/icon-title/IconTitle';

const t = (slug) => translate(`contragents.card.tabs.${slug}`);

export default (path, record) => [
	{
		title: <IconTitle faIcon={faInfoCircle} text={t('info')} />,
		path: `${path}/:contragentId`,
		exact: true,
		component: (props) => <Info {...props} contragent={record} path={path} />,
	},
	{
		title: <IconTitle faIcon={faUserFriends} text={t('staff')} />,
		path: `${path}/:contragentId/staff`,
		component: (props) => <Staff {...props} contragent={record} />,
	},
	{
		title: <IconTitle faIcon={faUniversity} text={t('bank')} />,
		path: `${path}/:contragentId/bank-details`,
		component: (props) => <Bank {...props} contragent={record} />,
	},
	{
		title: <IconTitle faIcon={faFolder} text={t('projects')} />,
		path: `${path}/:contragentId/projects`,
		component: (props) => <Projects {...props} contragent={record} />,
	},
	// {
	// 	title: <><FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;{t('docs')}</>,
	// 	path: `${path}/:contragentId/documents`,
	// 	component: () => <>Документы</>,
	// },
];
