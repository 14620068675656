import React from 'react';
import store from "client/store";
import { Button, Field, Form } from "@smartplatform/ui";

export default class Info extends React.Component {
	constructor(props) {
		super(props);
	}
	back = () => store.route.push({ path: this.props.basepath });
	render() {
		const formProps = {
			className: 'nomenclature-info',
			record: this.props.nomenclature,
			onDelete: this.back,
			onSave: this.back,
			stay: true,
			noSave: true,
			noDelete: true
		}
		return (
			<Form {...formProps}>
				<div className='row'>
					<div className='col-5'>
						<Field property='name' label='Наименование' disabled />
						<Field property='description' label='Описание' disabled>
							<textarea rows={5} />
						</Field>
					</div>
					<div className='col-5'>
						<Field relation='type' property='name' label='Вид номенклатуры' disabled />
						<Field property='code' label='Код в 1С' disabled />
						<Field property='measure' label='Ед. измерения' disabled />
					</div>
				</div>
				<Button onClick={this.back}>
					{`<- Назад`}
				</Button>
			</Form>
		);
	}
}