import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Popup, Row, Button, RecordSelect, NumberInput, NumberValue, Input, Loader } from '@smartplatform/ui';
import { Attachments } from 'components';
import Features from './Features';
import MatchesCount from './MatchesCount';
import Members from './Members';
import Parameters from './Parameters';
import store from 'client/store';
import t from 'i18n';

const CATEGORY_INCLUDE = [
	{
		relation: 'priceCategoryFeatures',
		scope: {
			where: { featureId: { neq: null } },
			fields: ['id', 'featureId', 'categoryId']
		},
	},
];
@inject('store')
@observer
export default class PositionEdit extends React.Component {

	@observable position = null;
	@observable category = null;
	@observable orderPositionValues = [];
	@observable emptyFeatures = {};
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
		this.order = this.props.store.order;
	}

	init = async () => {
		this.category = null;
		this.position = await store.model.OrderPosition.findById(this.props.positionId, {
			include: [
				{ relation: 'category', scope: { include: [CATEGORY_INCLUDE] } },
			]
		});
		this.calcFinalPrice();
		this.category = this.position.category;
		console.log('category', this.category);
		this.isLoading = false;
	};

	onCategoryChange = async category => {
		this.category = category;
	};

	updateMatchesCount = () => {
		if (this.matchesCountInstance) this.matchesCountInstance.update();
	};

	onAmountChange = async (value) => {
		this.position.amount = value;
		this.position.price = null;
		this.updateMatchesCount();
	};

	onCommentChange = (e) => this.position.comment = e.target.value;
	onAttachmentLinkChange = e => this.position.attachmentsLink = e.target.value;
	onPriceChange = (value) => {
		this.position.price = value;
		this.calcFinalPrice();
	};

	onDiscountPercentChange = (value) => {
		this.position.discountPercent = value;
		this.calcFinalPrice();
	};

	onDiscountChange = (value) => {
		this.position.discount = value;
		this.calcFinalPrice();
	};

	onUnitPriceChange = (value) => {
		this.position.unitPrice = value;
		if (this.position.amount)
			this.position.price = this.position.unitPrice * this.position.amount;
		this.calcFinalPrice();
	};

	onSizeChange = (value) => {
		this.position.size = value;
	};

	calcFinalPrice = () => {
		const discountPercent = this.position.discountPercent || 0;
		const discount = this.position.discount || 0;
		const price = this.position.price || 0;
		let final = price * (1 - discountPercent / 100) - discount;
		if (final <= 0) final = null;
		this.position.final = final;
		console.log('final', final);
	};

	cancel = () => this.props.onClose && this.props.onClose(false);

	save = async () => {
		if (this.category) {
			const oldPositionValues = await store.model.OrderPositionValue.find({
				where: {
					positionId: this.position.id,
					// categoryId: { neq: this.category.id },
					priceCategoryFeatureId: { nin: this.orderPositionValues.map(r => r.priceCategoryFeatureId) },
				},
				fields: ['id'],
			});
			console.log('oldPositionValues', oldPositionValues.map(r => r.id));
			for (let oldPositionValue of oldPositionValues) {
				await oldPositionValue.delete();
			}
		}
		this.position.category = this.category;

		this.calcFinalPrice();
		await this.position.save();
		await this.position.values();
		await Promise.all(this.orderPositionValues.map(v => v.save()));

		this.props.onClose && this.props.onClose(true);
	};

	getMatchesCountInstance = instance => this.matchesCountInstance = instance;

	getFeaturesInstance = instance => this.featuresInstance = instance;

	onFeaturesChange = async (orderPositionValues) => {
		this.orderPositionValues = orderPositionValues;
		this.updateMatchesCount();
	}

	onPricePositionSelect = async (pricePosition) => {
		console.log('onPricePositionSelect', pricePosition, pricePosition.values());
		this.position.price = pricePosition.price * this.position.amount;
		this.position.unitPrice = pricePosition.price;
		this.position.pricePositionName = pricePosition.name;
		this.calcFinalPrice();

		for (let orderPositionValue of this.orderPositionValues) {
			const feature = orderPositionValue.feature;
			const pricePositionValue = pricePosition.values().find(p => p.featureId === feature.id);
			const property = feature.type === 'number' ? 'numberValue' : feature.type === 'boolean' ? 'booleanValue' : 'stringValue';
			console.log('? empty', feature.name, property, orderPositionValue[property], feature.type !== 'boolean' && orderPositionValue[property] === null);

			if (feature.type === 'list') {
				if (pricePositionValue && pricePositionValue.featureValueId) {
					const featureValue = await store.model.FeatureValue.findById(pricePositionValue.featureValueId);
					orderPositionValue.featureValue = featureValue;
				}
			}
			else if ((feature.type !== 'boolean' && orderPositionValue[property] === null) || orderPositionValue[property] === undefined) {
				if (pricePositionValue) {
					// console.log('> empty feature!', feature.name, property, orderPositionValue.priceCategoryFeatureId, '<-', pricePositionValue[property]);
					orderPositionValue[property] = pricePositionValue[property];
				}
				else {
					console.log('pricePositionValue not found! featureId:', feature.id, pricePosition.values().map(p => p.featureId));
				}
			}
		}
		if (this.featuresInstance) this.featuresInstance.update(this.orderPositionValues);
		this.updateMatchesCount();
	};

	onAttachmentDelete = async (attachment) => {
		if (attachment) {
			this.order.deletedPositionAttachment = attachment.filename;
			Promise.all([
				this.order.save(),
				this.position.attachments.remove(attachment.id)
			]);
		}
	};
	onAttachmentChange = async (attachments) => {
		if (attachments && (attachments.length > 0)) {
			let attachmentArray = [];
			for (const { attachment } of attachments) {
				attachmentArray.push(attachment.filename)
			}
			this.order.addedPositionAttachment = attachmentArray.join(', ')
			await this.order.save();
		}
	}

	copy = async () => {
		const newPosition = await this.position.copy();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.positionId = newPosition.id;
		orderLog.updated = ['newPosition'];
		await orderLog.save();
		this.props?.onClose(true);
	}

	render() {
		if (this.isLoading) return null;

		return <Popup className="order-position-edit" onClose={this.cancel} header={t('orderPosition.title')}>
			<div className="order-form">
				<div className="main">
					<div className="form-field">
						<label>{t('orderPosition.category')}</label>
						<RecordSelect
							model={store.model.PriceCategory}
							property="name"
							value={this.category}
							onChange={this.onCategoryChange}
							filter={{ include: CATEGORY_INCLUDE }}
							isRequired
						/>
					</div>
					<Row>
						<div className="form-field">
							<label>{t('orderPosition.amount')}</label>
							<NumberInput
								value={this.position.amount}
								onChange={this.onAmountChange}
								integerOnly
								positiveOnly
							/>
						</div>
						<div className="form-field">
							<label>{t('orderPosition.unitPrice')}</label>
							<NumberInput
								value={this.position.unitPrice}
								onChange={this.onUnitPriceChange}
								positiveOnly
								decimalScale={2}
							/>
						</div>
						<div className="form-field">
							<label>{t('orderPosition.size')}: </label>
							<Input
								value={this.position.size}
								onChange={this.onSizeChange}
							/>
						</div>
					</Row>
					<div className="form-field">
						<label>{t('comment.title')}</label>
						<textarea
							value={this.position.comment || ''}
							onChange={this.onCommentChange}
							placeholder={t('comment.placeholder')}
							rows={3}
						/>
					</div>
					<Row>
						<div className="form-field">
							<label>{t('orderPosition.price')}</label>
							<NumberInput
								value={this.position.price}
								onChange={this.onPriceChange}
								positiveOnly
								decimalScale={2}
							/>
						</div>
						<div className="form-field">
							<label>{t('orderPosition.selectedPricePositionName')}</label>
							<Input
								value={this.position.pricePositionName}
								disabled
							/>
						</div>
					</Row>
					<Row>
						<div className="form-field">
							<label>{t('orderPosition.discountPercent')}</label>
							<NumberInput
								value={this.position.discountPercent}
								onChange={this.onDiscountPercentChange}
								positiveOnly
								decimalScale={2}
							/>
						</div>
						<div className="form-field">
							<label>{t('pricePosition.plural')}</label>
							<MatchesCount
								orderPosition={this.position}
								category={this.category}
								orderPositionValues={this.orderPositionValues}
								getInstance={this.getMatchesCountInstance}
								onSelect={this.onPricePositionSelect}
								popupClassName='price-position-popup'
							/>
						</div>
					</Row>
					<Row>
						<div className="form-field">
							<label>{t('orderPosition.final')}</label>
							<NumberValue
								value={this.position.final}
								type="currency"
							/>
						</div>
						<div className="form-field">
							<label>{t('orderPosition.discountDirect')}</label>
							<NumberInput
								value={this.position.discount}
								onChange={this.onDiscountChange}
								positiveOnly
								decimalScale={2}
							/>
						</div>
					</Row>
					<div className='row align-items-center'>
						<div className='col'>
							<div className="form-field">
								<label>Ссылка на материалы</label>
								<input
									defaultValue={this.position.attachmentsLink || ''}
									onChange={this.onAttachmentLinkChange}
								/>
							</div>
						</div>
						<div className='col'>
							<Attachments record={this.position} canUpload onDelete={this.onAttachmentDelete} onChange={this.onAttachmentChange} softDelete/>
						</div>
					</div>
				</div>

				<Features
					key={this.category ? this.category.id : 'empty'}
					getInstance={this.getFeaturesInstance}
					category={this.category}
					position={this.position}
					onChange={this.onFeaturesChange}
				/>

				<div className='secondary'>
					<Parameters
						position={this.position}
					/>
					<Members
						position={this.position}
					/>
				</div>
			</div>

			<div className="order-actions">
				<Button onClick={this.save} variant="primary">{t('orderPosition.save')}</Button>
				<Button onClick={this.copy} variant="primary">{t('orderPosition.copy')}</Button>
			</div>

		</Popup>;
	}

}
