import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { FormContext, Button } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class CustomField extends React.Component {
	
	/**
	 * Если это отдельный компонент, как здесь, можно использовать такой синтаксис (автоматически будет определен this.context).
	 * Можно также использовать FormContext.Consumer.
	 */
	static contextType = FormContext;

	@observable comment = null;
	@observable status = '';
	
	createNewComment = () => {
		const { form } = this.context;
		this.comment = new store.model.Comment();
		this.comment.text = 'test ' + new Date().toISOString();
		this.status = 'Создан (но не сохранен) комментарий с текстом: ' + this.comment.text;
		form.addBeforeSaveAction(() => {
			this.comment = null;
			this.status = 'Запись сохранена, несохраненный комментарий канул в лету';
		});
	};

	saveAndAddComment = async () => {
		const { form } = this.context;
		await this.comment.save();
		form.addManyToMany('comments', this.comment.id);
		this.status = 'Созданный комментарий сохранен и добавлен в предварительный список на добавление many-to-many реляции';
		form.addBeforeSaveAction(() => {
			this.comment = null;
			this.status = 'Запись сохранена, созданный комментарий добавлен к записи';
		});
	};
	
	cancel = async () => {
		const { form } = this.context;
		if (this.comment){
			if (this.comment.id) {
				form.removeManyToMany('comments', this.comment.id);
				await this.comment.delete();
				this.status = 'Сохраненный комментарий был убран из предварительного списка и удален';
			}
			else {
				this.status = 'Несохраненный комментарий был уничтожен';
			}
		}
		this.comment = null;
		form.addBeforeSaveAction(() => {
			this.status = 'Запись сохранена без нового комментария';
		});
	}

    render() {
		const { record, form } = this.context;
		
	    /**
	     * В контексте формы лежат следующие свойства:
	     *
	     * record - текущая запись в форме
	     * form - интерфейс формы, содержит набор методов:
	     *      save: сохранить текущую запись,
	     * 		delete: удалить текущую запись,
	     * 		setFile: добавить файл к очереди загрузок при сохранении записи, в аргументе нужно передать объект вида
	     * 		    {
	     * 		        file: сам файл,
	     * 		        property: свойство записи, в которое пишется файл,
	     * 		        label: описание свойства записи
	     * 		    }
	     * 		removeFile: убрать файл из очереди загрузок, в аргументе нужно передать:
	     * 	    	property: свойство записи, в которое пишется файл
	     * 		addManyToMany: добавить к записи many-to-many реляцию, в аргументах нужно передать:
	     * 		    relation: название реляции (например "comments")
	     * 		    id: id добавляемой записи реляции (например comment.id)
	     * 		removeManyToMany: убрать ранее добавленную many-to-many реляцию, аргументы такие же как и у addManyToMany
	     * 		addBeforeSaveAction: функция (может быть асинхронной) - (однократно) добавить некое действие перед сохранением записи
	     * 	    	(когда уже запущен процесс сохранения), в аргументе будет передана текущая запись
	     * 		disabled: признак того, что форма выключена
 	     */
	
	    console.log('record', record);
	    console.log('form', form);
	    
		return <div>
	        custom field: {record.name}
	        <div>
		        {!this.comment ?
			        <Button onClick={this.createNewComment}>Create comment (no save)</Button>
			        :
			        <>
			            {!this.comment.id && <Button onClick={this.saveAndAddComment}>Save comment and add to record</Button>}
				        <Button onClick={this.cancel}>{this.comment.id ? 'Delete comment and remove' : 'Remove comment'}</Button>
			        </>
				}
	        </div>
			<div>{this.status}</div>
        </div>;
    }
}
