import React from 'react';

import { Field, ColorPicker, Row, RecordSelect, Checkbox } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return(
		<ModelEdit model={store.model[modelName]} path={path} id={id} title={`Редактирование объекта`}>
			<div className='row'>
				<div className='col-5'>
					<Field property='name' label='Наименование' />
					<Field property='number' label='Код в программе' />
					<Field property='isAsset'>
						<Checkbox label='Учитывать при рассчёте остатков' />
					</Field>
				</div>
			</div>
		</ModelEdit>
	);
};
