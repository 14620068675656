import React from "react";
import { Switch, Route } from 'react-router-dom';
import store from 'client/store';
import List from './List';
import Edit from './Edit';
const path = '/1c-journal';
const modelName = 'EdFile';

export const EdJournal = () => {
    store.ui.title = "Журнал регистрации";
    return (
        <Switch>
            <Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />}/>
            <Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} />}/>
            <Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />}/>
        </Switch>
    );
};
