import React from 'react';
import { Field, Form, ManyToManySelect, Pager } from '@smartplatform/ui';
import FormContext from '@smartplatform/ui/src/components/form/context';
import { default as translate } from 'i18n';
import { observer } from 'mobx-react';
import { Attachments, BoxLabel } from 'components';
import { observable } from 'mobx';
import { renderStatus, renderUser } from 'client/tools';
import store from 'client/store';
import './style.scss';

const t = (slug) => translate(`document.card.${slug}`);

@observer
export default class Info extends React.Component {
	@observable attachments = [];
	@observable page = 1;
	@observable perPage = 1;

	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.document.id) {
			this.attachments = await this.props.document.attachments();
		}
	};

	get docFiles() {
		return this.attachments.map((attachment) => {
			const attachmentUrl = `${window.location.origin}/api/containers/attachments/download/${attachment.id}-filename`;
			return (
				<div className='preview-file' key={attachment.id}>
					<span>{attachment.filename}</span>
					<iframe src={`https://docs.google.com/viewer?url=${attachmentUrl}&embedded=true`} className='preview-iframe' />
				</div>
			);
		});
	}

	onPageChange = (page) => {
		this.page = page;
	};

	back = () => store.route.push({ path: this.props.path });
	renderLabel = (label) => <BoxLabel label={label} />;

	render() {
		const formProps = {
			className: 'document-form',
			record: this.props.document,
			onDelete: this.back,
			onSave: this.back,
			stay: true,
			noSave: false,
			noDelete: false,
			disableSave: !this.props.document.statusId || !this.props.document.name,
		};
		return (
			<div className='main-info'>
				<Form {...formProps}>
					<div className='form-row'>
						<Field property='name' label={t('name')} isRequired />
						<Field property='number' label={t('number')} />
					</div>
					<div className='form-row'>
						<Field relation='type' property='name' label={t('type')} />
						<Field relation='status' computed={renderStatus} label={t('status')} isRequired />
					</div>
					<Field relation='contragent' property='name' itemTag={(r) => r.name} label={t('contragents')} />
					<div className='form-row'>
						<Field relation='labels' computed={this.renderLabel} itemTag={this.renderLabel} label={t('labels')}>
							<ManyToManySelect className='box-labels-multiselect' noSearch immediatelySave />
						</Field>
						<Field relation='user' computed={renderUser} label={t('responsible')} />
					</div>
					{formProps.record.id && (
						<div className='form-field'>
							<label>Файл:</label>
							<Attachments record={formProps.record} canUpload={true} uploadIsImage />
						</div>
					)}
					<hr />
				</Form>
				<div className='preview'>
					{this.attachments && this.docFiles[this.page - 1]}
					{this.attachments.length > 1 && (
						<Pager
							current={this.page}
							totalCount={this.attachments.length}
							itemsPerPage={this.perPage}
							onChange={this.onPageChange}
						/>
					)}
				</div>
			</div>
		);
	}
}

