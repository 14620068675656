import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { observer } from 'mobx-react';
import './style.scss';
import { observable } from 'mobx';
import { Table, Column, NumberValue } from '@smartplatform/ui';
import { EditBankAccountPopup } from './EditBankAccountPopup';
import { AddButton } from 'components';

@observer
export class MyCompanies extends React.Component {
	@observable companies = [];
	@observable selectedRecord = null;
	@observable selectedContragent = null;
	@observable editPopup = false;

	constructor(props) {
		super(props);
		store.ui.title = t('contragent.myCompanies');
		this.init();
	}
	init = async () => (this.companies = await store.model.Contragent.find({ where: { myCompany: true }, include: ['bankAccounts'] }));

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.editPopup = true;
	};

	closeEditPopup = async (init = false) => {
		this.editPopup = false;
		this.selectedRecord = null;
		this.companyId = null;
		init && (await this.init());
	};

	onAddBankAccount = (company) => {
		this.companyId = company.id;
		this.editPopup = true;
	};

	render() {
		if (this.isLoading) return null;

		//TODO надо сделать ремоут,который посчитал бы баланс счета
		const rndsum = () => +(Math.random() * 100000000).toFixed(0);

		return (
			<div className='my-companies fixed-page'>
				{this.companies.map((company) => {
					const bankAccounts = company.bankAccounts();
					return (
						<div className='contragent-card' key={company.id}>
							<div className='title'>{company.name}</div>
							<div className='bank-accounts'>
								<Table rows={bankAccounts} onRowClick={this.onRowClick}>
									<Column property='name' label={t('name')} />
									<Column property='bank' label={t('bankAccount.bank')} />
									<Column property='checkingAccount' label={t('bankAccount.checkingAccount')} />
									<Column computed={rndsum} label={t('bankAccount.balance')}>
										<NumberValue />
									</Column>
								</Table>
								<AddButton onClick={() => this.onAddBankAccount(company)} />
							</div>
							{this.editPopup && (
								<EditBankAccountPopup
									onClose={this.closeEditPopup}
									record={this.selectedRecord}
									contragentId={this.companyId}
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}

