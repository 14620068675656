import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from '../budgets/List';
import Edit from '../budgets/Edit';

export const percentLeft = budget => {
	const sum = Math.round(budget.sum * budget.percentLeft);
	const percent = Math.round(budget.percentLeft * 100);
	return `${sum} (${percent}%)`;
};

export default ({ match }) => {
	const projectId = match.params.projectId;
	const path = `/projects/:projectId/budgets`;
	const modelName = 'Budget';

	const props = { modelName, projectId };
	
	return <Switch>
		<Route path={path} exact children={({ match }) => <List match={match} pageSize={10} {...props} />}/>
		<Route path={`${path}/page/:page`} children={({ match }) => <List pageSize={10} match={match} {...props} />}/>
		<Route path={`${path}/:budgetId`} children={({ match }) => <Edit match={match} {...props} />}/>
	</Switch>;
}
