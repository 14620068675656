import React from 'react';
import IconTitle from '../../../components/icon-title/IconTitle';
import { faInfoCircle, faShoppingBag, faFileInvoice, faArrowsSpin, faReceipt } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';

import Main from './Main';
import Positions from './order-positions';
const Passport = React.lazy(() => import('./passport/Passport'));
import { TechnologyRoadmap } from './technology-roadmap';
import Waybill from './waybill/Waybill';

export default (path, canViewSum) => [
	{
		path: path,
		exact: true,
		title: <IconTitle faIcon={faInfoCircle} text={t('order.info')} />,
		component: (props) => <Main {...props} />,
	},
	{
		path: `${path}/positions`,
		title: <IconTitle faIcon={faShoppingBag} text={t('order.positions')} />,
		component: (props) => <Positions />,
		canViewSum: canViewSum,
	},
	{
		path: `${path}/passport`,
		title: <IconTitle faIcon={faFileInvoice} text={t('order.passport')} />,
		component: (props) => (
			<React.Suspense fallback={<div>Loading...</div>}>
				<Passport />
			</React.Suspense>
		),
	},
	{
		path: `${path}/technologyroadmap`,
		title: <IconTitle faIcon={faArrowsSpin} text={t('order.technologyRoadmap')} />,
		component: (props) => <TechnologyRoadmap />,
	},
	{
		path: `${path}/waybill`,
		title: <IconTitle faIcon={faReceipt} text={t('order.waybill.tabname')} />,
		component: (props) => <Waybill />,
	},
];
