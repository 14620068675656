import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { MembersAvatars } from '../members';
import { Labels } from 'components';
import { relative } from 'client/tools';
import t from 'i18n';

@observer
export default class ProjectCard extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { project } = this.props;

		return <>
			<div className="project-card-top">
				<div className={'id' + (project.closed ? ' closed' : '')}># {project.id} {project.closed && <FontAwesomeIcon icon={faCheck} />}</div>
				{/*<div className="id">#{project.id}</div>*/}
				<div className="last-udpate">{relative(project.lastUpdate)}</div>
			</div>
			<div className="project-card-main">
				<div className="name">{project.name}</div>
				<div className="tasks">
					{t('project.tasksCount')}: <em>{project.tasksCount}</em>&nbsp;
					(<em className="opened">{project.openedCount}</em>/<em className="closed">{project.closedCount}</em>)
				</div>
				<div className="client">
					{t('client.title')}: <em>{project.client ? project.client.name : '-'}</em>
				</div>
			</div>
			{(project.labels().length > 0 || project.members().length > 0) && <div className="project-card-bottom evented">
				<div className="labels">
					{project.labels().length > 0 && <Labels record={project} labels={project.labels()} size={8} />}
				</div>
				<MembersAvatars project={project} size={16} avatarsCount={5} />
			</div>}
		</>;
	}

}
