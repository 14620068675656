import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Button } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

/*
const inputs = [
	'Select',
	'RecordSelect',
];
*/

@observer
export default class Editable extends React.Component{

	@observable edit = false;
	@observable changed = false;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}

	onMount = el => this.el = el;

	onMouseDown = e => {
		// console.log('onMouseDown', this.edit, this.el, !this.el.contains(e.target));
		if (this.edit && this.el && !this.el.contains(e.target)) {
			this.submit();
		}
	};

	startEdit = () => {
		if (!this.edit) this.edit = true;
	};

	stopEdit = () => this.edit = false;

	submit = e => {
		if (e) e.stopPropagation();
		this.stopEdit();
		if (!this.changed) return;
		this.changed = false;
		this.props.onSubmit && this.props.onSubmit(this.value);
	};

	onChange = e => {
		this.changed = true;
		this.props.onChange && this.props.onChange(e.target.value);
	};

	render () {
		const { value, rows, placeholder, className, width } = this.props;

		const style = { width };
		const noValue = value !== 0 && !value; // falsy value
		const output = !noValue ? value : (placeholder || '');
		
		const inputProps = {
			value: value || '',
			onChange: this.onChange,
			autoFocus: true,
		};
		
		let input = rows ?
			<textarea rows={rows} {...inputProps} />
			:
			<input type="text" {...inputProps} />;
		
		const _className = classNames('editable', className, {
			empty: noValue,
			textarea: !!rows,
		});

		return <div className={_className} style={style} ref={this.onMount}>
			{this.edit ?
				<>
					{input}
					<div className="submit"><Button variant="link" onClick={this.submit}>{t('ok')}</Button></div>
				</>
				:
				<div className="editable-value" onClick={this.startEdit}>
					<span dangerouslySetInnerHTML={{ __html: output.replace(/[\r\n]/g, '<br />') }}/>
					{/*<FontAwesomeIcon icon={faPencilAlt}/>*/}
				</div>
			}
		</div>
	}
}
