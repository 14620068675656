import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import { RecordSelect, ManyToManySelect, ColorUtils } from '@smartplatform/ui';
import Label from './Label';
import store from 'client/store';
import './style.scss';

@observer
export default class LabelsEdit extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		readOnly: PropTypes.bool,
	};
	
	static defaultProps = {
		relation: 'labels',
		readOnly: false,
	};

	@observable labels = [];
	@observable adding = false;
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.labels = await this.props.record[this.props.relation]();
	};

	@action add = e => {
		e.preventDefault();
		this.adding = true;
	};

	onNewLabelChange = async (label) => {
		this.isLoading = true;
		this.labels.push(label);
		await this.props.record[this.props.relation].add(label.id);
		this.isLoading = false;
	}

	remove = async (label) => {
		const index = this.labels.findIndex(_label => _label.id === label.id);
		if (index !== -1) {
			this.labels.splice(index, 1);
			await this.props.record[this.props.relation].remove(label.id);
		}
		this.adding = false;
	};

	onBlur = () => this.adding = false;

	renderLabel = label => <><Label label={label} size={10} /> {label.name}</>;
	
	render() {
		return <div className="labels-edit">
			{this.labels.map(label => {
				const textColor = ColorUtils.textColor(label.color);
				const style = { background: label.color, color: textColor };
				return <div key={label.id} className="label-item" style={style}>
					{label.name}
					<FontAwesomeIcon icon={faTimes} onClick={() => this.remove(label)} className="label-remove" />
				</div>;
			})}
			{this.adding ?
				<RecordSelect
					model={store.model.Label}
					computed={this.renderLabel}
					filter={{ where: { id: { nin: this.labels.map(label => label.id) }}}}
					onChange={this.onNewLabelChange}
					className="drop-down-sm"
					onBlur={this.onBlur}
					isRequired
					autoActivate
				/>
				:
				!this.props.readOnly ? <a href="#" onClick={this.add} className="label-add"><FontAwesomeIcon icon={faPlus}/></a> : null
			}
		</div>;
	}
	
}
