import React from 'react';

export default ({ usages }) => {
	const usageInfo = [];
	if (usages[0] > 0) usageInfo.push(<span key={0}>позициях прайс-листа (<strong>{usages[0]}</strong> записей)</span>);
	if (usages[0] > 0 && usages[1] > 0) usageInfo.push(<span key={1}>, </span>);
	if (usages[1] > 0) usageInfo.push(<span key={2}>позициях заказов (<strong>{usages[1]}</strong> записей)</span>);

	return <div className="warning">
		<div>Эта характеристика с типом "Список" используется в {usageInfo}.</div>
		<ul>
			<li>Вы можете добавлять новые значения.</li>
			<li>Вы можете редактировать и удалять еще нигде не использованные значения.</li>
			<li>
				Вы также можете редактировать где-либо использованные значения, но <strong>с осторожностью</strong>.<br />
				При изменении такого значения <strong>во всех позициях прайс-листа/заказов</strong> это значение также будет обновлено.<br />
				<ul>
					<li>Можно уточнить значение, например <em>А2</em> -> <em>А2 (420х594)</em>.</li>
					<li>Можно исправить опечатку, например <em>а2</em> -> <em>А2</em>.</li>
					<li>Но избегайте переименовывания значения на <strong>логически другое значение</strong>, например <em>А2</em> -> <em className="red">А3</em> и т.п.<br />
						В этом случае логика всех позиций с таким значением будет нарушена.</li>
				</ul>
			</li>
		</ul>
	</div>
}