import t from 'i18n';
import { INCOME, COSTS, COLORS } from '../constants';

export const getChartOptions = (data = []) => ({
	xAxis: { type: 'time' },
	yAxis: {
		type: 'value',
		max: function (value) {
			return Math.floor(value.max / 1000000) * 1000000 * 1.2;
		},
	},
	tooltip: { trigger: 'axis' },
	series: [INCOME, COSTS]
		.map((paymentType) => ({
			color: COLORS[paymentType],
			name: t('payment.' + paymentType.toLowerCase()),
			type: 'line',
			smooth: true,
			howSymbol: false,
			data: data[paymentType].map(({ total, date }) => [new Date(date).getTime() + 1, total]),
		}))
		.filter(({ data }) => data.length),
	legend: { top: 10 },
	dataZoom: [
		{
			type: 'inside',
			start: 0,
			end: 100,
		},
		{
			start: 0,
			end: 10,
		},
	],
	textStyle: { fontSize: 8 },
});

