import React, { Component } from 'react';
import store from 'client/store';
import t from 'i18n';
import { ModelList, Toolbar } from 'components';
import { Column } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
class List extends Component {
	@observable search = '';
	@observable count = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model.NomenclatureBalanceAccount.count();
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { modelName, path } = this.props;
		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url='/nomenclature-balance-accounts/create' innerText={t('create')} />
					<Toolbar.Search value={this.search} onSearch={this.onSearch} />
					<Toolbar.Count text={t('nomenclature.total')} count={this.count} />
					<Toolbar.ExportBtn />
				</Toolbar>
				<ModelList searchValue={this.search} noHeader noFilters model={store.model[modelName]} path={path} title='Учёт остатков'>
					<Column property='number' label='Код счёта' width={80} />
					<Column property='name' label='Наименование счёта учёта товаров' />
				</ModelList>
			</>
		);
	}
}

export default List;

