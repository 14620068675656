import React from 'react';
import { observable } from 'mobx';
import { observer, Provider, inject } from 'mobx-react';
import { Loader, Tabs, Tab } from '@smartplatform/ui';
import t from 'i18n';
import { relative } from 'client/tools';

import { checkModelAccess } from 'client/tools/checkModelAccess';

import OrderStore from './store';
import store from 'client/store';
import getTabs from './tabs';

import './style.scss';

@inject('store')
@observer
export default class Order extends React.Component {
	@observable store = null;
	@observable error = false;

	constructor(props) {
		super(props);
		this.init();
		this.error = checkModelAccess('Order');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		const orderRelations = [
			'Order',
			'OrderList',
			'Client',
			'Contragent',
			'User',
			'Comment',
			'Label',
			'Attachment',
			'OrderTechPassport',
		];
		for (let relation of orderRelations) {
			const model = store.model[relation].INFO.READ;
			console.log('MODEL', relation, model);
			if (!model) {
				this.error = true;
			}
		}
		this.store = new OrderStore();
		this.store.canViewSum = this.props.store.canViewSum;
		const id = this.props.match.params.id;
		try {
			await this.store.init(id);
		} catch (e) {
			this.error = e.message;
		}
	};

	render() {
		const { canViewSum } = this.props.store;

		const tabs = getTabs(this.props.match.path, canViewSum);

		if (this.error) return <div className='error'>{this.error}</div>;
		const { order } = this.store;

		if (!order)
			return (
				<div className='order-page'>
					<Loader />
				</div>
			);

		return (
			<Provider store={this.store}>
				<div className='order-page'>
					<div className='order-title'>
						<span className='order-number'>
							{t('order.title')} #{order.id}. {order.description}
						</span>
						<span className='updated-at'>
							{t('order.updatedAt')}: {relative(order.updatedAt)}
						</span>
					</div>
					<Tabs className='order-tabs' params={{ id: order.id }}>
						{tabs.map((item, i) => {
							const { canViewSum, ...otherProps } = item;

							if (canViewSum !== undefined) {
								return canViewSum && <Tab key={i} {...otherProps} />;
							}

							return <Tab key={i} {...otherProps} />;
						})}
					</Tabs>
				</div>
			</Provider>
		);
	}
}
