import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { ImageInput, Form, Row, Field, Select } from '@smartplatform/ui';
import { validateEmail } from 'client/tools';
import store from 'client/store';
import { ThemeSwitch } from 'components';
import t from 'i18n';

@observer
export default class Profile extends React.Component {

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable password = '';
	@observable password2 = '';

	constructor(props) {
		super(props);
		store.ui.title = t('user.profile');
		if (store.model.user) this.init();
	}

	@action init = async () => {
		this.error = null;
		this.id = store.model.user.id;
		if (this.id) {
			try {
				this.record = await store.model.User.findById(this.id);
			} catch (e) {
				this.error = e.message;
			}
		}
		this.isLoading = false;
	};

	beforeSave = user => {
		this.error = null;
		if (!user.email) {
			this.error = t('user.emptyEmail');
			return false;
		}
		else if (!validateEmail(user.email)) {
			this.error = t('user.invalidEmail');
			return false;
		}
		if (!user.id) {
			if (this.password.length === 0) {
				this.error = t('password.empty');
				return false;
			}
			else if (this.password2.length === 0) {
				this.error = t('password.repeat');
				return false;
			}
		}
		if (this.password.length > 0 || this.password2.length > 0) {
			if (this.password !== this.password2){
				this.error = t('password.noMatch');
				return false;
			}
			else {
				user.password = this.password;
			}
		}
	};

	onPasswordChange = e => this.password = e.target.value;

	onPassword2Change = e => this.password2 = e.target.value;

	render() {
		if (!store.model.user) return null;
		if (this.isLoading) return '...';
		if (!this.record && this.error) return <div className="error">{this.error}</div>;

		return <div className="profile fixed-page">
			<Form record={this.record} stay noDelete beforeSave={this.beforeSave}>
				<Row>
					<Field property="lastName" label={t('user.lastName')} />
					<Field property="firstName" label={t('user.firstName')} />
					<Field property="middleName" label={t('user.middleName')} />
				</Row>
				<Row>
					<Field property="username" label={t('user.name')} />
					<Field property='email' label={t('user.email')} />
					<Field property='telegram' label={t('user.telegram')} />
				</Row>
				<Row>
					<div className="form-field">
						<label>{t('password.field')}</label>
						<input type="password" value={this.password} onChange={this.onPasswordChange} autoComplete="new-password" />
						<div><small>{t('password.newHint')}</small></div>
					</div>
					<div className="form-field">
						<label>{t('password.repeat')}</label>
						<input type="password" value={this.password2} onChange={this.onPassword2Change} autoComplete="new-password" />
					</div>
					<div />
				</Row>
				<Field property="avatar" label={t('avatar')}><ImageInput width={300} height={300} /></Field>
				<Row>
					<div className="form-field">
						<label>{t('ui.theme')}</label>
						<ThemeSwitch />
					</div>
					<div/>
					<div/>
				</Row>
				{this.error && <div className="error">{this.error}</div>}
			</Form>
		</div>;
	}

}
