import React from 'react';
import { observer } from 'mobx-react';
import { Field, Row } from '@smartplatform/ui';
import { ModelEdit, CancelButton, ExportButton } from 'components';
import store from 'client/store';
import { FIELDS, Suggestions } from 'components/contragents';
import t from 'i18n';

@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.setContragentId = this.setContragentId.bind(this);
	}

	setContragentId(record) {
		record['contragentId'] = this.props.contragentId;
		return record;
	}

	renderButtons = (path) => (
		<>
			<CancelButton
				onClick={() => {
					store.route.push({ path: path });
				}}
			/>
			<ExportButton />
		</>
	);

	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);
		const buttons = this.renderButtons(path);

		return (
			<div className='fixed-page contragent-form'>
				<ModelEdit
					beforeSave={this.setContragentId}
					model={store.model[modelName]}
					id={id}
					path={path}
					title='Банковские реквезиты'
					noDelete
					controls={buttons}
				>
					<div className='row'>
						<div className='col-4'>
							<Field property={FIELDS.BANK} label='Наименование банка'>
								<Suggestions search='bank' />
							</Field>
							<div className='one-field'>
								<Field property={FIELDS.BIC} label='БИК' />
								<Field property={FIELDS.SWIFT} label='SWIFT' />
							</div>
							<div className='one-field'>
								<Field property={FIELDS.INN} label='ИНН Банка' />
								<Field property={FIELDS.KPP} label='КПП Банка' />
							</div>
							<Field property='checkingAccount' label='Рассчётный счёт' />
							<Field property={FIELDS.CORRESPONDENT_ACCOUNT} label='Корр. счёт' />
							<div className='one-field'>
								<Field relation='currency' property='name' label={t('currency.title')} />
								<Field property='registrationNumber' label='Регистрационный номер' />
							</div>
							<Field property={FIELDS.ADDRESS} label='Адрес Банка' />
						</div>
					</div>
				</ModelEdit>
			</div>
		);
	}
}
