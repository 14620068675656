import React from 'react';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Checkbox, Loader } from '@smartplatform/ui';
import pStore from '../store';
import store from 'client/store';
import './style.scss';

// строковые ключи для того, чтобы сохранялся порядок добавления
const key = list => 'list-' + list.id;

@observer
export default class Lists extends React.Component {

	@observable allLists = {};
	@observable project = null;
	@observable lists = [];
	@observable initialized = false;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.id = this.props.match.params.projectId;
		this.project = await store.model.Project.findById(this.id, { include: ['lists'] });
		
		const allLists = {};
		const lists = await store.model.BoardList.find({ order: 'priority desc' });
		for (let list of lists) {
			allLists[key(list)] = { list, count: 0, on: false, loading: false };
		}
		this.project.lists().forEach(list => allLists[key(list)].on = true);
		
		this.allLists = allLists;
		this.initialized = true;

		const promises = Object.values(allLists).map(listObj => new Promise(resolve => {
			store.model.Task
				.count({ boardListId: listObj.list.id, projectId: this.id })
				.then(count => resolve({ list: listObj.list, count }))
				.catch(error => resolve({ list: listObj.list, count: null }));
		}));
		const counts = await Promise.all(promises);

		runInAction(() => {
			counts.forEach(({ list, count }) => {
				this.allLists[key(list)].count = count;
			});
		});
	};
	
	toggle = async (list, value) => {
		this.allLists[key(list)].loading = true;
		this.allLists[key(list)].on = value;
		if (value) {
			await this.project.lists.add(list.id);
		}
		else {
			await this.project.lists.remove(list.id);
		}
		this.allLists[key(list)].loading = false;
		await pStore.load(this.project.id);
	};
	
	render() {
		if (!this.initialized) return <Loader />;
		
		const lists = Object.values(this.allLists).map(listObj => {
			const { list, on, loading, count } = listObj;
			const warn = count > 0 && !on;
			return <div key={list.id} className={'board-list' + (loading ? ' loading' : '')}>
				<div className="color" style={{ background: list.color || '#ddd' }} />
				<Checkbox value={on} onChange={v => this.toggle(list, v)} />
				<div className="name">{list.name} {loading && <Loader size={16} />}</div>
				<div className={'count' + (warn ? ' red' : '')}>
					{count || '-'} {warn && <FontAwesomeIcon icon={faExclamationTriangle} />}
				</div>
			</div>
		});
		
		return <div className="board-lists fixed-page">{lists}</div>;
	}
	
}
