import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Switch, Route, withRouter, matchPath } from 'react-router-dom';

import store from 'client/store';
import './style.scss';

const GRAD_WIDTH = 50;

@withRouter @observer
export default class Tabs extends React.Component {
	
	static propTypes = {
		className: PropTypes.string,
		linksOnly: PropTypes.bool,
		params: PropTypes.object,
	};
	
	static defaultProps = {
		linksOnly: false,
	};
	
	aboutToDrag = false;
	dragging = false;
	deltaX = 0;
	dragStartX = 0;
	
	constructor(props) {
		super(props);
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
		document.addEventListener('mouseup', this.onMouseUp);
		document.addEventListener('mousemove', this.onMouseMove);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
		document.removeEventListener('mouseup', this.onMouseUp);
		document.removeEventListener('mousemove', this.onMouseMove);
	}
	
	gotoItem = path => {
		if (!this.dragging) {
			if (this.props.params) {
				Object.keys(this.props.params).forEach(key => {
					const r = new RegExp(`:${key}`, 'i');
					path = path.replace(r, this.props.params[key]);
				});
			}
			console.log({ path });
			store.route.push({ path, params: {}});
		}
	};
	
	onMount = el => {
		this.el = el;
		this.checkGrads();
	};
	
	onWrapperMount = el => this.wrapper = el;
	onLeftGradMount = el => this.leftGrad = el;
	onHorMount = el => this.hor = el;
	onRightGradMount = el => this.rightGrad = el;
	
	onMouseDown = e => {
		if (this.wrapper && this.hor && this.hor.contains(e.target)) {
			// console.log('onMouseDown', this.wrapper.scrollLeft);
			this.dragStartX = e.clientX + this.hor.scrollLeft;
			this.aboutToDrag = true;
		}
	};
	
	onMouseUp = e => {
		// if (this.dragging) e.stopPropagation();
		// console.log('onMouseUp', this.hor.scrollLeft);
		this.aboutToDrag = false;
		setTimeout(() => {
			this.dragging = false;
		}, 0);
	};
	
	onMouseMove = e => {
		if (this.wrapper && (this.aboutToDrag || this.dragging)) {
			this.deltaX = this.dragStartX - e.clientX;
			if (this.deltaX > 3) {
				this.dragging = true;
				this.aboutToDrag = false;
			}
			// console.log('onMouseMove', this.hor.scrollLeft, this.wrapper.offsetWidth - this.hor.offsetWidth - this.hor.scrollLeft);
			this.hor.scrollTo(this.deltaX, 0);
			this.checkGrads();
		}
	};
	
	checkGrads = () => {
		if (this.leftGrad) {
			this.leftGrad.style.opacity = Math.min(this.hor.scrollLeft / GRAD_WIDTH, 1);
		}
		if (this.rightGrad) {
			this.rightGrad.style.opacity = Math.min((this.wrapper.offsetWidth - this.hor.offsetWidth - this.hor.scrollLeft - 15) / GRAD_WIDTH, 1);
		}
	};
	
	render() {
		const tabs = [];
		const children = [];
		
		let activeItem = 0;
		
		// убирает лишние чилдрены с null/false, чтобы не было лишних индексов 
		// и корректно сработал activeItem, если табы рендерятся по условию
		const filteredChildren = this.props.children.filter(ch => !!ch);

		// React.Children.forEach(this.props.children, (child, i) => {
		React.Children.forEach(filteredChildren, (child, i) => {
			if (child && child.props && child.props.isItem) {
				const { path, exact } = child.props;
				const match = matchPath(store.route.path, { path, exact });
				if (match) activeItem = i;
				// console.log('>', path, match, child.props);
				tabs.push(child.props);
			}
			else {
				children.push(child);
			}
		});
		
		const titles = tabs.map((tab, i) => (
			<div
				key={i}
				className={'route-tab' + (i === activeItem ? ' active' : '')}
				onClick={e => this.gotoItem(tab.path)}>
				{tab.title}
				{tab.badge && <div className="tab-badge">{tab.badge}</div>}
			</div>
		));
		
		const routes = !this.props.linksOnly ?
			tabs.map((tab, i) => {
				const _routes = [];
				const { isItem, badge, ...props } = tab;
				// return <Route key={i} {...props} />;
/*
				return <Route
					key={i}
					path={tab.path}
					exact={tab.exact}
					component={tab.component}
					render={tab.render}
					children={tab.children}
				/>;
*/
				_routes.push(<Route key={i} {...props} />);
				if (tab.alias) _routes.push(<Route key={i} {...props} path={tab.alias} exact={false} />);
				return _routes;
			})
			:
			null;
		
		const className = classNames('route-tabs-container', this.props.className);
		
		return <div className="route-tabs">
			<div className={className} ref={this.onMount}>
				<div className="route-tabs-hor" ref={this.onHorMount}>
					<div className="route-tabs-wrapper" ref={this.onWrapperMount}>
						{titles}
					</div>
				</div>
				<span className="left-grad" ref={this.onLeftGradMount}/>
				<span className="right-grad" ref={this.onRightGradMount}/>
			</div>
			{!this.props.linksOnly ? <Switch>{routes}</Switch> : null}
			{children}
		</div>;
	}
	
}
