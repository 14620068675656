import React from 'react';

import { Popover } from '@smartplatform/ui';

const Label = ({ label, className, size, onClick }) => {
	const style = { background: label.color };
	if (size) {
		style.width = size + 'px';
		style.height = size + 'px';
	}
	return <Popover key={label.id} content={label.name} contentClassName="label-popup">
	 	<span className={'label' + (className ? ' ' + className : '')} style={style} onClick={onClick} />
	</Popover>;
	// return <span className="label" style={style} onClick={onClick} />;
};

export default Label;
