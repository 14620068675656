import React from 'react';
import { Switch, Route } from 'react-router-dom';
import store from 'client/store';
import List from './List';
import Edit from './Edit';

const path = '/nomenclature-balance-accounts';
const modelName = 'NomenclatureBalanceAccount';

export const NomenclatureBalanceAccounts = (props) => {
	store.ui.title = 'Синхронизируемые объекты (справочники)';
	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />} />
			<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />} />
		</Switch>
	);
};
