import { observable } from 'mobx';
import store from 'client/store';
import { MODES } from 'client/constants';
import { LOCAL_STORAGE_FIELDS } from './constants';
import debounce from 'lodash/debounce';

export default class ProjectsStore {
	@observable mode = MODES.KANBAN;
	@observable user = store.model.user;
	@observable search = '';
	@observable isLoading = true;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.loadLocalStorage();
		this.count = await store.model.Project.count();
		this.isLoading = false;
	};

	//toolbar
	onChange = (prop) => (value) => {
		this[prop] = value;
		this.doSearch();
		this.saveToLocalStorage();
	};

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.reload?.();
	};

	doSearch = () => this.reload?.();

	onModeChange = (mode) => {
		this.mode = mode;
		this.saveToLocalStorage();
	};

	// localstorage
	localStorage = store.local.projects;
	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};
	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		storageKeys.length && storageKeys.forEach((key) => (this[key] = this.localStorage[key]));
	};
}

