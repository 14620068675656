import React from 'react';

import { Column, NumberValue } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import { percentLeft } from '.';
import t from 'i18n';

export default ({ modelName, path, match, title, projectId, pageSize }) => {
	const properties = projectId ? { projectId } : undefined;
	const filter = {
		include: [{ relation: 'project', scope: { fields: ['id', 'name'] }}],
		where: properties,
	};
	
	const props = { model: store.model[modelName], path, filter, properties, match, title, pageSize };
	
	return <ModelList {...props}>
		<Column relation="project" property="name" label={t('project.title')} />
		<Column property="name" label={t('name')} />
		<Column property="sum" label={t('sum')} className="text-right"><NumberValue type="currency" /></Column>
		<Column width={160} property="paid" type="currency" label={t('budget.paid')} className="text-right"><NumberValue type="currency" /></Column>
		<Column property="tax" width={90} label={t('tax')} className="text-center"><NumberValue type="percent" /></Column>
		<Column width={120} computed={percentLeft} label={t('percentLeft')} className="text-right" />
	</ModelList>;
};
