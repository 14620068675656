import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList, Toolbar, DeleteButton } from 'components';
import store from 'client/store';
import FormContext from '@smartplatform/ui/src/components/form/context';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import t from 'i18n';

@observer
export default class extends React.Component {
	static contextType = FormContext;
	@observable hiddenFields = [];
	@observable count = null;
	@observable reloads = 0;
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model[this.props.modelName].count({
			contragentId: this.props.contragentId,
		});
	};

	onDelete = async (record) => {
		await record.delete();
		this.init();
		this.reloads++;
	};

	onCreate = () => {
		store.route.push({
			path: `${this.props?.match?.url}/create`,
		});
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	renderActions = ({ record }) => <DeleteButton onConfirm={() => this.onDelete(record)} />;

	render() {
		return (
			<>
				<Toolbar>
					<Toolbar.CreateBtn onClick={this.onCreate} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
					<Toolbar.Count count={this.count} text={t('total')} />
				</Toolbar>
				<ModelList
					noHeader
					noFilters
					model={store.model[this.props.modelName]}
					title='Банковские реквизиты'
					filter={{
						where: { contragentId: this.props.contragentId },
					}}
					searchValue={this.search}
					noCount
					key={this.reloads}
				>
					<Column property='personName' label='ФИО' />
					<Column property='personRole' label='Должность' />
					<Column property='phone' label='Телефон' />
					<Column property='email' label='E-mail' />
					<Column property='comment' label='Комментарий' />
					<Column>{this.renderActions}</Column>
				</ModelList>
			</>
		);
	}
}
