import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';

export const ORDERS_LIMIT = 10;
export default class OrderStore {

	@observable order = null;
	@observable canViewSum = false;
	@observable isTechPassportChanged = false;
	@observable techPassport = null;
	@observable isTechPassportEditable = false;

	constructor() {
	}

	init = async (id) => {
		const roles = store.model.roles.map(role=>role.name);
		this.isTechPassportEditable = roles.includes('production') || roles.includes('admin');

		if (!id) {
			this.order = new store.model.Order();
			await this.order.save();
		}
		else {
			if (!parseInt(id)) {
				throw new Error(t('invalidId') + ': ' + id);
			}
			else {
				this.order = await store.model.Order.findById(id, {
					include: [
						{ relation: 'list', scope: { fields: ['id', 'name', 'color'] } },
						{ relation: 'client', scope: { include: { relation: 'orders', scope: { order: 'id desc', limit: ORDERS_LIMIT } } } },
						{
							relation: 'contragent',
							scope: {
								include: [
									{ relation: 'orders', scope: { order: 'id desc', limit: ORDERS_LIMIT }},
									{ relation: 'staff', scope: { order: 'personName asc' }},
								]
							},
						},
						{ relation: 'manager', scope: { order: 'lastName asc, firstName asc, middleName asc ' } },
						{ relation: 'positions', scope: { order: 'id asc' } },
						{ relation: 'techPassport' },
					]
				});
			}
			if (!this.order.techPassportId) {
				this.techPassport = new store.model.OrderTechPassport();
				await this.techPassport.save();
				this.order.techPassportId = this.techPassport.id;
				await this.order.save();
			} else {
				this.techPassport = this.order.techPassport;
			}
		}
	};

	save = async () => {
		await this.order.save();
		await this.updateHistory();
	};

	delete = async () => {
		await this.order.delete();
		store.route.push({ path: '/orders' });
	};

	updateHistory = async () => {
		if (this.historyInstance) this.historyInstance.reload();
	};

}
