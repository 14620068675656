import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import CategoryBtn from './category-btn';
import store from 'client/store';
import t from 'i18n';

@withRouter
@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable canDelete = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { match } = this.props;
		const id = parseInt(match.params.id);
		const childrenCount = await store.model.PriceCategory.count({ parentId: id });
		this.canDelete = !childrenCount;
	};

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id === 'create') {
			this.init();
		}
	}

	getRecord = (record) => {
		const { location } = this.props;
		record.parentId = new URLSearchParams(location.search).get('parentId');
		this.record = record;
	};

	onCategorySelect = async (categoryId) => {
		this.record.parentId = categoryId;
		await this.record.save();
	};

	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);

		return (
			<>
				<ModelEdit
					model={store.model[modelName]}
					stay
					path={path}
					id={id}
					noDelete={!this.canDelete}
					getRecord={this.getRecord}
					title={t('priceCategory.title')}
				>
					<Field property='name' label={t('name')} />
					{!!id && (
						<div>
							<CategoryBtn
								onSelect={this.onCategorySelect}
								exclude={id}
								disableRoot={this.record && this.record.parentId === null}
								disabled={!this.record}
							>
								{t('priceCategory.moveTo')}
							</CategoryBtn>
						</div>
					)}
				</ModelEdit>
				{!this.canDelete && !!id && <div style={{ color: '#CD4141' }}>Нельзя удалить, пока есть вложенные категории</div>}
			</>
		);
	}
}
