import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ProjectsList from './Projects';
import Project from './Project';
import Edit from './Edit';
import CreateTask from './CreateTask';

import './style.scss';

const path = '/projects';
const modelName = 'ViewProject';

export const Projects = () => (
	<Switch>
		<Route path={path} exact>
			<ProjectsList path='' modelName={modelName} />
		</Route>
		<Route path={`${path}/page/:page`} children={({ match }) => <ProjectsList path='' match={match} modelName={modelName} />} />
		<Route path={`${path}/create`} component={Edit} />
		<Route path={`${path}/:id`} component={Project} />
		<Route path={`${path}/:id/createTask`} component={CreateTask} />
	</Switch>
);
