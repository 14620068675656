import React from 'react';

import { Table } from '@smartplatform/ui';
import './style.scss';

const Wrapper = ({ children }) => <div className="table-wrapper">{children}</div>;
const WrappedTable = props => <Table wrapper={Wrapper} {...props} />;

WrappedTable.propTypes = {...Table.propTypes};

export default WrappedTable;
