import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import FormContext from "@smartplatform/ui/src/components/form/context";
import { AUTOCOMPLETE, Suggestions } from "components/contragents";
import { Field, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';


@observer
export default class Create extends React.Component {
	@observable record = null;
	render () {
		const { modelName, path, match } = this.props;
		return <div className="fixed-page">
			<ModelEdit model={store.model[modelName]} stay path={path} title={t('client.title')}>
				<FormContent />
			</ModelEdit>
		</div>;
	}
};

class FormContent extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.handleSelectSuggestions = this.handleSelectSuggestions.bind(this);
	}
	handleSelectSuggestions(suggestion) {
		if(!suggestion || !this.context.record) return;
		Object.keys(AUTOCOMPLETE).forEach(property => {
			this.context.record[property] = AUTOCOMPLETE[property](suggestion)
		})
	}
	render() {
		return(
			<Field property="name" label={t('client.title')}>
				<Suggestions onSelect={this.handleSelectSuggestions} />
			</Field>
		);
	}
}