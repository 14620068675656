import React from 'react';
import { Button } from '@smartplatform/ui';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { ExportButton, ImportButton, ChartButton } from 'components/buttons';
import { COSTS, INCOME, TRANSFER } from '../constants';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@inject('store')
@observer
export class ActionBar extends React.Component {
	showEditPopup = (paymentType) => () => {
		this.props.store.toggleProp('showEditPopup')();
		this.props.store.addPaymentType = paymentType;
	};
	render() {
		const { store } = this.props;
		const { toggleProp, showCharts } = store;

		return (
			<div className='action-bar'>
				<Button variant='primary' onClick={this.showEditPopup(INCOME)}>
					<FontAwesomeIcon icon={faPlus} />
					{t('payment.income')}
				</Button>
				<Button variant='primary' onClick={this.showEditPopup(COSTS)}>
					<FontAwesomeIcon icon={faMinus} />
					{t('payment.costs')}
				</Button>
				<Button variant='primary' onClick={this.showEditPopup(TRANSFER)}>
					{t('payment.transfer')}
				</Button>
				<ExportButton disabled />
				<ImportButton disabled />
				<ChartButton onClick={toggleProp('showCharts')} className={showCharts ? 'active' : undefined} />
			</div>
		);
	}
}
