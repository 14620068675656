import React from "react";
import store from 'client/store';
import t from 'i18n';
import { ModelList } from 'components';
import { Column, ColorValue } from '@smartplatform/ui';


const List = ({ modelName, path }) => {
    return (
        <ModelList disableFilters model={store.model[modelName]} path={path} title="История синхронизаций">
            <Column property="date" label="Дата синхронизации" width={150}/>
            <Column relation="node" property="nodeCode" label="Информационная база"/>
            <Column property="status" label="Статус синхронизации"/>
            <Column property="message" label="Сообщение об ошибке"/>
        </ModelList>
    );
}

export default List;