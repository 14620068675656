import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@smartplatform/ui';
import { User, SmallDeleteButton } from 'components';
import MemberEdit from './MemberEdit';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Members extends React.Component {

	@observable members = [];
	@observable currentMember = null;
	@observable newMember = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.members = await this.props.position.members.find();
	};

	onListMount = el => this.list = el;

	addMember = async () => {
		this.newMember = new store.model.OrderPositionMember();
		this.newMember.position = this.props.position;
		setTimeout(() => {
			if (this.list) this.list.scrollTop = this.list.scrollHeight;
		}, 100);
	};

	edit = (member) => this.currentMember = member;

	delete = async (member) => {
		await member.delete();
		this.reload();
	};

	reload = () => {
		this.stopEdit();
		this.init();
	};

	@action stopEdit = () => {
		this.newMember = null;
		this.currentMember = null;
	};

	render() {
		return <div className="position-members">
			<div className="form-field">
				<label>{t('orderPosition.members')}</label>
			</div>
			{(this.members.length > 0 || this.newMember) && <div className="members-list" ref={this.onListMount}>
				{this.members.map(member => {
					if (this.currentMember && this.currentMember.id === member.id) {
						return <MemberEdit
							member={member}
							exclude={this.members.map(m => m.userId)}
							onSave={this.onSave}
							onCancel={this.stopEdit}
						/>;
					}
					return <div key={member.id} className="member">
						<div className="user"><User user={member.user}/></div>
						<div className="role">{member.role ? member.role.name : '-'}</div>
						<div className="actions">
							<span className="edit-btn" onClick={() => this.edit(member)}><FontAwesomeIcon icon={faPencilAlt} /></span>
							<SmallDeleteButton onConfirm={() => this.delete(member)}/>
						</div>
					</div>;
				})}
				{this.newMember &&
					<MemberEdit
						member={this.newMember}
						exclude={this.members.map(m => m.userId)}
						onSave={this.reload}
						onCancel={this.stopEdit}
					/>
				}
			</div>}
			<div className="actions">
				{!this.newMember && <Button variant="primary" onClick={this.addMember}>{t('orderPosition.addMember')}</Button>}
			</div>
		</div>;
	}

}
