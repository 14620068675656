import store from 'client/store';
import { FIELDS, PERIODS, TRANSFER } from './constants';
import t from 'i18n';
import { observable } from 'mobx';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';

export default class AnalyticsStore {
	@observable isLoading = true;
	@observable isInitialized = false;
	@observable chartRecords = [];
	// даты
	@observable period = PERIODS[0];
	@observable startDate = null;
	@observable endDate = null;
	// остальные фильтры
	@observable project = null;
	@observable paymentType = null;
	@observable cashFlows = [];
	@observable contragent = null;
	@observable bankAccounts = [];

	myCompanies = [];
	currentDate = new Date();

	constructor() {}

	init = async () => {
		this.loadLocalStorage();
		this.onPeriodChange(this.period);
		await Promise.all([this.getMyCompanies(), this.fetchDashBoardData()]);
		this.isInitialized = true;
	};

	fetchDashBoardData = async () => {
		if (this.bankAccounts.length) {
			this.isLoading = true;
			const { bankAccountsChart } = await store.model.ViewPayment.getDashboardData({
				startDate: this.startDate,
				endDate: this.endDate,
				bankAccountIds: this.bankAccounts.map((i) => i.id),
				cashFlowIds: this.cashFlows.map((i) => i.id),
				paymentType: this.paymentType,
				contragendId: this.contragent?.id,
				projectId: this.project?.id,
				charts: ['bankAccounts'],
			});
			this.chartRecords = bankAccountsChart || [];
		}
		this.isLoading = false;
	};

	getMyCompanies = async () => {
		this.myCompanies = await store.model.Contragent.find({ where: { myCompany: true }, include: ['bankAccounts'] });
	};

	onChange = (prop) => async (value) => {
		this[prop] = value;
		this.saveToLocalStorage();
		await this.fetchDashBoardData();
	};

	onPeriodChange = async (period) => {
		this.period = period;
		switch (period) {
			case 'currentMonth': {
				this.startDate = startOfMonth(this.currentDate);
				this.endDate = endOfMonth(this.currentDate);
				break;
			}
			case 'currentQuarter': {
				this.startDate = startOfQuarter(this.currentDate);
				this.endDate = endOfQuarter(this.currentDate);
				break;
			}
			case 'currentYear': {
				this.startDate = startOfYear(this.currentDate);
				this.endDate = endOfYear(this.currentDate);
				break;
			}
			case 'all': {
				this.startDate = null;
				this.endDate = null;
				break;
			}
		}
		this.saveToLocalStorage();
		await this.fetchDashBoardData();
	};

	onPaymentTypeChange = async (type) => {
		this.paymentType = type;
		if (type === TRANSFER) {
			this.contragent = null;
		}
		this.cashFlows = [];
		this.saveToLocalStorage();
		await this.fetchDashBoardData();
	};

	cleanFilters = () => {
		FIELDS.forEach((field) => (this[field] = null));
		this.onPeriodChange(PERIODS[0]);
		this.cashFlows = [];
		this.bankAccounts = [];
		this.saveToLocalStorage();
		this.fetchDashBoardData();
	};

	// localstorage
	localStorage = store.local.analytics;
	saveToLocalStorage = () => {
		FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};
	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				const storageValue = this.localStorage[key];
				if (key === 'cashFlows' && !storageValue) {
					this[key] = [];
				} else if (key === 'period' && !storageValue) {
					this[key] = PERIODS[0];
				} else this[key] = storageValue;
			});
	};
}

