import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PriceList from './PriceList';
import PriceCategory from './PriceCategory';

const path = '/pricecategories';
const modelName = 'PriceCategory';

export const PriceCategories = () => <Switch>
	<Route path={path} exact children={({ match }) => <PriceList match={match} modelName={modelName} />}/>
	<Route path={`${path}/page/:page`} children={({ match }) => <PriceList match={match} modelName={modelName} />}/>
	<Route path={`${path}/:id`} children={({ match }) => <PriceCategory match={match} path={path} modelName={modelName} />}/>
</Switch>;
