import React from 'react';
import store from 'client/store';
import { Checkbox, Column } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ModelList, Toolbar } from 'components';
import t from 'i18n';

@observer
class List extends React.Component {
	@observable hideZeroCount = true;
	@observable search = '';

	get filter() {
		const filter = { where: { and: [] } };
		if (this.hideZeroCount) filter.where.and.push({ totalQuantity: { neq: 0 } });
		if (this.props.groupId) filter.where.and.push({ groupId: this.props.groupId });
		if (filter.where.and.length === 0) delete filter.where.and;
		return filter;
	}

	onHideZeroCountChange = (boolen) => (this.hideZeroCount = boolen);

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { path, groupId } = this.props;
		const zeroCheckbox = (
			<Checkbox label={t('nomenclature.onlyNonZeroQuantity')} value={this.hideZeroCount} onChange={this.onHideZeroCountChange} />
		);
		return (
			<div key={groupId}>
				<Toolbar className='toolbar-page'>
					<Toolbar.Search value={this.search} onSearch={this.onSearch} />
				</Toolbar>
				<ModelList
					noHeader
					searchValue={this.search}
					noFilters
					path={path}
					disableCreate
					model={store.model.ViewNomenclature}
					filter={this.filter}
					afterPager={zeroCheckbox}
				>
					<Column property='lastBalanceUpdate' label={t('nomenclature.lastBalanceUpdate')} width={100} />
					<Column property='name' label={t('name')} />
					<Column property='totalQuantity' label={t('nomenclature.quantity')} width={130}></Column>
					<Column property='measure' label={t('nomenclature.measure')} width={80} />
				</ModelList>
			</div>
		);
	}
}

export default List;

