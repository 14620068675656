import { Field, Form, Popup, RecordSelect, RelationSelect, Row } from '@smartplatform/ui';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { COSTS, INCOME, PAYMENT_INCLUDE, TRANSFER } from '../constants';
import './style.scss';

@inject('store')
@observer
export class EditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable splitPayment = false;
	@observable bankAccount1 = null;
	@observable bankAccount2 = null;
	@observable contragent = null;
	@observable paymentType = null;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}
	init = async () => {
		const { selectedRecord } = this.store;
		this.isNew = !selectedRecord;
		if (this.isNew) {
			this.record = new store.model.Payment({ date: new Date() });
			this.paymentType = this.props.store.addPaymentType;
		} else {
			this.paymentType = selectedRecord.paymentType;
			await this.fetchRecord(selectedRecord.id);
		}
		this.isLoading = false;
	};
	fetchRecord = async (id) => {
		this.record = await store.model.Payment.findById(id, { include: PAYMENT_INCLUDE });

		if (this.paymentType === COSTS || this.paymentType === TRANSFER) {
			this.bankAccount1 = this.record.payerBankAccount;
			if (this.paymentType === COSTS) {
				this.contragent = this.record.recipient;
			}
		}

		if (this.paymentType === INCOME) {
			this.bankAccount1 = this.record.recipientBankAccount;
			this.contragent = this.record.payer;
		}
		
		if (this.paymentType === TRANSFER) this.bankAccount2 = this.record.recipientBankAccount;
	};
	onClose = () => {
		this.store.selectedRecord = null;
		this.store.toggleProp('showEditPopup')(false);
	};

	beforeSave = () => {
		if (this.isNew) {
			if (this.paymentType === INCOME) {
				this.record.payerId = this.contragent?.id;
				this.record.recipientBankAccountId = this.bankAccount1?.id;
				this.record.recipientId = this.bankAccount1.contragentId;
			}
			if (this.paymentType === COSTS) {
				this.record.recipientId = this.contragent?.id;
				this.record.payerBankAccountId = this.bankAccount1?.id;
				this.record.payerId = this.bankAccount1.contragentId;
			}
			if (this.paymentType === TRANSFER) {
				this.record.payerBankAccountId = this.bankAccount1?.id;
				this.record.payerId = this.bankAccount1.contragentId;
				this.record.recipientBankAccountId = this.bankAccount2?.id;
				this.record.recipientId = this.bankAccount2.contragentId;
			}
		}
	};
	onAction = () => {
		this.onClose();
		this.store.refetch();
	};
	onChange = (prop) => (value) => {
		this[prop] = value;
	};

	render() {
		if (this.isLoading) return null;
		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;
		const { direction, cashFlow, project } = this.record || {};

		const title = t('payment.' + (this.isNew ? 'add' : 'edit') + this.paymentType[0] + this.paymentType.slice(1).toLowerCase());
		const formContent = (
			<>
				<Row>
					<Field property='total' label={t('payment.total')} />
					<Field property='date' label={t('date')} />
				</Row>
				{this.paymentType === TRANSFER ? (
					<>
						<Row>
							<div className='form-field'>
								<label>{t('payment.bankAccountFrom')}</label>
								<RecordSelect
									model={store.model.ViewBankAccount}
									property='checkingAccount'
									value={this.bankAccount1}
									onChange={this.onChange('bankAccount1')}
									filter={{ where: { myCompany: true, include: ['contragent'] } }}
									showValue={showValue(t('payment.selectBankAccount'), this.bankAccount1?.checkingAccount)}
								/>
							</div>
							<div className='form-field'>
								<label>{t('payment.bankAccountTo')}</label>
								<RecordSelect
									model={store.model.ViewBankAccount}
									property='checkingAccount'
									value={this.bankAccount1}
									onChange={this.onChange('bankAccount2')}
									filter={{ where: { myCompany: true, include: ['contragent'] } }}
									showValue={showValue(t('payment.selectBankAccount'), this.bankAccount2?.checkingAccount)}
								/>
							</div>
						</Row>
						<Field property='description' label={t('description')}>
							<textarea rows={2} />
						</Field>
					</>
				) : (
					<>
						<Row>
							<div className='form-field'>
								<label>{this.paymentType === TRANSFER ? t('payment.bankAccountFrom') : t('payment.bankAccount')}</label>
								<RecordSelect
									model={store.model.ViewBankAccount}
									property='checkingAccount'
									value={this.bankAccount1}
									onChange={this.onChange('bankAccount1')}
									filter={{ where: { myCompany: true, include: ['contragent'] } }}
									showValue={showValue(t('payment.selectBankAccount'), this.bankAccount1?.checkingAccount)}
								/>
							</div>
							<div className='form-field'>
								<label>{t('contragent.title')}</label>
								<RecordSelect
									model={store.model.Contragent}
									property='name'
									value={this.contragent}
									onChange={this.onChange('contragent')}
									filter={{
										where: {
											or: [
												{ myCompany: false },
												{
													myCompany: null,
												},
											],
										},
									}}
									showValue={showValue(t('payment.selectContragent'), this.contragent?.name)}
								/>
							</div>
						</Row>
						<Row>
							<Field relation='cashFlow' property='name' label={t('cashFlow.shortTitle')}>
								<RelationSelect showValue={showValue(t('cashFlow.selectCashFlow'), cashFlow?.name)} isRequired />
							</Field>
							<Field relation='project' property='name' label={t('project.title')}>
								<RelationSelect showValue={showValue(t('payment.selectProject'), project?.name)} />
							</Field>
						</Row>
						<Row>
							<Field relation='direction' property='name' label={t('payment.direction')}>
								<RelationSelect showValue={showValue(t('payment.selectDirection'), direction?.name)} />
							</Field>
							<Field property='number' label={t('number')} />
						</Row>
						<Field property='billingDate' label={t('payment.billingDate')} />
						<Field property='description' label={t('description')}>
							<textarea rows={2} />
						</Field>
					</>
				)}
			</>
		);

		return (
			<Popup className='operation-edit-popup' onClose={this.onClose} width={this.splitPayment ? 1000 : 600}>
				<h2>{title}</h2>
				<Form record={this.record} beforeSave={this.beforeSave} onSave={this.onAction} onDelete={this.onAction} stay>
					{formContent}
				</Form>
			</Popup>
		);
	}
}

