import React from 'react';
import t from 'i18n';
import { faCubes, faScrewdriverWrench, faGears, faDroplet } from '@fortawesome/free-solid-svg-icons';

import { Handbook } from './handbook';
import { Workshops } from './workshops';
import { MaterialTypes } from './material-types';
import MaterialIcon from '!!svg-react-loader!client/img/icons/materials.svg';
import IconTitle from '../../components/icon-title/IconTitle';

export const path = '/technicalroadmap';

export const tabs = [
	{
		path: path + '/workshop',
		title: t('process.workshop.plural'),
		iconTitle: <IconTitle faIcon={faCubes} text={t('process.workshop.plural')} />,
		Component: Workshops,
		modelName: 'ProcessWorkshop',
	},
	{
		path: path + '/equipments',
		title: t('process.equipment'),
		iconTitle: <IconTitle faIcon={faScrewdriverWrench} text={t('process.equipment')} />,
		Component: Handbook,
		modelName: 'ProcessEquipment',
		relationLabel: t('process.workshop.title'),
		relation: 'workshop',
	},
	{
		path: path + '/operations',
		title: t('process.operation.plural'),
		iconTitle: <IconTitle faIcon={faGears} text={t('process.operation.plural')} />,
		Component: Handbook,
		modelName: 'ProcessOperation',
		relationLabel: t('process.workshop.title'),
		relation: 'workshop',
	},
	{
		path: path + '/materialtypes',
		title: t('material.type.plural'),
		iconTitle: <IconTitle icon={<MaterialIcon />} text={t('material.type.plural')} />,
		Component: MaterialTypes,
		modelName: 'MaterialType',
	},
	{
		path: path + '/materials',
		title: t('material.plural'),
		iconTitle: <IconTitle faIcon={faDroplet} text={t('material.plural')} />,
		Component: Handbook,
		modelName: 'Material',
		relationLabel: t('material.type.title'),
		relation: 'materialType',
	},
];
