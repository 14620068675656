import t from 'i18n';

export const ALL = 'ALL';
export const NONE = 'NONE';

export const CLOSED_BUTTONS_ITEMS = [
	{ name: 'active', value: false },
	{ name: 'closed', value: true },
	{ name: 'all', value: null },
];
export const ORDER_BUTTONS_ITEMS = [
	{ label: t('task.sortByUpdated'), value: 'updatedAt desc' },
	{ label: t('task.dueDate'), value: 'dueDateBoolean desc, dueDate desc' },
	{ label: t('task.priority'), value: 'priority desc' },
];

export const TASKS_INCLUDE = [
	{ relation: 'project', scope: { fields: ['id', 'name'] } },
	{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] } },
];

export const LOCAL_STORAGE_FIELDS = ['boardLists', 'closed', 'user', 'order'];

