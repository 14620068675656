import React from 'react';

import { Column, NumberValue } from '@smartplatform/ui';
import { ModelList, User } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match, title, filter, pageSize }) => {
	const _filter = {
		include: [
			{ relation: 'person' },
			{ relation: 'role', scope: { fields: ['id', 'name'] }},
			{ relation: 'budget', scope: { fields: ['id', 'name'] }},
		],
		...filter,
	};
	
	const props = { path, match, title, pageSize, filter: _filter };
	const renderPerson = person => <User user={person} size={20} />;
	
	return <>
		<ModelList model={store.model[modelName]} {...props}>
			<Column relation="person" property="lastName" label={t('person.title')} computed={renderPerson} />
			{/*<Column relation="role" property="name" label={t('projectRole.title')}/>*/}
			<Column relation="budget" property="name" label={t('budget.title')}/>
			<Column property="sum" label={t('sum')} width={90} className="text-right"><NumberValue type="currency"/></Column>
			<Column property="percent" label={t('percent')} width={90} className="text-right"><NumberValue type="percent"/></Column>
			<Column property="price" label={t('price')} width={90} className="text-right"><NumberValue type="currency"/></Column>
			<Column property="paid" label={t('budgetRole.paid')} width={90} className="text-right"><NumberValue type="currency"/></Column>
			<Column width={80} property="agent" label={t('agent')} className="text-center"/>
		</ModelList>
	</>;
};
