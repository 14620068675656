import React from 'react';
import { User } from 'components';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

export const renderUser = (user) => (user ? <User user={user} size={24} /> : '-');
export const renderStatus = (boardList) => (
	<div className='render-status'>
		<span className='color' style={{ background: boardList.color }} /> {boardList.name}
	</div>
);

export const renderBankAccount = (bankAccount) => <div>{bankAccount?.name || bankAccount?.checkingAccount}</div>;

export const renderContragent = (contragent) =>
	contragent && (
		<div className='render-contragent'>
			<span className='name'>{contragent.name}</span>
			{contragent.myCompany && <FontAwesomeIcon icon={faHome} />}
		</div>
	);

export const renderMoney = (sum, fixed = 0, local = 'ru-RU') => (sum && Number(sum.toFixed(fixed)).toLocaleString(local)) || 0;
export const renderPlaceholder = (placeholder) => placeholder && <span className='placeholder'>{placeholder}</span>;

