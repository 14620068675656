import React from 'react';
import { observer } from 'mobx-react';

import Toolbar from './toolbar';
import TasksBoard from './TasksBoard';

@observer
export default class Tasks extends React.Component {
	
	render() {
		return <>
			<Toolbar {...this.props} />
			<TasksBoard {...this.props} />
		</>;
	}
	
}
