import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ModelList } from 'components';
import t from 'i18n';
import React, { Component } from 'react';
import { checkModelAccess } from 'client/tools/checkModelAccess';
import { observer } from 'mobx-react';

@observer
export default class List extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { modelName, path, title, searchValue } = this.props;
		const error = checkModelAccess(modelName);

		if (error) return <div className='error'>{error}</div>;

		const renderCount = (record) => record.length;

		return (
			<>
				<ModelList
					noHeader
					noFilters
					searchValue={searchValue}
					model={store.model[modelName]}
					path={path}
					title={title}
					filter={{ include: ['operations', 'equipments'] }}
				>
					<Column property='name' label={t('name')} />
					<Column relation='operations' computed={renderCount} label={t('operation.count')} />
					<Column relation='equipments' computed={renderCount} label={t('equipment.count')} />
				</ModelList>
			</>
		);
	}
}
