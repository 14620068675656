import { observable } from 'mobx';
import store from 'client/store';
import { FIELDS, PAYMENT_INCLUDE, PERIODS, PER_PAGE } from './constants';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';

export default class OperationsStore {
	@observable isLoading = true;
	@observable isInitialized = false;

	@observable showCharts = true;
	@observable showEditPopup = false;
	@observable selectedRecord = null;
	@observable addPaymentType = null;
	// даты
	@observable period = PERIODS[0];
	@observable startDate = null;
	@observable endDate = null;
	// остальные фильтры
	@observable planFactGroup = null;
	@observable projects = [];
	@observable cashFlows = [];
	@observable directions = [];
	@observable contragents = [];
	@observable bankAccounts = [];
	@observable query = { order: 'date desc' };
	@observable page = 1;
	@observable paymentTypeTotal = null;
	myCompanies = [];
	currentDate = new Date();

	constructor() {}

	init = async () => {
		this.onPeriodChange(this.period);
		await Promise.all([this.fetchDashBoardData(), this.getMyCompanies()]);
		this.isInitialized = true;
	};

	getMyCompanies = async () => {
		this.myCompanies = await store.model.Contragent.find({ where: { myCompany: true }, include: ['bankAccounts'] });
	};

	fetchDashBoardData = async () => {
		this.isLoading = true;
		const { paymentTypeTotal, moneyOnBankAccountsChart } = await store.model.ViewPayment.getDashboardData({
			startDate: this.startDate,
			endDate: this.endDate,
			bankAccountIds: this.bankAccounts.map((i) => i.id),
			cashFlowIds: this.cashFlows.map((i) => i.id),
			contragentIds: this.contragents.map((i) => i.id),
			projectIds: this.projects.map((i) => i.id),
			directionIds: this.directions.map((i) => i.id),
			charts: ['pie', 'moneyOnBankAccounts'],
		});
		this.chartRecords = moneyOnBankAccountsChart || [];
		this.paymentTypeTotal = paymentTypeTotal || [];
		this.isLoading = false;
	};

	// обновление дашборда и таблицы при закрытии попапа редактирования
	refetch = () => {
		this.fetchDashBoardData();
		this.fetchTable?.();
	};

	get queryFilter() {
		const filter = {
			where: { and: [] },
			include: PAYMENT_INCLUDE,
			limit: PER_PAGE,
			skip: PER_PAGE * (this.page - 1),
			order: this.query.order,
		};
		if (this.cashFlows.length) filter.where.and.push({ cashFlowId: { inq: this.cashFlows.map((i) => i.id) } });
		if (this.projects.length) filter.where.and.push({ projectId: { inq: this.projects.map((i) => i.id) } });
		if (this.directions.length) filter.where.and.push({ directionId: { inq: this.directions.map((i) => i.id) } });

		if (this.contragents.length)
			filter.where.and.push({
				or: [{ payerId: { inq: this.contragents.map((i) => i.id) } }, { recipientId: { inq: this.contragents.map((i) => i.id) } }],
			});

		if (this.bankAccounts.length) {
			const bankAccountIds = this.bankAccounts.map((i) => i.id);
			filter.where.and.push({
				and: [
					{
						and: [
							{ or: [{ payerBankAccountId: { inq: bankAccountIds } }, { recipientBankAccountId: { inq: bankAccountIds } }] },
						],
					},
				],
			});
		}
		if (this.startDate) filter.where.and.push({ date: { gte: this.startDate } });
		if (this.endDate) filter.where.and.push({ date: { lte: this.endDate } });
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.fetchDashBoardData();
	};

	toggleProp = (prop) => (value) => {
		this[prop] = typeof value === 'boolean' ? value : !this[prop];
	};

	cleanFilters = () => {
		FIELDS.forEach((field) => {
			if (['cashFlows', 'bankAccounts', 'projects', 'contragents', 'directions'].includes(field)) {
				this[field] = [];
			}
		});
		this.showCharts = true;
		this.onPeriodChange(PERIODS[0]);
		this.fetchDashBoardData();
	};

	onQueryUpdate = (query) => {
		if (query._totalCount === undefined) {
			query._totalCount = this.query._totalCount || 0;
		}
		this.query = query;
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.showEditPopup = true;
	};

	getReloadFunc = (instance) => (this.fetchTable = instance.reload);

	onPeriodChange = (period) => {
		this.period = period;
		switch (period) {
			case 'currentMonth': {
				this.startDate = startOfMonth(this.currentDate);
				this.endDate = endOfMonth(this.currentDate);
				break;
			}
			case 'currentQuarter': {
				this.startDate = startOfQuarter(this.currentDate);
				this.endDate = endOfQuarter(this.currentDate);
				break;
			}
			case 'currentYear': {
				this.startDate = startOfYear(this.currentDate);
				this.endDate = endOfYear(this.currentDate);
				break;
			}
			case 'all': {
				this.startDate = null;
				this.endDate = null;
				break;
			}
		}
		this.fetchDashBoardData();
	};
}

