export const DOCUMENT_INCLUDE = [
	{ relation: 'user', scope: { fields: ['id', 'avatar', 'lastName', 'firstName', 'middleName'] } },
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color'],
		},
	},
	{
		relation: 'contragent',
		scope: {
			fields: ['id', 'name'],
		},
	},
];

export const LOCAL_STORAGE_FIELDS = ['mode'];

