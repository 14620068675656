import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { Button, Loader, RecordSelect } from '@smartplatform/ui';
import { DeleteButton } from 'components';
import Members from './members/Members';
import Payments from './payments/Payments';
import { percentLeft } from '.';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@withRouter @observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable error = null;
	@observable nameError = null;
	@observable editComment = false;
	
	constructor(props) {
		super(props);
		store.ui.title = t('budget.title');
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.budgetId !== this.props.match.params.budgetId) {
			this.init();
		}
	}
	
	init = async () => {
		this.id = parseInt(this.props.match.params.budgetId);
		if (this.id) {
			try {
				this.record = await store.model.Budget.findById(this.id, {
					include: [
						{ relation: 'project', scope: { fields: ['id', 'name' ] }},
						{ relation: 'payments', scope: { order: 'date desc' }},
					]
				});
			}
			catch(e) {
				this.error = e.message;
			}
		}
		else {
			this.error = 'No ID';
		}
	};
	
	onNameChange = e => {
		this.nameError = null;
		this.nameChanged = true;
		this.record.name = e.target.value;
	}
	
	onNameBlur = async e => {
		if (this.nameChanged) {
			await this.record.patchAttributes({ name: this.record.name });
			this.nameChanged = false;
		}
	};
	
	onProjectChange = record => {
		this.record.project = record;
	};
	
	delete = async () => {
		await this.record.delete();
		// store.route.push({ path: `/budgets` });
		this.props.history.push(`/budgets`);
	};
	
	autoHeight = el => {
		if (el) {
			el.style.height = '5px';
			const height = el.scrollHeight + 2;
			el.style.height = Math.max(height, 69) + 'px';
		}
	};
	
	onCommentChange = e => {
		this.record.comment = e.target.value;
		this.autoHeight(e.target);
		this.commentChanged = true;
	}
	
	toggleComment = e => {
		e.preventDefault();
		this.editComment = true;
	};

	submitComment = async e => {
		e.preventDefault();
		await this.record.patchAttributes({ comment: this.record.comment });
		this.editComment = false;
	};

	render() {
		if (this.error) return <div className="error">{this.error}</div>;
		if (!this.record) return <Loader />;
		
		const incoming = this.record.payments().filter(payment => !payment.personId);
		const lastIncoming = incoming[0];
		
		return <div className="budget-edit fixed-page">
			<div className="name">
				<input className={this.nameError ? 'error' : ''} value={this.record.name} onChange={this.onNameChange} onBlur={this.onNameBlur} />
				<div className="error">{this.nameError}</div>
			</div>
			{this.record.project ?
				<div className="project">
					<label>{t('project.title')}:</label> <Link to={`/projects/${this.record.project.id}`}>{this.record.project.name}</Link>
				</div>
				:
				<div className="form-field project">
					<label>{t('project.title')}</label>
					<RecordSelect
						model={store.model.Project}
						property="name"
						value={this.record.project}
						onChange={this.onProjectChange}
						isRequired
					/>
				</div>
			}
			
			<div className="budget-details">
				<div className="budget-numbers">
					<div className="block">
						<div className="budget-number">
							<label>Сумма</label>
							<strong>{this.record.sum || '-'}</strong> {this.record.sum ? <em>{t('rub')}</em> : ''}
							<small>test</small>
						</div>
						<div className="budget-number">
							<label>Налог</label>
							<strong>{this.record.tax ? Math.round(this.record.tax * 100) : '-'}</strong> {this.record.tax ? <em>%</em> : ''}
							<small>test</small>
						</div>
					</div>
					<div className="block">
						<div className="budget-number grey">
							<label>Поступления</label>
							<strong>{this.record.paid || '-'}</strong> {this.record.paid ? <em>{t('rub')}</em> : ''}
							{lastIncoming && <small>+{lastIncoming.sum} {t('rub')}</small>}
						</div>
						<div className="budget-number grey">
							<label>Доля компании</label>
							<strong>
								{(this.record.sum && this.record.percentLeft) ? (this.record.percentLeft * this.record.sum).toFixed(0) : '-'}
							</strong>
							{this.record.sum && this.record.percentLeft ? <em> {t('rub')}</em> : ''}
							{this.record.percentLeft && <small>{Math.round(this.record.percentLeft * 100)} %</small>}
						</div>
					</div>
				</div>
				
				<div className="budget-comment">
					<div>Комментарий:</div>
					{this.editComment ?
						<>
							<textarea rows={3} value={this.record.comment || ''} onChange={this.onCommentChange} ref={this.autoHeight} autoFocus />
							<div><a href="#" onClick={this.submitComment}>{t('save')}</a></div>
						</>
						:
						<>
							{this.record.comment && <div className="comment-text" dangerouslySetInnerHTML={{ __html: this.record.comment.replace(/[\r\n]/g, '<br/ >')}} />}
							<div><a href="#" onClick={this.toggleComment}>{t(this.record.comment ? 'edit' : 'add')}</a></div>
						</>
					}
				</div>

				<div className="actions">
					<DeleteButton onConfirm={this.delete} />
				</div>
			
			</div>
			
			<div className="budget-relations">
				<Members budget={this.record} />
				<Payments type="outgoing" budget={this.record} />
				<Payments type="incoming" budget={this.record} />
			</div>
			
		</div>;
	}
	
}
