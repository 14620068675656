import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Popup } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class CategoryTree extends React.Component {

	@observable categories = [];
	@observable confirm = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.allCategories = await store.model.PriceCategory.find({
			where: this.props.exclude ? { id: { neq: this.props.exclude }} : undefined,
			order: 'name asc',
		});

		const rootCategories = this.allCategories.filter(cat => cat.parentId === null);
		rootCategories.forEach(this.getChildren);
		this.categories = rootCategories;
	};

	getChildren = category => {
		category.__children = [];
		this.allCategories.forEach(cat => {
			if (cat.parentId === category.id) {
				this.getChildren(cat);
				category.__children.push(cat);
			}
		});
	};

	renderChildren = record => {
		return record.__children.length > 0 ? <ul>{record.__children.map(this.renderCategory)}</ul> : null;
	};

	renderCategory = (record) => {
		return <li key={record.id}>
			<div className="name" onClick={() => this.onSelect(record.id)}>{record.name}</div>
			{this.renderChildren(record)}
		</li>;
	};

	onSelect = (id) => {
		this.selected = id;
		this.confirm = true;
	};

	onConfirm = () => {
		this.props.onSelect(this.selected);
		this.props.onClose(this.selected);
	};

	closeConfirm = () => {
		this.selected = null;
		this.confirm = false;
	};

	render() {
		return <Popup onClose={this.props.onClose} className="category-tree">
			<ul>
				{!this.props.disableRoot && <li key="root">
					<div className="name root" onClick={() => this.onSelect(null)}>{t('priceCategory.root')}</div>
				</li>}
				{this.categories.map(this.renderCategory)}
			</ul>
			{this.confirm && <Popup showCloseButton={false} onClose={this.closeConfirm} className="category-confirm" height={100}>
				<div className="mb-2">{t('priceCategory.confirmMove')}</div>
				<div>
					<Button onClick={this.onConfirm} variant="primary">{t('yes')}</Button>
					<Button onClick={this.closeConfirm}>{t('cancel')}</Button>
				</div>
			</Popup>}
		</Popup>;
	}

}
