import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faCheckCircle, faCalculator, faUserFriends, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Tabs, Tab, Select } from '@smartplatform/ui';
import { Mobile, Desktop } from 'components';
import IconTitle from '../../components/icon-title/IconTitle';
import Tasks from './Tasks';
import Budgets from './Budgets';
import BoardLists from './board-lists';
import Members from './members';
import Summary from './summary';
import Edit from './Edit';
import CreateTask from './CreateTask';

import pStore from './store';
import store from 'client/store';
import t from 'i18n';

const routes = [
	{
		title: <IconTitle faIcon={faCheckCircle} text={t('task.plural')} />,
		path: `/projects/:projectId`,
		exact: true,
		component: Tasks,
	},
	{
		title: <IconTitle faIcon={faCheck} text={t('task.boardList')} />,
		path: `/projects/:projectId/lists`,
		component: BoardLists,
	},
	{
		title: <IconTitle faIcon={faCalculator} text={t('budgets')} />,
		path: `/projects/:projectId/budgets`,
		component: Budgets,
		visible: () => !!store.model.Budget,
	},
	{
		title: <IconTitle faIcon={faUserFriends} text={t('project.members')} />,
		path: `/projects/:projectId/members`,
		component: Members,
	},
	{
		title: <IconTitle faIcon={faFolder} text={t('information')} />,
		path: `/projects/:projectId/edit`,
		component: Edit,
	},
];

@observer
export default class Project extends React.Component {
	@observable route = null;

	constructor(props) {
		super(props);
		store.ui.title = 'Проект';
		store.ui.kanban = true;
		routes.forEach((route) => {
			const match = matchPath(store.route.path, route);
			if (match) this.route = route;
		});
		document.title = t('project.title');
		this.init();
	}

	componentWillUnmount() {
		document.title = t('defaultTitle');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		await pStore.init(this.id);
		store.ui.title = pStore.project ? pStore.project.name || t('unnamed') : t('project.title');
		if (pStore.project) document.title = pStore.project.name;
	};

	onRouteChange = (route) => {
		this.route = route;
		store.route.push({ path: route.path.replace(/:projectId/, this.id) });
	};

	render() {
		if (pStore.error) return <div className='error'>{pStore.error.message}</div>;

		const _routes = routes.filter((route) => (route.visible ? route.visible() : true));

		return (
			<div className='project-root'>
				<Switch>
					<Route path={`/projects/${this.id}/createTask`}>
						<CreateTask projectId={this.id} />
					</Route>
					<Route>
						{pStore.project && <Summary project={pStore.project} key={pStore.project.id} />}
						<Desktop>
							<Tabs params={{ projectid: this.id }} className='project-nav'>
								{_routes.map((route, i) => (
									<Tab key={i} {...route} />
								))}
							</Tabs>
						</Desktop>
						{this.route && (
							<Mobile>
								<Select
									items={_routes.map((route) => ({ label: route.title, value: route }))}
									value={this.route}
									showValue={this.route.title}
									onChange={this.onRouteChange}
									className='route-filter'
									isRequired
									noSearch
								/>
								<Switch>
									{routes.map((route, i) => (
										<Route key={i} {...route} />
									))}
								</Switch>
							</Mobile>
						)}
					</Route>
				</Switch>
			</div>
		);
	}
}
