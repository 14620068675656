import React from 'react';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Switch, Route, matchPath } from 'react-router-dom';
import { Tabs, Tab, Select, Form, Loader } from '@smartplatform/ui';
import { Mobile, Desktop } from 'components';
import t from 'i18n';
import routes from './routes';

@observer
export default class extends React.Component {
	@observable route = null;
	@observable record = null;
	@observable load = true;
	constructor(props) {
		super(props);
		console.log(this.props);
		this.routes.forEach((route) => {
			const match = matchPath(store.route.path, route);
			if (match) this.route = route;
		});
		document.title = t('project.title');
		this.init();
	}
	get routes() {
		return routes(this.props.path, this.record);
	}
	componentWillUnmount() {
		document.title = t('defaultTitle');
	}

	init = async () => {
		this.load = true;
		this.id = parseInt(this.props.match.params.id);
		this.record = await store.model[this.props.modelName].findById(this.id, {
			include: 'type',
		});
		// store.ui.title = this.record && this.record.name;
		this.load = false;
	};
	onRouteChange = (route) => {
		this.route = route;
		store.route.push({ path: route.path.replace(/:contragentId/, this.id) });
	};

	render() {
		const _routes = this.routes.filter((route) => (route.visible ? route.visible() : true));
		if (this.load) return <Loader />;
		if (!this.record) return <>Клиент не найден</>;
		return (
			<>
				<h2 className='mt-2 mb-4'>{t('contragents.card.title')}</h2>
				<Desktop>
					<Tabs params={{ contragentId: this.id }}>
						{_routes.map((route, i) => (
							<Tab key={i} {...route} component={route.component} />
						))}
					</Tabs>
				</Desktop>
				<Mobile>
					<Select
						items={_routes.map((route) => ({ label: route.title, value: route }))}
						value={this.route}
						showValue={this.route.title}
						onChange={this.onRouteChange}
						className='route-filter'
						isRequired
						noSearch
					/>
					<Switch>
						{this.routes.map((route, i) => (
							<Route key={i} {...route} />
						))}
					</Switch>
				</Mobile>
			</>
		);
	}
}
