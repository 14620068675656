import React from 'react';
import { Checkbox, Field as FormField, Form } from '@smartplatform/ui';
import FormContext from '@smartplatform/ui/src/components/form/context';
import { FIELDS, Suggestions } from 'components/contragents';
import { default as translate } from 'i18n';
import { observer } from 'mobx-react';
import store from 'client/store';

const t = (slug) => translate(`contragents.card.${slug}`);

@observer
export default class Info extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
	}
	back = () => store.route.push({ path: this.props.path });
	render() {
		const formProps = {
			className: 'contragent-form',
			record: this.props.contragent,
			onDelete: this.back,
			onSave: this.back,
			stay: true,
			noSave: false,
			noDelete: false,
		};
		return (
			<Form {...formProps}>
				<div className='row'>
					<div className='col-4'>
						<FormField relation='type' property='fullName' label='Тип контрагента' />
					</div>
					<div className='col-4'>
						<FormField property='myCompany' label={translate('contragent.myCompany')}>
							<Checkbox />
						</FormField>
					</div>
				</div>
				<hr />
				<Row className='row'>
					<Row className='col-4'>
						<h3 className='mb-3 mt-2'>{t('basicInfo')}</h3>
						<Row>
							<Field property={FIELDS.NAME} label={t('name')}>
								<Suggestions />
							</Field>
						</Row>
						<Row>
							<Field property={FIELDS.INN} label={t('inn')} />
						</Row>
						<Row>
							<Field property={FIELDS.KPP} label={t('kpp')} />
						</Row>
						<Row>
							<Field property={FIELDS.LEGAL_ADDRESS} label={t('legalAddress')} />
						</Row>
						<Row>
							<Field property='actualAddress' label={t('actualAddress')} />
						</Row>
						<Row>
							<Field property='phone' label={t('phone')} />
							<Field property='email' label={t('email')} />
						</Row>
						<Row>
							<Field property='comment' label={t('comments')}>
								<textarea rows={5} />
							</Field>
						</Row>
					</Row>
					<div className='col-4'>
						<h3 className='mb-3 mt-2'>{t('additionalInfo')}</h3>
						<Row>
							<Field property={FIELDS.OKATO} label={t('okato')} />
						</Row>
						<Row>
							<Field property={FIELDS.OKTMO} label={t('oktmo')} />
						</Row>
						<Row>
							<Field property={FIELDS.OKVED} label={t('okved')} />
						</Row>
						<Row>
							<Field property={FIELDS.OGRN} label={t('ogrn')} />
						</Row>
						<Row>
							<Field property={FIELDS.OKPO} label={t('okpo')} />
						</Row>
						<h3 className='mb-3 mt-2'>Данные о руководителе</h3>
						<Row>
							<Field property={FIELDS.OWNER_NAME} label={t('personName')} />
						</Row>
						<Row>
							<Field property={FIELDS.OWNER_ROLE} label={t('personRole')} />
						</Row>
					</div>
				</Row>
			</Form>
		);
	}
}
const Row = (props) => <div className='row' {...props} />;
const Col = (props) => <div className='col' {...props} />;
@observer
class Field extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
	}
	render() {
		const { property } = this.props;
		const hidden = property && this.context?.record?.type?.hiddenFields?.includes(property);
		if (hidden) return <></>;
		return (
			<Col>
				<FormField {...this.props} />
			</Col>
		);
	}
}
