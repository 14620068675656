import React from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import t from 'i18n';
import './style.scss';
import { Toolbar, Mode } from 'components';
import FilterPopup from './filter-popup/FilterPopup';

@inject('store')
@observer
export default class DocumentsToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	onSearch = (e) => {
		this.store.search.name = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		if (this.store.reload) this.store.reload();
	};

	render() {
		const { mode, onChange, count, search } = this.props.store;
		const filterContent = <FilterPopup store={this.store} />;

		return (
			<div className='documents-toolbar'>
				<Toolbar store={this.store}>
					<Toolbar.CreateBtn url='/documents/create' innerText={t('document.new')} />
					<Toolbar.Search content={filterContent} inputValue={search.name} onSearch={this.onSearch} />
					<Toolbar.Count text={t('document.total')} count={count} />
					<Toolbar.ExportBtn />
				</Toolbar>
				<Mode mode={mode} onChange={onChange('mode')}>
					<Mode.List />
					<Mode.Kanban />
				</Mode>
			</div>
		);
	}
}

//TODO сделать экспорт документов

