import React from 'react';
import store from 'client/store';
import { Column, Table } from "@smartplatform/ui";

export class NomenclatureReserves extends React.Component {
	constructor(props) {
		super(props);
	}
	renderMeasure = ({ record: balance }) => balance?.nomenclature?.measure;
	render() {
		const tableProps = {
			className: 'nomenclature-balance',
			model: store.model.NomenclatureBalance,
			query: {
				where: {
					debitId: 4
				},
				include: [ 'debit', 'credit', 'nomenclature' ]
			},
			noRecordsMsg: 'Операции резервирования не найдены'
		}
		return (
			<Table {...tableProps}>
				<Column property='updatedAt' label='Дата' width={90} />
				<Column relation='nomenclature'  property='name' label='Позиция номенклатуры' width={500} />
				<Column property='comment' label='Основание/комментарий'  />
				<Column relation='debit'  property='name' label='Куда' width={200} />
				<Column relation='credit' property='name'  label='Откуда' width={200}  />
				<Column property='quantity'  label='Кол-во' width={80}  />
				<Column label='Ед. измерения' width={100} >{this.renderMeasure}</Column>
			</Table>
		);
	}
}