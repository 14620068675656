import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ClockIcon from '!!svg-react-loader!client/img/icons/clock.svg';
import CommentIcon from '!!svg-react-loader!client/img/icons/comment.svg';
import AttachmentIcon from '!!svg-react-loader!client/img/attach.svg';
import { BoxLabel, UserAvatar } from 'client/components';
import { relative } from 'client/tools';
import './style.scss';
import classNames from 'classnames';

@observer
export default class TaskCard extends React.Component {
	get dueDateClassName() {
		const dueDate = new Date(this.props.task.dueDate);
		const currentDate = new Date();
		if (dueDate < currentDate) return 'expired';
		if (dueDate - currentDate < 86400000) return 'expiring-today';

		return '';
	}
	render() {
		const { task } = this.props;
		const dueDateClassName = classNames('dueDate', this.dueDateClassName);

		return (
			<>
				<div className='task-content'>
					<div className='top'>
						<div className={'id' + (task.closed ? ' closed' : '')}>
							#{task.id} {task.closed && <FontAwesomeIcon icon={faCheck} />}
						</div>
						<div className='right'>
							{task.dueDate ? (
								<div className={dueDateClassName}>
									<ClockIcon /> <span className='date'>{relative(task.dueDate)}</span>
								</div>
							) : (
								<span className='date'>{relative(task.updatedAt)}</span>
							)}
						</div>
					</div>

					<div className='name' dangerouslySetInnerHTML={{ __html: task.name.replace(/[\r\n]+/g, '<br />') }} />
					{task.labels().length > 0 && (
						<div className='labels'>
							{task.labels().map((label) => (
								<BoxLabel key={label.id} label={label} style={{ marginRight: 5, marginBottom: 5 }} />
							))}
						</div>
					)}
					<div className='bottom'>
						<div className='left'>
							{task.commentsCount > 0 ? (
								<span className='comments-count'>
									<CommentIcon /> {task.commentsCount}
								</span>
							) : null}
							{task.attachmentsCount > 0 ? (
								<span className='attachments-count'>
									<AttachmentIcon /> {task.attachmentsCount}
								</span>
							) : null}
						</div>
						{task.user && (
							<div className='user evented'>
								<UserAvatar user={task.user} size={28} />
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

