import { Column, Loader, NumberValue, Pager, Table } from '@smartplatform/ui';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

const PER_PAGE = 10;

@inject('store') @observer
export default class ProcessPosition extends React.Component {
	@observable isInitialized = false;
	@observable page = 1;
	@observable positions = [];

	constructor(props) {
		super(props);
		this.order = this.props.store.order;
		this.init();

	}

	init = async () => {
		this.positions = await this.order.positions.find({
			order: 'id asc',
		});
		this.isInitialized = true;
	};

	onPageChange = page => {
		this.page = page;
		this.init();
	};

	render() {
		const { onSelect } = this.props;
		if (!this.isInitialized) return <div className="loading-popover"><Loader size={20} /></div>;
		return <div className="positions-list">
			{this.positions.totalCount > PER_PAGE && <Pager current={this.page} onChange={this.onPageChange} itemsPerPage={PER_PAGE} totalCount={this.positions.totalCount} />}
			<Table multiline rows={this.positions} onRowClick={this.props.onSelect}>
				<Column label={t('number')} width={50}>{data => data.row + 1}</Column>
				<Column relation="category" property="name" label={t('orderPosition.category')} />
				<Column width={80} property="amount" label={t('orderPosition.amount')} className="text-right" />
				<Column width={80} property="unitPrice" label={t('orderPosition.unitPrice')} className="text-right"><NumberValue type="currency" /></Column>
				<Column width={100} property="price" label={t('orderPosition.price')} className="text-right"><NumberValue type="currency" /></Column>
			</Table>
		</div>;
	}
};
