import React from 'react';
import { RecordSelect, Select, DatePicker, GroupSelect } from '@smartplatform/ui';
import { PERIODS } from '../constants';
import { inject, observer } from 'mobx-react';
import { CleanButton } from 'components';
import { renderBankAccount } from 'client/tools/renders';
import appStore from 'client/store';
import t from 'i18n';

@inject('store')
@observer
export class Filters extends React.Component {
	render() {
		const { store } = this.props;
		const {
			onChange,
			contragents,
			bankAccounts,
			cleanFilters,
			cashFlows,
			// planFactGroup,
			period,
			onPeriodChange,
			projects,
			myCompanies,
		} = store;

		const showMultiSelectValue = (prop) => {
			const propCapitalName = prop.charAt(0).toUpperCase() + prop.slice(1);

			if (store[prop].length) {
				return t('payment.selected' + propCapitalName) + ': ' + store[prop].length;
			}
			return <span className='placeholder'>{t('payment.all' + propCapitalName)}</span>;
		};
		const selectProps = { noTotal: true, maxItems: 10, itemsPerPage: 1000, width: 220 };
		return (
			<div className='filters'>
				<div className='top'>
					<Select
						key={period}
						items={PERIODS.map((name, i) => ({ value: name, label: t('datePeriod.' + name) }))}
						value={period}
						onChange={onPeriodChange}
						showValue={period ? t('datePeriod.' + period) : t('datePeriod.select')}
						noSearch
						isRequired
						width={220}
					/>
					{period === 'specified' &&
						['startDate', 'endDate'].map((date) => <DatePicker key={date} value={store[date]} onChange={onChange(date)} />)}

					<GroupSelect
						groups={myCompanies.map((item) => ({ title: item.name, items: item.bankAccounts() }))}
						selected={bankAccounts}
						onChange={onChange('bankAccounts')}
						computed={renderBankAccount}
						showValue={showMultiSelectValue('bankAccounts')}
						width={200}
						comparator={(a, b) => a.id === b.id}
					/>
					<RecordSelect
						model={appStore.model.Contragent}
						showValue={showMultiSelectValue('contragents')}
						property='name'
						onChange={onChange('contragents')}
						filter={{ order: 'name asc', where: { or: [{ myCompany: false }, { myCompany: null }] } }}
						value={contragents}
						width={220}
					/>
					<RecordSelect
						model={appStore.model.Project}
						showValue={showMultiSelectValue('projects')}
						property='name'
						onChange={onChange('projects')}
						value={projects}
						width={220}
					/>
					<RecordSelect
						{...selectProps}
						model={appStore.model.CashFlow}
						property='name'
						onChange={onChange('cashFlows')}
						filter={{ order: 'name asc' }}
						value={cashFlows}
						showValue={showMultiSelectValue('cashFlows')}
					/>
					<RecordSelect
						{...selectProps}
						model={appStore.model.PaymentDirection}
						property='name'
						onChange={onChange('directions')}
						value={cashFlows}
						showValue={showMultiSelectValue('directions')}
					/>
					<CleanButton onClick={cleanFilters} />
				</div>
				<div className='bottom'>
					{/* <ButtonGroup>
						{[PLAN, FACT, null].map((name, index) => (
							<Button
								variant={planFactGroup === name ? 'primary' : 'default'}
								key={index}
								onClick={() => (store.planFactGroup = name)}
								disabled
							>
								{t('payment.' + (name?.toLocaleLowerCase() || 'all'))}
							</Button>
						))}
					</ButtonGroup> 
				 */}
				</div>
			</div>
		);
	}
}

