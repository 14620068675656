import React from 'react';
import store from 'client/store';
import { Column, Table } from '@smartplatform/ui';
import t from 'i18n';

export default class Balance extends React.Component {
	constructor(props) {
		super(props);
	}
	renderMeasure = () => this.props?.nomenclature?.measure;
	render() {
		const tableProps = {
			className: 'nomenclature-balance',
			rows: this.props?.nomenclature?.balance,
			model: store.model.NomenclatureBalance,
			query: {
				where: {
					nomenclatureId: this.props?.nomenclature?.id,
				},
				include: ['debit', 'credit'],
				order: 'date desc'
			},
			noRecordsMsg: 'Операции с товаром не найдены',
		};
		return (
			<Table {...tableProps}>
				<Column property='date' label={t('date')} />
				<Column property='number' label={t('number')} />
				<Column relation='debit' property='name' label={t('nomenclature.from')} width={200} />
				<Column relation='credit' property='name' label={t('nomenclature.to')} width={200} />
				<Column property='quantity' label={t('nomenclature.quantity')} width={80} />
				<Column label={t('nomenclature.measure')} width={100}>
					{this.renderMeasure}
				</Column>
			</Table>
		);
	}
}
