import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import store from 'client/store';
import { observable } from 'mobx';

@observer
export default class MenuItem extends React.Component {
	@observable badgeCount;

	isActive = (match) => {
		if (match) {
			this.props.changeVisibility(true);
		}
		return match;
	};

	componentDidMount() {
		if (this.props.badgeFunc) {
			this.update();
		}
	}

	update = async () => {
		this.badgeCount = await this.props.badgeFunc(store);
		this.timerId = setTimeout(() => {
			this.update();
		}, 5000);
	};

	componentWillUnmount() {
		clearTimeout(this.timerId);
	}

	render() {
		const { item } = this.props;
		const { label, path, icon, reactIcon } = item;
		if (item.enabled && !item.enabled(store)) return null;

		const className = classNames('item');
		let renderIcon = <span className='no-icon' />;
		if (icon) renderIcon = <FontAwesomeIcon icon={icon} />;
		if (reactIcon) renderIcon = reactIcon;

		const tasks = this.badgeCount ? '-tasks' : '';
		return (
			<NavLink to={path} className={className} activeClassName='active' isActive={this.isActive}>
				<span className='icon'>{renderIcon}</span>
				<div className={'title' + tasks}>
					{label}
					{(this.badgeCount && <span className='count'>{this.badgeCount}</span>) || null}
				</div>
			</NavLink>
		);
	}
}
