import React from 'react';
import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';

import t from 'i18n';
export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return (
		<ModelEdit model={store.model[modelName]} path={path} id={id} title={t('orderPositionRole.title')}>
			<Field property='name' label={t('name')} />
		</ModelEdit>
	);
};
