import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Checkbox, RecordSelect } from '@smartplatform/ui';
import { fio } from 'client/tools';
import Contact from './Contact';
import NewContact from './NewContact';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const NEW_CONTACT = 'NEW_CONTACT';

@observer
export default class Contacts extends React.Component {
	@observable available = [];
	@observable contacts = [];
	@observable newContact = null;
	@observable newContragent = false;
	@observable onlyAvailable = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.available = await this.props.order.contragent.staff.find({
			fields: ['id'],
			order: 'id asc',
		});

		this.contacts = await this.props.order.contacts.find({
			fields: ['id', 'personName', 'personRole', 'phone', 'email'],
			order: 'id asc',
		});
	};

	onNewContactChange = (contragent) => {
		if (contragent === NEW_CONTACT) {
			console.log(NEW_CONTACT);
			this.newContragent = true;
		} else {
			this.newContact = contragent;
		}
	};

	onClose = async (updated) => {
		this.newContragent = false;
		if (updated) await this.init();
	};

	deleteContact = async (contragent) => {
		await this.props.order.contacts.remove(contragent.id);
		await this.init();
	};

	addContact = async () => {
		// добавление контакта к контрагенту,если его нет в списке
		// const existsInClient = this.available.find(contact => contact.id === this.newContact.id)
		// if (!existsInClient) await this.props.order.client.contacts.add(this.newContact.id)
		await this.props.order.contacts.add(this.newContact.id);
		this.newContact = null;
		await this.init();
	};

	toggleOnlyAvailable = (value) => {
		this.newContact = null;
		this.onlyAvailable = value;
	};

	renderContragent = (contragent) => {
		if (contragent.personName) {
			return `${contragent.personName} ${contragent.email ? `(${contragent.email})` : ''}`;
		}
		if (contragent.email) {
			return contragent.email;
		}
		return <>id: {contragent.id}</>;
	};

	get filter() {
		const filter = { where: { and: [{ contragentId: { neq: null } }] }, order: 'personName asc' };
		if (this.contacts.length > 0) filter.where.and.push({ id: { nin: this.contacts.map((c) => c.id) } });
		if (this.onlyAvailable) {
			if (this.available.length) filter.where.and.push({ id: { inq: this.available.map((c) => c.id) } });
			else filter.where = { id: -1 };
		}
		return filter;
	}
	render() {
		const appendItems = store.model.Contragent.INFO.WRITE
			? [{ label: t('contact.new'), value: NEW_CONTACT, className: 'new-user' }]
			: undefined;

		return (
			<div className='order-contacts'>
				<div className='form-field'>
					<label>{t('client.contacts')}</label>
					{this.contacts.map((contragent) => {
						return (
							<Contact
								key={contragent.id}
								contragent={contragent}
								noDefaultEmail
								onRemove={this.deleteContact}
								confirmMessage={t('order.removeContact')}
							/>
						);
					})}
				</div>

				<div className='new-contact'>
					<Checkbox onChange={this.toggleOnlyAvailable} checked={this.onlyAvailable} label={t('client.onlyAvailable')} />
					<RecordSelect
						model={store.model.Contragent}
						// property="personName"
						computed={this.renderContragent}
						value={this.newContact}
						onChange={this.onNewContactChange}
						searchPlaceholder={t('contact.searchHint')}
						itemsPerPage={8}
						className='with-new-record'
						filter={this.filter}
						emptyContent={t('client.noContacts')}
						appendItems={appendItems}
						isRequired
					/>

					<Button onClick={this.addContact} variant='primary' disabled={!this.newContact}>
						{t('client.addContact')}
					</Button>
				</div>
				{this.newContragent && <NewContact onClose={this.onClose} order={this.props.order} />}
			</div>
		);
	}
}
