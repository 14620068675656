import React from 'react';
import store from 'client/store';
import { DEFAULT_PRIORITY } from '../kanban/OrdersKanban';
import t from 'i18n';
import { Button, Loader } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class CopyOrder extends React.Component {
    @observable isLoading = false;

    constructor(props) {
        super(props);
        this.store = props.store;
    }

    copyOrder = async () => {
        this.isLoading = true;
        //* копируем в новый заказ контрагента и состав текущего
        const { order } = this.props;
        //* еще раз зафетчим заказ, чтобы не сбивались кэш-данные реляций
        const currentOrder = await store.model.Order.findById(order.id, {
            include: [
                { relation: 'contacts' },
                { relation: 'positions' },
				{ relation: 'attachments' },
            ],
        });

        //* получаем дефолтный лист и приоритет для нового заказа
        let defaultList;
        let maxPriority;
        try {
            defaultList = await store.model.OrderList.find({
                where: { default: true },
                include: { relation: 'orders', fields: ['priority'], order: 'priority desc', limit: 1 },
                limit: 1,
            });
            defaultList = defaultList.length > 0 ? defaultList[0] : null;
            maxPriority = DEFAULT_PRIORITY;
            if (defaultList) {
                const orders = defaultList.orders();
                const maxPriorityOrder = orders.length > 0 ? orders[0] : null;
                if (maxPriorityOrder) {
                    maxPriority = maxPriorityOrder.priority * 2;
                }
            } else {
                store.ui.setNotification({ message: t('order.defaultListError'), error: true });
                return;
            }
        } catch (e) {
            console.error('fetching default order list error: ', e);
        }

        //* создаем копию заказа
        let copy;
        try {
            copy = new store.model.Order();
            copy.priority = maxPriority;
            copy.description = currentOrder.description;
            copy.listId = defaultList ? defaultList.id : 1;
            copy.client = currentOrder.client;
			copy.contragentId = currentOrder.contragentId;
            await copy.save();
        } catch (e) {
            console.error('Saving copy order error:', e);
        }
		const promises = [];

		const contacts = currentOrder.contacts();
		for (let contact of contacts) {
			promises.push(copy.contacts.add(contact.id));
		}

		const attachments = currentOrder.attachments();
		for (let attachment of attachments) {
			promises.push(copy.attachments.add(attachment.id));
		}

		const positions = currentOrder.positions();
		for (let position of positions) {
			promises.push(position.copy({ orderId: copy.id }))
		}
		
		Promise.all(promises);

        this.isLoading = false;
        store.route.push({ path: `/orders/${copy.id}` });
    };

    render() {
        return (
            <Button onClick={this.copyOrder} variant="primary" size="sm">
                {t('order.copy')} {this.isLoading && <Loader size={12} />}
            </Button>
        );
    }
}
