import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import {
	Form,
	Field,
	Select, RecordSelect,
} from '@smartplatform/ui';
import store from 'client/store';
import { fio } from 'client/tools';
import t from 'i18n';
import data from './data';

@observer
class RoleMappingEdit extends React.Component {

	@observable record = null;
	@observable users = null;
	@observable user = null;
	@observable isLoading = false;

	constructor (props) {
		super(props);
		store.ui.title = t('roleMapping.title');
		this.init();
	}

	@action init = async () => {
		this.isLoading = true;

		if (this.props.match.params.id) {
			this.record = await store.model.RoleMapping.findById(this.props.match.params.id, {
				include: ['role'],
			});
		} else {
			this.record = new store.model.RoleMapping();
		}

		this.users = await store.model.User.find();
		this.isLoading = false;
	};

	back = () => {
		store.route.push({ path: `/rolemappings` });
	};

	render () {
		if (this.isLoading) return '...';

		return <div className="fixed-page">
			<Form record={this.record} stay onDelete={this.back} onSave={this.back} beforeSave={() => this.record.principalId = this.user.id}>
				<Field property="principalType" label={t('acl.principalType')}>
					<Select items={data.PRINCIPAL_TYPE.map(pt => ({ value: pt.id, label: pt.name }))} />
				</Field>

				{this.record.principalType === 'USER' && this.users && (
					<div className='form-field'>
						<label>{t('user.title')}</label>
						<RecordSelect
							model={store.model.User}
							value={this.user}
							onChange={record => this.user = record}
							computed={user => `${fio(user)} (${user.email})`}
							itemsPerPage={5}
						/>
					</div>
				)}

				<Field relation="role" property="name" label={t('role.title')} />
			</Form>
		</div>;
	}
}

export default RoleMappingEdit;
