import React from 'react';
import { observer } from 'mobx-react';
import { Labels } from 'components';
import { Avatar } from '@smartplatform/ui';

@observer
export default class DocumentCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { doc } = this.props;

		return (
			<>
				<div className='document-card-top'>
					<div className='name'>{doc.name}</div>
					{doc.labels().length > 0 && (
						<div className='document-card-bottom evented'>
							<div className='labels'>
								{doc.labels().length > 0 && <Labels record={doc} labels={doc.labels()} size={8} />}
							</div>
						</div>
					)}
				</div>
				<div className='document-card-main'>
					<div>
						<div className='contragent-name'>{doc.contragent().length> 0 ? doc.contragent().map((agent) => agent.name).join(', ') : '-'}</div>
						<div className='id'>#{doc.id}</div>
					</div>
					{doc.user && <Avatar user={doc.user} size={18} />}
				</div>
				<div className='document-card-bottom'>{doc.typeName ?? '-'}</div>
			</>
		);
	}
}
