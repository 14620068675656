import React from 'react';
import PropTypes from 'prop-types';

export default class Tab extends React.Component {

	static propTypes = {
		path: PropTypes.string.isRequired,
		title: PropTypes.any.isRequired,
		exact: PropTypes.bool,
		isItem: PropTypes.bool,
		badge: PropTypes.any,
	};
	
	static defaultProps = {
		exact: false,
		isItem: true,
	};
	
	render() {
		return null;
	}
	
}
