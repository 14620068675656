import React from 'react';

import { Tabs, Tab } from '@smartplatform/ui';
import Features from './Features';
import PricePositions from './PricePositions';
import Edit from './Edit';
import './style.scss';
import t from 'i18n';
import store from 'client/store';
import { checkModelAccess } from 'client/tools/checkModelAccess';

export default class PriceCategory extends React.Component {
	constructor(props) {
		super(props);
		this.error = checkModelAccess(this.props.modelName);
	}

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		const { match } = this.props;
		const id = parseInt(match.params.id);

		return (
			<div className='price-category-edit'>
				<Edit {...this.props} />
				{id ? (
					<Tabs params={{ id }}>
						<Tab path='/pricecategories/:id' exact title={t('priceCategory.positions')}>
							<PricePositions {...this.props} />
						</Tab>
						<Tab path='/pricecategories/:id/features' exact title={t('priceCategory.features')}>
							<Features {...this.props} />
						</Tab>
					</Tabs>
				) : null}
			</div>
		);
	}
}
