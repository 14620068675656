import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CreateButton from '../buttons/CreateButton';
import store from 'client/store';
import './style.scss';

@observer
export class ToolbarCreateBtn extends React.Component {
	static propTypes = {
		url: PropTypes.string,
	};

	create = () => store.route.push({ path: this.props.url });

	render() {
		return (
			<CreateButton
				text={this.props.innerText}
				onClick={this.props.onClick}
				url={this.props.url}
				className={this.props.className}
				type='create'
			/>
		);
	}
}

