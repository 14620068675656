import React from 'react';

import { Row, Field, NumberInput } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match, title }) => {
	
	const id = parseInt(match.params.roleId);
	const budgetId = parseInt(match.params.budgetId);
	
	return <>
		<ModelEdit model={store.model[modelName]} path={path} id={id} title={title}>
			<Row>
				<Field relation="person" computed={fio} property='lastName' label={t('person.title')}/>
				<Field relation="budget" property="name" label={t('budget.title')} disabled={!!budgetId}/>
				<Field relation="role" property="name" label={t('budgetRole.role')}/>
			</Row>
			<Row>
				<Field property="sum" label={t('sum')}><NumberInput type="currency" /></Field>
				<Field property="percent" label={t('percent')}><NumberInput type="percent" /></Field>
				<Field property="price" label={t('price')}><NumberInput type="currency" /></Field>
			</Row>
			<Row>
				<Field property="paid" label={t('budgetRole.paid')}><NumberInput type="currency" /></Field>
				<Field property="agent" label={t('agent')}/>
				<div/>
			</Row>
			<Field property='comment' label={t('comment.title')}><textarea rows={5}/></Field>
		</ModelEdit>
	</>;
};
