import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Suggestions } from "components/contragents";
import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';


@observer
export default class Create extends React.Component {
	@observable record = null;
	render () {
		const { modelName, path } = this.props;
		return <div className="fixed-page">
			<ModelEdit model={store.model[modelName]} stay path={path} title={t('client.title')}>
				<div className='row'>
					<div className='col-5'>
						<Field relation='type' property='fullName' label='Тип контрагента' />
					</div>
					<div className='col-7'>
						<Field property="name" label={t('client.title')}>
							<Suggestions onSelect={this.handleSelectSuggestions} />
						</Field>
					</div>
				</div>
			</ModelEdit>
		</div>;
	}
};