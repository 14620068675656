import React, { useContext } from 'react';
import List from '!!svg-react-loader!client/img/mode-list.svg';
import Kanban from '!!svg-react-loader!client/img/mode-kanban.svg';
import Tiles from '!!svg-react-loader!client/img/mode-tiles.svg';
import './style.scss';
import { MODES } from 'client/constants';

const ModeContext = React.createContext({});

export const Mode = ({ mode, onChange, children }) => {
	return (
		<ModeContext.Provider value={{ mode, onChange }}>
			<div className='modes'>{children}</div>
		</ModeContext.Provider>
	);
};

const makeModeIcon = (Component) => () => {
	const { mode, onChange } = useContext(ModeContext);

	const componentModeName = Component.displayName;
	const onClick = () => onChange(componentModeName);
	return <Component className={mode === componentModeName ? 'active' : ''} onClick={onClick} />;
};

List.displayName = MODES.LIST;
Kanban.displayName = MODES.KANBAN;
Tiles.displayName = MODES.TILES;

Mode.List = makeModeIcon(List);
Mode.Kanban = makeModeIcon(Kanban);
Mode.Tile = makeModeIcon(Tiles);

