import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { SuggestInput } from "@smartplatform/ui";
import {
	DADATA_API_KEY,
	DADATA_API_URL
} from "components/contragents/constants";
import FormContext from "@smartplatform/ui/src/components/form/context";
import { AUTOCOMPLETE } from "./constants";

@observer
export class Suggestions extends React.Component {
	static  contextType = FormContext;
	static propTypes = {
		search: PropTypes.oneOf([ 'party', 'bank' ])
	}
	static defaultProps = {
		search: 'party'
	}
	@observable suggestions = []
	constructor(props) {
		super(props);
		this.onSelect = this.onSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.search = debounce(this.search.bind(this), 500);
	}
	handleChange(val) {
		const { onChange } = this.props;
		onChange && onChange(val);
		this.search(val);
	}
	onSelect(suggestion) {
		if(!suggestion || !this.context?.record) return;
		Object.keys(AUTOCOMPLETE).forEach(property => {
			this.context.record[property] = AUTOCOMPLETE[property](suggestion)
		})
	}
	async search(query) {
		const response = await fetch(`${DADATA_API_URL}${this.props.search}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": "Token " + DADATA_API_KEY
			},
			body: JSON.stringify({ query })
		});
		const { suggestions = [] } = await response.json();
		this.suggestions = suggestions.map(({ data }) => data);
	}
	render() {
		const { value } = this.props;
		return(
			<SuggestInput
				value={value}
				suggestions={this.suggestions}
				onSuggestionClick={this.onSelect}
				onChange={this.handleChange}
				renderSuggestion={suggestion => (
					<div className='suggestion'>
						<div className='name-with-opf'>
							{suggestion.name.short_with_opf || suggestion.name.payment}
						</div>
						<div className='suggestion-footer'>
							<div>
								ИНН: {suggestion.inn} <br />
								{suggestion.address.value}
							</div>

						</div>
					</div>
				)}
			/>
		);
	}
}