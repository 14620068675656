import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getChartOptions, getPieOptions } from './options';
import { inject, observer } from 'mobx-react';
import { COLORS, COSTS, INCOME } from '../constants';
import t from 'i18n';

const _Charts = ({ store }) => {
	const { paymentTypeTotal, paymentType, chartRecords, isLoading } = store;
	if (isLoading) return <div className='charts-loading' />;

	const getTitle = (title) => t('payment.' + title.toLowerCase());
	const chartOptions = getChartOptions(chartRecords);
	const incomePie = getPieOptions(paymentTypeTotal[INCOME], { color: COLORS.GREEN, title: getTitle(INCOME) });
	const costsPie = getPieOptions(paymentTypeTotal[COSTS], { color: COLORS.RED, title: getTitle(COSTS) });
	// const isPieAvailable = (title) => [null, title].includes(paymentType) && paymentTypeTotal[title];

	return (
		<div className='charts'>
			<div className='chart'>
				<ReactECharts option={chartOptions} />
			</div>
			<div className='pie'>
				<ReactECharts option={incomePie} />
			</div>
			<div className='pie'>
				<ReactECharts option={costsPie} />
			</div>
		</div>
	);
};
export const Charts = inject('store')(observer(_Charts));

