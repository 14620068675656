export const DADATA_API_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/";
export const DADATA_API_KEY = '842709bce84734ee40403e8ce6ef9bb80e722fa5';
export const FIELDS = {
	INN: 'inn',
	NAME: 'name',
	KPP: 'kpp',
	OKATO: 'okato',
	OKTMO: 'oktmo',
	OKVED: 'okved',
	OGRN: 'ogrn',
	OKPO: 'okpo',
	LEGAL_ADDRESS: 'legalAddress',
	OWNER_NAME: 'personName',
	OWNER_ROLE: 'personRole',
	BANK: 'bank',
	BIC: 'bic',
	SWIFT: 'swift',
	ADDRESS: 'address',
	CORRESPONDENT_ACCOUNT: 'correspondentAccount'
}
export const AUTOCOMPLETE = {
	[FIELDS.NAME]: sug => sug?.name?.short_with_opf,
	[FIELDS.INN]: sug => sug?.inn,
	[FIELDS.KPP]: sug => sug?.kpp,
	[FIELDS.OKATO]: sug => sug?.okato,
	[FIELDS.OKTMO]: sug => sug?.oktmo,
	[FIELDS.OKVED]: sug => sug?.okved,
	[FIELDS.OGRN]: sug => sug?.ogrn,
	[FIELDS.OKPO]: sug => sug?.okpo,
	[FIELDS.LEGAL_ADDRESS]: sug => sug?.address?.value,
	[FIELDS.OWNER_NAME]: sug => sug?.management?.name,
	[FIELDS.OWNER_ROLE]: sug => sug?.management?.post,
	[FIELDS.BANK]: sug => sug?.name?.payment,
	[FIELDS.BIC]: sug => sug?.bic,
	[FIELDS.ADDRESS]: sug => sug?.address?.value,
	[FIELDS.SWIFT]: sug => sug?.swift,
	[FIELDS.CORRESPONDENT_ACCOUNT]: sug => sug?.correspondent_account
}