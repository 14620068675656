import React from 'react';
import { ColorUtils } from '@smartplatform/ui';

export const BoxLabel = ({ label, style }) => {
	if (!label) return null;
	const { r, g, b } = ColorUtils.hexToRgb(label.color);
	return (
		<div className='box-label' style={{ color: label.color, backgroundColor: `rgba(${r},${g},${b},0.2)`, ...style }}>
			{label.name}
		</div>
	);
};

