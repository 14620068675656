import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import FeatureValue from './FeatureValue';
import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import { AddButton } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FeatureValues extends React.Component {

	@observable featureValues = [];
	@observable isAddingValue = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { record } = this.props;
		this.featureValues = await record.featureValues.find({ order: 'id asc' });
	};

	endAdding = () => {
		this.isAddingValue = false;
		console.log('endAdding', this.isAddingValue);
		this.init();
	};

	addValue = () => {
		this.isAddingValue = true;
		const featureValue = new store.model.FeatureValue();
		featureValue.featureId = this.props.record.id;
		featureValue.name = '';
		this.featureValues.push(featureValue);
	};

	render() {
		return <div className="feature-values">
			<div className="table-list">
				<table>
					<thead>
					<tr>
						<th>№</th>
						<th>{t('name')}</th>
						<th/>
						<th/>
					</tr>
					</thead>
					<tbody>
					{this.featureValues.map((featureValue, i) => (
						<FeatureValue
							index={i}
							key={featureValue.id || 'new'}
							featureValue={featureValue}
							onDelete={this.init}
							onCreate={this.endAdding}
							onCancel={this.endAdding}
						/>
					))}
					</tbody>
				</table>
				<AddButton onClick={this.addValue} disabled={this.isAddingValue} />
			</div>
		</div>;
	}

}
