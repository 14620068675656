import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup, FormPlain, Row, Field, NumberInput } from '@smartplatform/ui';
import { User } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class PaymentEdit extends React.Component {
	
	@observable personFilter = {};
	
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const roles = await store.model.BudgetRole.find({
			where: { budgetId: this.props.budget.id },
			fields: ['id', 'personId'],
		});
		if (roles.length > 0) this.personFilter = {
			where: {
				id: { inq: roles.map(role => role.personId) },
			}
		}
	};
	
	renderPerson  = person => <User user={person} size={20} />;
	
	render() {
		const { record, budget, type, onClose } = this.props;
		
		const update = !record.id;
		
		const budgetFilter = { where: { budgetId: budget.id }};
		
		return <Popup className="payment-edit" onClose={onClose}>
			<FormPlain record={record} onSave={() => onClose(update)} onDelete={() => onClose(true)}>
				{type === 'outgoing' && <Row>
					<Field relation="person" computed={this.renderPerson} property='lastName' label={t('person.title')} filter={this.personFilter}/>
					<Field relation="budget" property="name" label={t('budget.title')} disabled/>
					<Field relation="role" property="name" label={t('budgetRole.role')} filter={budgetFilter}/>
				</Row>}
				<Row>
					<Field property="sum" label={t('sum')}><NumberInput type="currency" /></Field>
					{type === 'incoming' && <Field relation="budget" property="name" label={t('budget.title')} disabled/>}
					<Field property="date" label={t('payment.date')}/>
					{type === 'outgoing' && <div/>}
				</Row>
				<Field property='comment' label={t('comment.title')}><textarea rows={5}/></Field>
			</FormPlain>
		</Popup>;
	}
	
}
