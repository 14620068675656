import React from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { Field, Row, Select, Button } from '@smartplatform/ui';
import { ModelEdit, DeleteButton } from 'components';
import store from 'client/store';
import Warning from './Warning';
import FeatureValues from './FeatureValues';
import TYPES from './types';
import t from 'i18n';
import './style.scss';

@observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable usages = null;

	constructor(props) {
		super(props);
	}

	getRecord = async record => {
		this.record = record;
		if (this.record.type === TYPES.LIST && !this.record.values) {
			this.record.values = [];
		}
		if (record.id) {
			const priceValues = await store.model.PricePositionValue.count({
				featureId: record.id,
			});
			const orderValues = await store.model.OrderPositionValue.count({
				featureId: record.id,
			});
			this.usages = [priceValues, orderValues];
		}
	}

	beforeSave = () => {
		if (this.record.type === TYPES.LIST && Array.isArray(this.record.values)) {
			this.record.values = this.record.values.map(value => String(value).trim()).filter(value => value.length > 0);
		}
	};

	onTypeChange = type => {
		if (!this.record.values && type === TYPES.LIST) {
			this.record.values = [];
		}
	};

	onValueChange = (e, i) => this.record.values[i] = e.target.value;

	deleteValue = async () => {
		await this.record.featureValues();
	};

	addValue = () => this.record.values.push('');

	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);

		// console.log('TYPES', TYPES);
		const types = Object.values(TYPES).map(typeName => ({ label: t(`feature.${typeName}`), value: typeName }));

		const isUsed = this.usages !== null && this.usages[0] + this.usages[1] > 0;
		// console.log('isUsed', isUsed);

		const props = {
			model: store.model[modelName],
			path,
			id,
			noDelete: isUsed,
			// disabled: isUsed,
			title: t('feature.title'),
			beforeSave: this.beforeSave,
			getRecord: this.getRecord,
			filter: {
				include: [ 'featureValues' ],
			}
		};

		return <ModelEdit {...props}>
			<Row>
				<Field property="name" label={t('name')} isRequired />
				<Field property="type" label={t('feature.type')} isRequired disabled={isUsed}>
					<Select items={types} onChange={this.onTypeChange} isRequired />
				</Field>
			</Row>
			{this.record && this.record.type === TYPES.LIST && <>
				{isUsed && <Warning usages={this.usages} />}
				<FeatureValues record={this.record} />
			</>}
		</ModelEdit>;
	}
};
