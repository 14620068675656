import React from 'react';

import { Column, ColorValue } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path }) => {
	return <ModelList model={store.model.ViewProjectList} path={path} title={t('projectList.plural')}>
		<Column width={200} property="name" label={t('name')} />
		<Column width={50} property="color" className="text-center" label={t('color')}><ColorValue /></Column>
		<Column property="priority" label={t('priority')} className="text-center"/>
		<Column property="total" label={t('project.count')} className="text-center"/>
		<Column width={120} property="closing" label={t('projectList.closing')} className="text-center" />
		<Column property="description" label={t('description')}/>
	</ModelList>;
};
