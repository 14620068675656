import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { User } from 'components';
import t from 'i18n';
import './style.scss';

@observer
export default class MemberCard extends React.Component {

	onClick = () => this.props.onClick(this.props.record);
	
	render() {
		const { record } = this.props;
		
		return <div className="member-card" onClick={this.onClick}>
			<div className="member-info">
				<div className="person">{record.person ? <User user={record.person} size={18} /> : '-'}</div>
				<div className="role">
					{record.role ? <><FontAwesomeIcon icon={faCog}/> {record.role.name}</> : ''}
					{record.agent ? <> {t('budget.agent')}</> : ''}
				</div>
			</div>
			<div className="member-numbers">
				<div className="block sum">
					<label>{t('sum')}</label>
					{record.sum ? <><strong>{record.sum}</strong> <em>{t('rub')}</em></> : '-'}
				</div>
				<div className="block">
					<label>{t('percent')}</label>
					{record.percent ? <><strong>{Math.round(record.percent * 100)}</strong> <em>%</em></> : '-'}
				</div>
				<div className="block">
					<label>{t('price')}</label>
					{record.price ? <><strong>{record.price}</strong> <em>{t('rub')}</em></> : '-'}
				</div>
				<div className="block">
					<label>{t('budgetRole.paid')}</label>
					{record.paid ? <><strong>{record.paid}</strong> <em>{t('rub')}</em></> : '-'}
				</div>
			</div>
		</div>;
	}
	
}
