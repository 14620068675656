import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

const IconTitle = ({ icon, text, faIcon }) => {
	if (faIcon) {
		icon = <FontAwesomeIcon icon={faIcon} />;
	}

	return (
		<span className='icon-title'>
			{icon}
			{text || null}
		</span>
	);
};

export default IconTitle;
