export const FIELDS = ['period', 'startDate', 'endDate', 'bankAccounts', 'contragent', 'cashFlows', 'paymentType', 'project', 'statuses'];
export const PERIODS = ['currentMonth', 'currentQuarter', 'currentYear', 'specified', 'all'];
export const ALL = 'ALL';
export const COSTS = 'COSTS';
export const INCOME = 'INCOME';
export const TRANSFER = 'TRANSFER';
export const PLAN = 'PLAN';
export const FACT = 'FACT';
export const COLORS = {
	COSTS: '#FF0000',
	INCOME: '#008000',
};

