import React from 'react';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import { getRoleNames } from 'client/tools';
import Overview from './Overview';
import Order from './order/Order';
import OrdersStore from './store';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

export const ORDERS_MODE_TILES = 'tiles';
export const ORDERS_MODE_LIST = 'list';
export const ORDERS_MODE_KANBAN = 'kanban';
export const ORDERS_MODE_REPORT = 'report';

@observer
export default class Orders extends React.Component {
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.store = new OrdersStore();
		store.ui.title = t('order.plural');
		this.init()
	}
	init = async () => {
		// роли в конструкторе не успевают прогрузиться до рендера
		this.roles = await getRoleNames();
		this.store.canViewSum = this.roles .includes('admin') || this.roles .includes('manager');
		if (!this.store.canViewSum) this.store.availableListIds = await store.model.ViewOrderList.getLists({rolesNames:this.roles });
		// 
		this.isLoading = false;
	}

	render() {
		if (this.isLoading) return null;
		return <div className="orders-root">
			<Provider store={this.store}>
				<Switch>
					<Route path="/ordersreport" exact><Overview mode={ORDERS_MODE_REPORT}/></Route>
					<Route path="/orders" exact><Overview /></Route>
					<Route path="/orders/:id" component={Order}/>
				</Switch>
			</Provider>
		</div>;
	}

}
