import React from 'react';
import { observer, Provider } from 'mobx-react';
import ProjectsList from './projects-list/ProjectsList';
import ProjectsKanban from './projects-kanban/ProjectsKanban';
import ProjectTiles from './projects-tiles/ProjectTiles';
import store from 'client/store';
import ProjectsStore from './ProjectsStore';
import ProjectsToolbar from './projects-toolbar/ProjectsToolbar';
import { MODES } from 'client/constants';
import t from 'i18n';

@observer
export default class Projects extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('project.plural');
		this.store = new ProjectsStore();
		this.store.init();
	}

	render() {
		const { isLoading, mode } = this.store;
		if (isLoading) return null;
		const components = {
			[MODES.LIST]: <ProjectsList {...this.props} store={this.store} />,
			[MODES.KANBAN]: <ProjectsKanban />,
			[MODES.TILES]: <ProjectTiles store={this.store} />,
		};

		return (
			<Provider store={this.store}>
				<div className='fixed-page projects-root '>
					<ProjectsToolbar />
					{components[mode]}
				</div>
			</Provider>
		);
	}
}

