import { action, observable } from 'mobx';
import t from 'i18n';

export const THEMES = ['default', 'dark'];

export default class UI {
	@observable title = '';
	@observable menu = false;
	@observable kanban = false;
	@observable notification = false;

	constructor(store) {
		this.store = store;
	}

	@action setNotification = (notification) => {
		if (notification) {
			const { message, error } = notification;
			this.notification = { message, error };
		} else {
			this.notification = false;
		}
	};
}
