import React, { useState } from 'react';

import Label from './Label';
import './style.scss';

export default props => {
	const record = props.record || (props.__data ? props.__data.record : null);
	if (!record) return 'no record';

	const [ labels, setLabels ] = useState(props.labels || record.labels());
	if (!props.labels) (async () => setLabels(await labels))();

	// TODO: почему приходят промисы вместо готовых данных?

	return labels.then ?
		null
		:
		labels.map(label => <Label key={label.id} label={label} size={props.size} />);
}
