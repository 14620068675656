import React from 'react';

import { Column, ColorValue } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path }) => {
	return <ModelList model={store.model[modelName]} path={path} title={t('document.card.status')}>
		<Column width={200} property="name" label={t('name')} />
		<Column width={50} property="color" className="text-center" label={t('color')}><ColorValue /></Column>
	</ModelList>;
}
