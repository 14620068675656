import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { Pager } from '@smartplatform/ui';
import MemberCard from './MemberCard';
import MemberEdit from './MemberEdit';
import store from 'client/store';
import t from 'i18n';

const PER_PAGE = 5;

@observer
export default class Members extends React.Component {

	@observable records = [];
	@observable page = 1;
	@observable editMember = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.BudgetRole.find({
			where: {
				budgetId: this.props.budget.id,
			},
			include: ['person', 'role'],
			order: 'id desc',
			skip: (this.page - 1) * PER_PAGE,
			limit: PER_PAGE,
		});
	};
	
	onPageChange = (page) => {
		this.page = page;
		this.init();
	};
	
	onClick = (member) => this.editMember = member;
	
	onClose = (reload) => {
		this.editMember = null;
		if (reload) {
			this.page = 1;
			this.init();
		}
	};
	
	add = () => {
		const member = new store.model.BudgetRole();
		member.budget = this.props.budget;
		this.editMember = member;
	}
	
	render() {
		return <div className="budget-members">
			<h3>{t('budgetRole.plural')}</h3>
			<div className="nav">
				<Pager current={this.page} totalCount={this.records.totalCount} onChange={this.onPageChange} itemsPerPage={PER_PAGE} />
				<div className="add"><FontAwesomeIcon icon={faPlusCircle} onClick={this.add} /></div>
			</div>
			{this.records.map(record => <MemberCard key={record.id} record={record} onClick={this.onClick} />)}
			{this.editMember && <MemberEdit record={this.editMember} onClose={this.onClose} />}
		</div>;
	}
	
}
