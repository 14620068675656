import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@smartplatform/ui';
import { fio, fioShort } from 'client/tools';

const User = props => {
	const { user, size, full } = props;
	return <div className="avatar-fio">
		<Avatar user={user} size={size} />
		<div className="fio">{(full ? fio(user) : fioShort(user)) || user.username || `userId: ${user.id}`}</div>
	</div>;
};

User.propTypes = {
	user: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	size: PropTypes.number,
	full: PropTypes.bool,
};

User.defaultProps = {
	size: 22,
};

export default User;
