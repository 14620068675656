import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ModelList } from 'components';
import t from 'i18n';
import React from 'react';

export default ({ modelName, path, title, searchValue }) => {
	const renderMaterialsCount = (record) => record.length;
	return (
		<ModelList
			noHeader
			noFilters
			searchValue={searchValue}
			model={store.model[modelName]}
			path={path}
			title={title}
			filter={{ include: ['materials'] }}
		>
			<Column property='name' label={t('name')} />
			<Column relation='materials' computed={renderMaterialsCount} label={t('material.count')} />
		</ModelList>
	);
};
