import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import t from 'i18n';

const path = '/budgets';
const modelName = 'Budget';

export const percentLeft = budget => {
	const sum = Math.round(budget.sum * budget.percentLeft);
	const percent = Math.round(budget.percentLeft * 100);
	return `${sum} (${percent}%)`;
};

export const Budgets = () => {
	return <Switch>
		<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} title={t('budget.plural')} />}/>
		<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} title={t('budget.plural')} />}/>
		<Route path={`${path}/create`} children={({ match }) => <Create title={t('budget.title')} />}/>
		<Route path={`${path}/:budgetId`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} title={t('budget.title')} />}/>
	</Switch>;
};
