import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import t from 'i18n';

const path = '/contracts';
const modelName = 'Contract';
const filter = {
	include: [
		{ relation: 'organization', scope: { fields: ['id', 'name'] } },
		{ relation: 'contragent', scope: { fields: ['id', 'name'] } },
	],
};

export const Contracts = () => (
	<Switch>
		<Route
			path={path}
			exact
			children={({ match }) => <List match={match} modelName={modelName} title={t('contract.plural')} filter={filter} />}
		/>
		<Route
			path={`${path}/page/:page`}
			children={({ match }) => <List match={match} modelName={modelName} title={t('contract.plural')} filter={filter} />}
		/>
		<Route
			path={`${path}/:contractId`}
			children={({ match }) => <Edit match={match} path={path} modelName={modelName} title={t('contract.title')} filter={filter} />}
		/>
	</Switch>
);
