import React, { Component } from 'react';
import { Column } from '@smartplatform/ui';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ModelList, Toolbar } from 'components';
import store from 'client/store';

@observer
export default class List extends Component {
	@observable count = null;
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model[this.props.modelName].count();
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { modelName, path } = this.props;

		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url='/orderpositionParameters/create' innerText={t('parameters.new')} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
					<Toolbar.Count text={t('total')} count={this.count} />
				</Toolbar>
				<ModelList
					noHeader
					noFilters
					searchValue={this.search}
					model={store.model[modelName]}
					path={path}
					title={t('orderPositionParameters.plural')}
				>
					<Column property='name' label={t('name')} />
				</ModelList>
			</>
		);
	}
}

