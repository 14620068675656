import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Avatar, Popover, Loader } from '@smartplatform/ui';
import { fio } from 'client/tools';
import './style.scss';

const LOADING = <div className="loading"><Loader size={18} /></div>;

@observer
export default class MemberAvatar extends React.Component {

	static propTypes = {
		member: PropTypes.object.isRequired,
		size: PropTypes.number,
	};

	static defaultProps = {
		size: 18,
	};

	@observable popup = LOADING;

	loadMember = async () => {
		const { member } = this.props;
		const projectRole = await member.role;
		this.popup = <>
			<div className="avatar"><Avatar user={member.user} size={20} /></div>
			<div className="info">
				<div className="fio">{fio(member.user)}</div>
				{projectRole && <div className="role">{projectRole.name}</div>}
			</div>
		</>;
	};

	onClose = () => this.popup = LOADING;

	render() {
		const { member, size, style } = this.props;

		return (
			<Popover
				className="project-member-avatar"
				contentClassName="project-member-popup"
				// trigger="click"
				content={this.popup}
				onActivate={() => this.loadMember(member)}
				onClose={this.onClose}
				style={style}>
				<Avatar user={member.user} size={size} />
			</Popover>
		);
	}

}
