import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { NumberValue } from '@smartplatform/ui';
import { DeleteButton } from 'components';

/**
 * Этот компонент больше не используется! Оставил его на вяский случай.
 * См. Positions.js - переделано с использованием ui/Table
 */

@observer
export default class PositionCard extends React.Component {

	getValue = orderPositionValue => {
		if (!orderPositionValue.feature) return `no feature! ${orderPositionValue.featureId}`
		const feature = orderPositionValue.feature;
		if (feature.type === 'boolean') return orderPositionValue.booleanValue ? feature.name : null;
		const property = feature.type === 'number' ? 'numberValue' : 'stringValue';
		return orderPositionValue[property];
	};

	render() {
		const { position, row, onClick, onDelete } = this.props;

		const values = position.values.then ? [] : position.values();

		return <div className="position-card" onClick={() => onClick(position)}>
			<div className="number">{row + 1}</div>
			<div className="category">{position.category ? position.category.name : '-'}</div>
			<div className="features" key={position.categoryId}>
				{values.map(this.getValue).filter(v => !!v).join(', ') || '-'}
			</div>
			<div className="amount">{position.amount}</div>
			<div className="amount">{position.unitPrice}</div>
			<div className="members">{position.members().length || '-'}</div>
			<div className="attachments">{position.attachments().length || '-'}</div>
			<div className="price">{position.final ? <NumberValue value={position.final} type="currency" /> : '-'}</div>
			<div className="actions" onClick={e => e.stopPropagation()}><DeleteButton onConfirm={onDelete}/></div>
		</div>;
	}

}
