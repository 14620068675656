import React from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';

import { Kanban } from '@smartplatform/ui';
import OrderCard from './OrderCard';
import store from 'client/store';
import { formatDate, fio } from 'client/tools';
import t from 'i18n';
import './style.scss';

export const INITIAL_LIMIT = 20;
export const DEFAULT_PRIORITY = 10000;

const ORDER_INCLUDE = [
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } }
			],
			order: 'id asc',
		}
	},
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color']
		}
	},
];

@inject('store') @observer
export default class OrdersKanban extends React.Component {

	@observable lists = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.doSearch;
		this.init();
	}

	init = async () => {
		const ordersWhere = this.store.compileFilter();
		const where = this.store.canViewSum ? undefined : { id: { inq: this.store.availableListIds } }
		const lists = await store.model.ViewOrderList.find({
			where,
			include: [
				{
					relation: 'orders',
					scope: {
						where: ordersWhere,
						limit: INITIAL_LIMIT,
						order: 'priority desc',
						include: ORDER_INCLUDE,
					}
				},
			],
			order: 'priority desc',
		});

/*
		const promises = lists.map(list => new Promise((resolve) => {
			store.model.Order.count({
				and: [
					...ordersWhere.and,
					{ listId: list.id },
				],
			}).then(res => {
				list.totalCount = res;
				resolve(res);
			});
		}))

		await Promise.all(promises);
*/

		this.lists = lists;
		this.isLoading = false;
	};

	doSearch = async () => {
		await this.init();
		if (this.kanban) {
			this.kanban.reload();
		}
	};

	renderOrder = (order, list, options) => <OrderCard order={order} list={list} canViewSum={this.props.store.canViewSum} {...options} />;

	@action onChange = async ({ item, text, prev, next, list, index }) => {
		console.log('onChange', item, prev, next, list, index);
		this.saving = true;

		// const order = new store.model.Order(item);
		const order = !item ? new store.model.Order(item) : await store.model.Order.findById(item.id, {
			include: ORDER_INCLUDE,
		});

		order.listId = list.id;
		// order.closed = list.closing || false;
		// if (list.closing) order.closedAt = new Date();

		if (!item) {
			// новый заказ
			order.description = text;
		}
		else {
			// order.membersCount = item.membersCount;
			// order.tasksCount = item.tasksCount;
			// order.openedCount = item.openedCount;
			// order.closedCount = item.closedCount;
		}

		if (!prev) {
			order.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		}
		else if (!next) {
			order.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		}
		else {
			order.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await order.save();
			if (!item) store.route.push({ path: `/orders/${order.id}` });
			this.saving = false;
		}
		catch (e) {
			this.saving = false;
			throw e;
		}

		// TODO: возвращать данные из item, а не project?

		return {
			id: order.id,
			listId: order.listId,
			path: `/orders/${order.id}`,
			data: order,
		};
	};

	gotoItem = path => store.route.push({ path });

	fakeDelay = (delay = 1000) => new Promise(resolve => setTimeout(resolve, delay));

	getInstance = instance => this.kanban = instance;

	loadMore = async list => {
		const ordersWhere = this.store.compileFilter();
		console.log('loadMore', list.items.length);

		const moreOrders = await store.model.Order.find({
			where: {
				and: [
					...ordersWhere.and,
					{ listId: list.id },
				],
			},
			order: 'priority desc',
			limit: INITIAL_LIMIT,
			skip: list.items.length,
			include: ORDER_INCLUDE,
		});

		return moreOrders.map(order => ({
			id: order.id,
			listId: order.listId,
			path: `/orders/${order.id}`,
			data: order,
		}));
	};

	render() {
		if (this.isLoading) return null;

		const kanbanProject = {
			name: t('order.plural'),
			lists: this.lists.map(list => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.count,
					data: list,
					items: list.orders().map(order => {
						return {
							id: order.id,
							listId: order.listId,
							path: `/orders/${order.id}`,
							data: order,
						};
					}),
				};
			})
		};

		return <div className="orders-kanban">
			<div className="orders-kanban-container">
				{this.lists.length > 0 ?
					<Kanban
						project={kanbanProject}
						renderItem={this.renderOrder}
						itemClassName="order-kanban-card"
						onChange={this.onChange}
						getInstance={this.getInstance}
						loadMore={this.loadMore}
						onError={this.onError}
						gotoItem={this.gotoItem}
						itemsLimit={20}
					/>
					:
					<>
						Не заданы <Link to={`/orderlists`}>состояния заказов</Link>
					</>
				}
			</div>
		</div>;
	}

}
