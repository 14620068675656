import React from 'react';
export class NomenclatureGroups extends React.Component {
	render() {
		const { groups, onChange, listGroupId } = this.props;
		if (!groups.length) return null;
		return (
			<ul className={`nomenclature-groups__list`}>
				{groups.map((group) => (
					<li key={group.id}>
						<span
							className={listGroupId === group.id ? 'nomenclature-groups__marked-item' : ''}
							onClick={() => onChange(group)}
						>
							{group.name.charAt(0).toUpperCase() + group.name.toLowerCase().slice(1)}
						</span>
						<NomenclatureGroups groups={group.children()} onChange={onChange} listGroupId={listGroupId} />
					</li>
				))}
			</ul>
		);
	}
}
