import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@smartplatform/ui';
import { SmallDeleteButton } from 'components';
import ParametersEdit from './ParametersEdit';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Parameters extends React.Component {

	@observable parameters = [];
	@observable currentParameter = null;
	@observable newParameter = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {

		this.parameters = await this.props.position.parameters.find({ include: { relation: 'parameter', fields: ['id', 'name '] } });
	};

	onListMount = el => this.list = el;

	addParameter = async () => {
		this.newParameter = new store.model.OrderPositionParameterValue();
		this.newParameter.orderPosition = this.props.position;
		setTimeout(() => {
			if (this.list) this.list.scrollTop = this.list.scrollHeight;
		}, 100);
	};

	edit = (parameter) => this.currentParameter = parameter;

	delete = async (parameter) => {
		await parameter.delete();
		this.reload();
	};

	reload = () => {
		this.stopEdit();
		this.init();
	};

	@action stopEdit = () => {
		console.log('stop')
		this.newParameter = null;
		this.currentParameter = null;
	};

	render() {
		return <div className="position-parameters">
			<div className="form-field">
				<label>{t('orderPosition.parameters')}</label>
			</div>
			{(this.parameters.length > 0 || this.newParameter) && <div className="parameters-list" ref={this.onListMount}>
				{this.parameters.map(parameter => {

					const name = parameter.parameter && parameter.parameter.name;
					const value = parameter.value;

					if (this.currentParameter && this.currentParameter.id === parameter.id) {
						return <ParametersEdit
							parameter={parameter}
							exclude={this.parameters.filter(m => m.parameterId !== parameter.parameterId).map(m => m.parameterId)}
							onSave={this.reload}
							onCancel={this.reload}
						/>;
					}
					return <div key={parameter.id} className="parameter">
						<div className="name">{name}</div>
						<div className="value">{value}</div>
						<div className="actions">
							<span className="edit-btn" onClick={() => this.edit(parameter)}><FontAwesomeIcon icon={faPencilAlt} /></span>
							<SmallDeleteButton onConfirm={() => this.delete(parameter)} />
						</div>
					</div>;
				})}
				{this.newParameter &&
					<ParametersEdit
						parameter={this.newParameter}
						exclude={this.parameters.map(m => m.parameterId)}
						onSave={this.reload}
						onCancel={this.reload}
					/>
				}
			</div>}
			<div className="actions">
				{!this.newParameter && <Button variant="primary" onClick={this.addParameter}>{t('orderPosition.addParameter')}</Button>}
			</div>
		</div>;
	}

}
