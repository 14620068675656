import { getChildrenRelation } from 'client/tools';

export const path = '/nomenclature';
export const modelName = 'Nomenclature';

export const query = {
	where: {
		name: { inq: ['Продукция', 'СКЛАД УЧЕТА МАТЕРИАЛОВ'] },
	},
	...getChildrenRelation(),
};
