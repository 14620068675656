import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ModelEdit } from 'components';
import store from 'client/store';
import { Field } from "@smartplatform/ui";


@observer
export default class Create extends React.Component {
	@observable record = null;
	render () {
		const { modelName, path } = this.props;
		return (
			<div className="fixed-page">
				<ModelEdit model={store.model[modelName]} stay path={path} title="Создание типа контрагента">
					<Field property='name' label='Краткое наименование'/>
				</ModelEdit>
			</div>
		);
	}
};