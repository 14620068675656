import React from "react";
import store from 'client/store';
import t from 'i18n';
import { ModelList } from 'components';
import { Column, ColorValue } from '@smartplatform/ui';


const List = ({ modelName, path }) => {
    return (
        <ModelList disableFilters model={store.model[modelName]} path={path} title="Информационные базы с которыми происходит синхронизация">
            <Column property="basePrefix" label="Префикс базы-источника" width={150}/>
            <Column property="secondBaseName" label="Наименование базы-источника" />
            <Column property="secondBaseNodeCode" label="Код нового узла базы-источника" />
            <Column property="nodeCode" label="Код нового узла этой базы" />
            <Column property="codePredefinedNode" label="Код предопределённого узла" />
        </ModelList>
    );
}

export default List;