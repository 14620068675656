import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFolder,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { default as translate } from 'i18n';
import Projects from "./projects";
import Info from "./info";

const t = slug => translate(`document.card.tabs.${slug}`);

export default (path, record) => ([
	{
		title: <><FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{t('info')}</>,
		path: `${path}/:documentId`,
		exact: true,
		component: (props) => <Info {...props} document={record} path={path} />
	},
	{
		title: <><FontAwesomeIcon icon={faFolder} />&nbsp;&nbsp;{t('projects')}</>,
		path: `${path}/:documentId/projects`,
		component: (props) => <Projects {...props} document={record} />
	},
]);