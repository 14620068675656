import React, { Component } from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList, Toolbar } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export default class extends Component {
	@observable search = '';
	@observable count = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model.Contragent.count({ contragentId: null });
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { modelName, path, match } = this.props;

		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url='/contragents/create' innerText={t('client.create')} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
					<Toolbar.Count text={t('client.total')} count={this.count} />
					<Toolbar.ExportBtn />
				</Toolbar>
				<ModelList
					noFilters
					noHeader
					searchValue={this.search}
					model={store.model[modelName]}
					filter={{
						where: { contragentId: null },
					}}
					path={path}
					match={match}
					title={t('client.plural')}
				>
					<Column width={200} property='name' label={t('name')} />
					<Column property='personName' label={t('client.personName')} />
					<Column property='personRole' label={t('client.personRole')} />
					<Column property='phone' label={t('phone')} />
					<Column property='email' label={t('email.title')} />
					<Column property='comment' label={t('comment.title')} />
					<Column property='myCompany' label={t('contragents.card.myCompany')} />
				</ModelList>
			</>
		);
	}
}

//TODO реализовать экспорт, поиск и количество контрагентов

