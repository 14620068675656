import React from 'react';

import { ColorValue, Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path }) => {
	return <ModelList model={store.model[modelName]} path={path} title={t('boardList.plural')}>
		<Column width={200} property="name" label={t('name')} />
		<Column width={50} property="color" className="text-center" label={t('color')}><ColorValue /></Column>
		<Column width={120} property="closing" label={t('boardList.closing')} className="text-center"/>
		<Column width={120} property="priority" label={t('priority')}/>
		<Column width={150} property="projectDefault" label={t('boardList.projectDefault')} />
		<Column width={150} property="default" label={t('boardList.default')} />
		<Column property="description" label={t('description')}/>
	</ModelList>;
};
