const MAX_QUERY_DEPTH = 10;

export const getChildrenRelation = (depth = 0) => {
	if (depth >= MAX_QUERY_DEPTH) return {};
	return {
		include: {
			relation: 'children',
			scope: getChildrenRelation(depth + 1),
		},
	};
};
