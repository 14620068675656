import React from 'react';
import { Field, Row } from "@smartplatform/ui";
import FormContext from "@smartplatform/ui/src/components/form/context";
import { FIELDS, AUTOCOMPLETE, Suggestions } from "components/contragents";

export default class Info extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.handleSelectSuggestions = this.handleSelectSuggestions.bind(this);
	}
	handleSelectSuggestions(suggestion) {
		if(!suggestion || !this.context.record) return;
		Object.keys(AUTOCOMPLETE).forEach(property => {
			this.context.record[property] = AUTOCOMPLETE[property](suggestion)
		})
	}
	render() {
		return(
			<div className='row'>
				<div className='col-4'>
					<h3 className='mb-3 mt-2'>Основные сведения</h3>
					<Row>
						<Field property={FIELDS.NAME} label='Наименование:'>
							<Suggestions onSelect={this.handleSelectSuggestions}/>
						</Field>
					</Row>
					<Row>
						<Field property={FIELDS.INN} label='ИНН:' />
					</Row>
					<Row>
						<Field property={FIELDS.KPP} label='КПП:' />
					</Row>
					<Row>
						<Field property={FIELDS.LEGAL_ADDRESS} label='Юридический адрес:'>
							<textarea rows={4} />
						</Field>
					</Row>
					<Row>
						<Field property='actualAddress' label='Фактический адрес:'>
							<textarea rows={4} />
						</Field>
					</Row>
					<Row>
						<Field property='comment' label='Комментари:'>
							<textarea rows={5} />
						</Field>
					</Row>
				</div>
				<div className='col-4'>
					<h3 className='mb-3 mt-2'>Дополнительные сведения</h3>
					<Row>
						<Field property={FIELDS.OKATO} label='ОКАТО:' />
					</Row>
					<Row>
						<Field property={FIELDS.OKTMO} label='ОКТМО:' />
					</Row>
					<Row>
						<Field property={FIELDS.OKVED} label='ОКВЭД:' />
					</Row>
					<Row>
						<Field property={FIELDS.OGRN} label='ОГРН:' />
					</Row>
					<Row>
						<Field property={FIELDS.OKPO} label='ОКПО:' />
					</Row>
					<h3 className='mb-3 mt-2'>Данные о руководителе</h3>
					<Row>
						<Field property={FIELDS.OWNER_NAME} label='Фамилия, имя, отчество руководителя:' />
					</Row>
					<Row>
						<Field property={FIELDS.OWNER_ROLE} label='Должность руководителя:' />
					</Row>
				</div>
			</div>
		);
	}
}