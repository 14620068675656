import React from 'react';
import { inject, observer } from 'mobx-react';
import { Editor, CreateButton, CancelButton } from 'components';
import { relative, processText } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('taskStore')
@observer
export class Description extends React.Component {
	autoHeight = (el) => {
		if (el) {
			el.style.height = '5px';
			const height = el.scrollHeight + 2;
			el.style.height = Math.max(height, 48) + 'px';
		}
	};

	render() {
		const {
			task,
			onNameChange,
			onDescriptionChange,
			onSaveNameAndDescription,
			cancelEditNameAndDescription,
			startEditNameAndDescription,
			editNameAndDescription,
			onDescriptionMount,
			getInstance,
		} = this.props.taskStore;

		return (
			<div className='task-information'>
				{editNameAndDescription ? (
					<>
						<div className='name'>
							<textarea rows={1} value={task.name} onChange={onNameChange} ref={this.autoHeight} autoFocus />
						</div>
						<div className='description'>
							<Editor
								key={task.id}
								value={task.description}
								onChange={onDescriptionChange}
								mediaModel={store.model.Attachment}
								onInit={getInstance('editor')}
								autoResize={true}
								minHeight={150}
							/>
							<div className='toggle'>
								<CreateButton text={t('save')} onClick={onSaveNameAndDescription} />
								<CancelButton onClick={cancelEditNameAndDescription} />
							</div>
						</div>
					</>
				) : (
					<>
						<div className='name'>
							<div className='name-value' dangerouslySetInnerHTML={{ __html: task.name.replace(/[\r\n]/g, '<br />') }} />
							<div className='updated'>
								{t('task.updatedAt')}: {relative(task.updatedAt)}
							</div>
						</div>
						<div className='description'>
							<div
								className='rich-text'
								dangerouslySetInnerHTML={{
									__html: processText(task.description?.length > 0 ? task.description : t('task.addDescription')),
								}}
								ref={onDescriptionMount}
							/>
							<div className='toggle'>
								<CreateButton text={t('modify')} onClick={startEditNameAndDescription} />
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}
