import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SaveIcon from '!!svg-react-loader!client/img/save-btn.svg';

export default class SaveButton extends React.Component {

	static propTypes = {
		onClick: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
	};

	render() {
		const { onClick, disabled } = this.props;
		const className = classNames('save-btn', { disabled });

		return <SaveIcon onClick={onClick} className={className} />;
	}

}
