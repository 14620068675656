import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import './style.scss';

@observer
export default class Dashboard extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		return <div className="fixed-page">
			Dashboard
		</div>;
	}
	
}
