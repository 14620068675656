import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from '../../store';

@observer
export default class Footer extends React.Component {

	switchTheme = (e, theme) => {
		e.preventDefault();
		store.local.theme = theme;
		store.local.save();
		document.body.className = theme + '-theme';
	};

	render() {
		return <div className="footer">
			<div className="theme">
				<a href="#" onClick={e => this.switchTheme(e, 'default')}>default</a>&nbsp;|&nbsp;
				<a href="#" onClick={e => this.switchTheme(e, 'dark')}>dark</a>
			</div>
			<div className="copyright">© SmartUnit LLC {new Date().getFullYear()}</div>
		</div>;
	}
	
}
