import { Field, Form, NumberInput, Popover, Popup, RelationSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Positions from '../Positions';
import { NotPhone, Phone } from '../../../../../components';

@inject('store')
@observer
export default class EditProcess extends React.Component {
	@observable record = null;
	@observable position = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = this.props.record;
		this.isNew = !this.record?.id;
		if (this.isNew) {
			this.record = new store.model.ProcessLog();
			this.record.orderId = this.props.orderId;
		} else {
			this.position = this.record.position;
		}
		this.isLoading = false;
	};

	onPositionSelect = (position) => {
		if (this.popover) this.popover.close();
		this.position = position;
	};
	// onRelationChange = (relationName) => (value) => (this.record[relationName] = value);
	beforeSave = () => {
		this.record.position = this.position;
	};

	onWorkShopChange = (workshop) => {
		this.record.workshop = workshop;
		this.record.operation = null;
		this.record.equipment = null;
	};

	onClose = () => this.props.onClose();

	onSave = async () => {
		if (this.isNew) {
			const orderLog = new store.model.OrderLog();
			orderLog.orderId = this.props.orderId;
			orderLog.recordId = this.record.id;
			orderLog.updated = ['newProcess'];
			await orderLog.save();
		}
		this.props.onClose();
	};

	getPopoverInstance = (instance) => (this.popover = instance);

	render() {
		if (this.isLoading) return null;
		const popup = <Positions onSelect={this.onPositionSelect} />;
		const workShopFilter = { where: { workshopId: this.record.workshop?.id } };
		return (
			<Popup className='edit-process-popup' onClose={this.onClose} header={t('process.title')}>
				<Popover trigger='click' position='bottom' className='popover' getInstance={this.getPopoverInstance} content={popup}>
					{this.position ? (
						<span>{(this.position.category && this.position.category.name) || t('process.noPositionCategory')} </span>
					) : (
						t('process.selectPosition')
					)}
				</Popover>
				<Form beforeSave={this.beforeSave} record={this.record} stay onSave={this.onSave} noDelete>
					<Row>
						<Field relation='workshop' property='name' label={t('process.workshop.title')}>
							<RelationSelect
								onChange={this.onWorkShopChange}
								filter={{ where: this.record.targetWorkshop ? { id: { neq: this.record.targetWorkshop?.id } } : undefined }}
							/>
						</Field>
						<Field relation='targetWorkshop' property='name' label={t('process.targetWorkshop')}>
							<RelationSelect
								filter={{ where: this.record.workshop ? { id: { neq: this.record.workshop?.id } } : undefined }}
							/>
						</Field>
					</Row>
					<Row key={this.record.workshop?.id}>
						<Field relation='operation' property='name' label={t('process.operation.title')}>
							<RelationSelect filter={workShopFilter} />
						</Field>
						<Field relation='equipment' property='name' label={t('process.equipment')}>
							<RelationSelect filter={workShopFilter} />
						</Field>
					</Row>
					<NotPhone>
						<Row>
							<Field property='adjustmentsNumber' label={t('process.adjustmentsNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
							<Field property='operatorsNumber' label={t('process.operatorsNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
							<Field property='workingTime' label={t('process.workingTime')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
					</NotPhone>
					<Phone>
						<Row>
							<Field property='adjustmentsNumber' label={t('process.adjustmentsNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
						<Row>
							<Field property='operatorsNumber' label={t('process.operatorsNumber')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
						<Row>
							<Field property='workingTime' label={t('process.workingTime')}>
								<NumberInput positiveOnly integerOnly />
							</Field>
						</Row>
					</Phone>
				</Form>
			</Popup>
		);
	}
}
