import React from 'react';
import { observer, Provider } from 'mobx-react';
import Lightbox from 'react-image-lightbox';
import SideBar from './sidebar/SideBar';
import History from './history/History';
import { Description } from './description';
import t from 'i18n';
import './style.scss';
import { TaskStore } from './store';
import { NotPhone, Phone } from 'components';

@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TaskStore(parseInt(this.props.match.params.id));
		this.store.init();
	}

	render() {
		const { task, isLoading } = this.store;
		if (isLoading) return null;
		if (!task) return <span className='error'>{t('task.notFound')}</span>;

		return (
			<Provider taskStore={this.store}>
				<div className='task-edit fixed-page'>
					<Phone>
						<div className='task-main'>
							<Description />
						</div>
						<SideBar />
						{task.project && <History />}
					</Phone>
					<NotPhone>
						<div className='task-main'>
							<Description />
							{task.project && <History />}
						</div>
						<SideBar />
					</NotPhone>
				</div>
			</Provider>
		);
	}
}

