import React from 'react';

export default class Projects extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <>В разработке</>;
	}
}