import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Row } from '@smartplatform/ui';
import FeatureField from './FeatureField';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Features extends React.Component {

	@observable isLoading = true;
	@observable priceCategoryFeatures = [];
	@observable values = {};

	constructor(props) {
		super(props);
		this.category = props.category;
		if (this.category) {
			this.props.getInstance && this.props.getInstance({
				update: this.updateOrderPositionValues,
			});
			this.init();
		}
	}

	init = async () => {
		const { category, position } = this.props;

		this.priceCategoryFeatures = await category.priceCategoryFeatures.find({
			where: { featureId: { neq: null }},
			order: 'weight asc',
		});

		const orderPositionValues = await store.model.OrderPositionValue.find({
			where: {
				positionId: position.id,
				priceCategoryFeatureId: { inq: this.priceCategoryFeatures.map(f => f.id)},
			},
			include: [
				{ relation: 'priceCategoryFeature' },
				{ relation: 'featureValue' },
			],
		});
		// console.log('orderPositionValues', JSON.stringify(orderPositionValues));

		const values = {};
		this.priceCategoryFeatures.forEach(priceCategoryFeature => {
			const value = orderPositionValues.find(orderPositionValue => orderPositionValue.priceCategoryFeatureId === priceCategoryFeature.id);
			if (value) {
				values[priceCategoryFeature.id] = value;
			}
			else {
				const newValue = new store.model.OrderPositionValue();
				newValue.position = position;
				if (!priceCategoryFeature) {
					console.error('no priceCategoryFeature!');
				}
				else {
					newValue.feature = priceCategoryFeature.feature;
					newValue.priceCategoryFeature = priceCategoryFeature;
				}
				values[priceCategoryFeature.id] = newValue;
			}
		});
		console.log('values', values);
		this.values = values;

		this.onChange();
		this.isLoading = false;
	};

	updateOrderPositionValues = (orderPositionValues) => {
		const values = {};
		orderPositionValues.forEach(orderPositionValue => {
			values[orderPositionValue.priceCategoryFeatureId] = orderPositionValue;
		});
		console.log('values', values);
		this.values = values;
	};

	onChange = () => {
		this.props.onChange && this.props.onChange(Object.values(this.values));
	};

	onFeatureChange = (orderPositionValue) => {
		// console.log('onFeatureChange', orderPositionValue);
		// console.log('onFeatureChange values', Object.values(this.values).map(r => JSON.stringify(r)));
		this.onChange();
	};

	render() {
		if (!this.props.category) {
			return <div className="features">
				<label>{t('feature.plural')}</label>
				<div className="hint">{t('orderPosition.selectCategoryToEditFeatures')}</div>
			</div>;
		}

		if (this.isLoading) {
			return <div className="features">...</div>;
		}

		if (this.priceCategoryFeatures.length === 0) {
			return <div className="features">
				<label>{t('feature.plural')}</label>
				<div className="hint">
					{t('orderPosition.noFeaturesSetForCategory')}<br />
					<Link to={`/pricecategories/${this.props.category.id}/features`}>{t('orderPosition.gotoCategory')}</Link>
				</div>
			</div>;
		}

		const featuresFields = this.priceCategoryFeatures.map(priceCategoryFeature => {
			return <FeatureField
				key={priceCategoryFeature.id}
				priceCategoryFeature={priceCategoryFeature}
				orderPositionValue={this.values[priceCategoryFeature.id]}
				onChange={this.onFeatureChange}
			/>;
		})

/*
		const rows = [];
		const rowLength = 3;
		for (let i = 0, k = 0; i < featuresFields.length; i += rowLength) {
			const row = featuresFields.slice(i, i + rowLength);
			if (row.length === rowLength) {
				rows.push(<Row key={k++}>{row}</Row>);
			}
			else {
				rows.push(<Row key={k++}>{row}{[...new Array(rowLength - row.length)].map((v, i) => <div key={i}/>)}</Row>);
			}
		}
*/

		return <div className="features">
			{/*<div className="form-field"><label>{t('feature.plural')}</label></div>*/}
			<div className="features-fields">
				{featuresFields}
			</div>
		</div>;
	}

}
