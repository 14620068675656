import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import './style.scss';

@observer
export class Notification extends React.Component {
	render() {
		return store.ui.notification ? (
			<div
				onAnimationEnd={() => {
					store.ui.setNotification();
				}}
				className={`notification notification-${store.ui.notification.error ? 'error' : 'success'}`}
			>
				<p> {store.ui.notification.message} </p>
			</div>
		) : (
			<div />
		);
	}
}
