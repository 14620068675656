import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Popup, Form, Input, Field, Row, NumberInput, Select, Checkbox, RecordSelect, Loader } from '@smartplatform/ui';
import { DeleteButton, AddButton } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class EditPricePosition extends React.Component {

	@observable isLoading = true;
	@observable priceCategoryFeatures = [];
	@observable pricePositionValues = [];
	@observable error = null;
	@observable emptyFeatures = {};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { pricePosition } = this.props;

		const [ priceCategory, pricePositionValues ] = await Promise.all([
			store.model.PriceCategory.findById(pricePosition.categoryId, {
				include: [
					{
						relation: 'priceCategoryFeatures',
						scope: {
							where: { featureId: { neq: null }},
							include: [
								{ relation: 'feature', scope: { fields: ['id', 'name', 'type', 'values'] }},
							],
							order: 'weight asc',
						}
					},
				],
			}),
			this.loadPositionValues(),
		]);

		this.priceCategoryFeatures = priceCategory.priceCategoryFeatures();
		this.pricePositionValues = pricePositionValues;

		const missingFeatures = this.priceCategoryFeatures
			.filter(priceCategoryFeature => {
				const feature = priceCategoryFeature.feature;
				const positionValue = this.pricePositionValues.find(positionValue => positionValue.featureId === feature.id);
				return !positionValue;
			});

		if (missingFeatures.length > 0) {
			console.log('! missingFeatures', missingFeatures.length);
			await Promise.all(missingFeatures.map(this.createPositionValue));
			this.pricePositionValues = await this.loadPositionValues();
		}

		this.isLoading = false;
	};

	loadPositionValues = async () => {
		return await this.props.pricePosition.values.find({
			include: [
				{ relation: 'feature', scope: { fields: ['id'] }},
			],
		});
	};

	createPositionValue = async (priceCategoryFeature) => {
		const { pricePosition } = this.props;
		const positionValue = new store.model.PricePositionValue();
		console.log('+ creating new PricePositionValue', priceCategoryFeature.feature, 'for', pricePosition);
		positionValue.position = pricePosition;
		positionValue.feature = priceCategoryFeature.feature;
		await positionValue.save();
		return positionValue;
	};

	renderPositionField = (priceCategoryFeature) => {
		const feature = priceCategoryFeature.feature;

		let positionValue = this.pricePositionValues.find(positionValue => positionValue.featureId === feature.id);

		if (!positionValue) {
			console.warn('feature not found!', feature, this.pricePositionValues);
			return 'ERR';
		}

		let property = 'stringValue';
		if (feature.type === 'number') {
			property = 'numberValue';
		}
		else if (feature.type === 'boolean') {
			property = 'booleanValue';
		}
		else if (feature.type === 'list') {
			property = 'featureValueId';
		}

		const onChange = value => {
			positionValue[property] = value;
			if (priceCategoryFeature.required) {
				delete this.emptyFeatures[priceCategoryFeature.featureId];
			}
		};

		const inputProps = { value: positionValue[property], onChange };

		let input = <Input {...inputProps} />;
		if (feature.type === 'number') {
			input = <NumberInput {...inputProps} />;
		}
		else if (feature.type === 'boolean') {
			input = <Checkbox {...inputProps} />;
		}
		else if (feature.type === 'list') {
			// const types = feature.values.map(value => ({ label: value, value }));
			input = <RecordSelect
				model={store.model.FeatureValue}
				property="name"
				value={positionValue.featureValue}
				onChange={record => positionValue.featureValue = record}
				filter={{ where: { featureId: feature.id }}}
			/>;
		}
		return input;
	};

	getPositionValue = priceCategoryFeature => {
		return this.pricePositionValues.find(value => value.featureId === priceCategoryFeature.featureId);
	}

	getPositionValues = () => this.priceCategoryFeatures.map(this.getPositionValue);

	onSave = async () => {
		const dirtyPositionValues = this.getPositionValues().filter(positionValue => positionValue.isDirty);
		console.log('dirtyFeatures', dirtyPositionValues.map(p => p.feature.name));
		await Promise.all(dirtyPositionValues.map(positionValue => positionValue.save()));
		this.props.onClose && this.props.onClose(true);
	};

	beforeSave = () => {
		const emptyFeatures = {};
		const emptyRequired = this.priceCategoryFeatures
			.filter(priceCategoryFeature => {
				const positionValue = this.getPositionValue(priceCategoryFeature);
				if (priceCategoryFeature.required) {
					console.log('>', JSON.stringify(positionValue));
					return positionValue.feature.type !== 'boolean' &&
						!positionValue.stringValue && !positionValue.numberValue;
				}
				return false;
			})

		emptyRequired.forEach(positionValue => emptyFeatures[positionValue.featureId] = true);
		console.log('emptyFeatures', emptyRequired.map(p => p.feature.name));
		this.emptyFeatures = emptyFeatures;
		return emptyRequired.length === 0;
	};

	cancel = () => {
		this.props.onClose && this.props.onClose(false);
	};

	render() {
		const { pricePosition, onClose } = this.props;

		if (this.isLoading) {
			return <Popup className="price-position-edit" onClose={this.cancel}>
				<Loader />
			</Popup>;
		}

		console.log('[EditPricePosition] render', this.pricePositionValues.length);

		const featuresFields = this.priceCategoryFeatures.map(priceCategoryFeature => (
			<div className="form-field" key={priceCategoryFeature.id} aria-invalid={!!this.emptyFeatures[priceCategoryFeature.featureId]}>
				<label>{priceCategoryFeature.feature.name} {priceCategoryFeature.required && <span className="is-required">*</span>}</label>
				{this.renderPositionField(priceCategoryFeature)}
			</div>
		));

		const rows = [];
		const rowLength = 3;
		for (let i = 0, k = 0; i < featuresFields.length; i += rowLength) {
			const row = featuresFields.slice(i, i + rowLength);
			if (row.length === rowLength) {
				rows.push(<Row key={k++}>{row}</Row>);
			}
			else {
				rows.push(<Row key={k++}>{row}{[...new Array(rowLength - row.length)].map((v, i) => <div key={i}/>)}</Row>);
			}
		}

		const header = <>{t('pricePosition.title')} <span>[{pricePosition.id}]</span></>;

		return <Popup className="price-position-edit" onClose={this.cancel} header={header}>
			<Form record={pricePosition} stay beforeSave={this.beforeSave} onSave={this.onSave} controls={<div className="error">{this.error}</div>}>
				{rows}
				<Row>
					<Field property="name" label={t('pricePosition.name')} />
					<Field property="price" label={t('pricePosition.priceForOne')}><NumberInput positiveOnly decimalScale={2}/></Field>
					<div/>
				</Row>
				<Row>
					<Field property="amountFrom" label={t('pricePosition.amountFrom')}><NumberInput positiveOnly integerOnly /></Field>
					<Field property="amountTo" label={t('pricePosition.amountTo')}><NumberInput positiveOnly integerOnly/></Field>
					<div/>
				</Row>
			</Form>
		</Popup>;
	}

}
