import React from 'react';

import { Button } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { AddButton, Toolbar } from 'components';

@observer
export default class PriceList extends React.Component {
	@observable categories = [];
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		// получаем все категории
		const allCategories = await store.model.PriceCategory.find({
			include: [
				{
					relation: 'priceCategoryFeatures',
					scope: {
						include: [{ relation: 'feature', scope: { fields: ['id', 'name'] } }],
						order: 'weight asc',
					},
				},
				{
					relation: 'positions',
					scope: {
						fields: ['id'],
					},
				},
			],
			where: { name: { ilike: `%${this.search}%` } },
			order: 'name asc',
		});

		// рекурсивная функция, вытаскивающая дочерние записи для категории
		const getChildren = (category) => {
			category.__children = [];
			allCategories.forEach((cat) => {
				if (cat.parentId === category.id) {
					cat.__level = category.__level + 1; // уровень категории = уровень родителя + 1
					getChildren(cat);
					category.__children.push(cat);
				}
			});
		};

		if (!this.search) {
			// сначала нам нужны только корневые категории
			const rootCategories = allCategories.filter((cat) => cat.parentId === null);

			// у корневых катеогрий уровень = 0 (для дальнейшего вычисления уровней в getChildren)
			rootCategories.forEach((category) => (category.__level = 0));

			// для каждой их них запускаем рекурсивную функцию getChildren
			rootCategories.forEach(getChildren);

			this.categories = rootCategories;
		} else {
			this.categories = allCategories;
		}

		store.ui.title = t('priceList.title');
	};

	gotoCategory = (id) => {
		store.route.push({ path: `/pricecategories/${id}` });
	};

	create = (e, id) => {
		e.stopPropagation();
		store.route.push({ path: `/pricecategories/create`, params: { parentId: id } });
	};

	onSearch = (e) => {
		this.search = e.target.value;
		this.init();
	};

	// рендер детей
	renderChildren = (record) => {
		return record.__children.length > 0 ? record.__children.map(this.renderCategory) : null;
	};

	renderFeatures = (record) =>
		record
			.priceCategoryFeatures()
			.filter((c) => !!c.feature)
			.map((c) => c.feature.name)
			.join(', ');

	renderPositions = (record) => record.positions().length;

	// рендер категории
	renderCategory = (record) => {
		const paddingLeft = 40 * record.__level;
		return (
			<>
				<tr key={record.id} onClick={() => this.gotoCategory(record.id)}>
					<td style={{ backgroundClip: 'content-box', padding: '0 0 0 40px', paddingLeft }}>
						<span>{record.__children.length ? '📂' : '📄'}</span>
						<span>{record.name}</span>
					</td>
					<td>{this.renderFeatures(record)}</td>
					<td>{this.renderPositions(record)}</td>
					<td>
						<AddButton onClick={(e) => this.create(e, record.id)} />
					</td>
				</tr>
				{this.renderChildren(record)}
			</>
		);
	};

	render() {
		return (
			<div>
				<div className='price-list-filters'>
					<Toolbar store={this.pStore} className='toolbar-page'>
						<Toolbar.CreateBtn onClick={this.create} innerText={t('priceList.create')} />
						<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} inputPlaceholder={t('search')} />
						<Toolbar.Count text={t('priceList.total')} count={this.categories.length} />
						<Toolbar.ExportBtn onClick={this.exportAll} />
					</Toolbar>
				</div>
				<table className='sp-table clickable price-list-table'>
					<thead>
						<tr>
							<th>{t('priceCategory.name')}</th>
							<th>{t('priceCategory.features')}</th>
							<th>{t('priceCategory.positions')}</th>
						</tr>
					</thead>
					<tbody>{this.categories.map((record) => this.renderCategory(record))}</tbody>
				</table>
			</div>
		);
	}
}

//TODO сделать функционал эспорта и возможно сделать count через запрос

