import { observable } from 'mobx';
import store from 'client/store';
import { TASK_INCLUDE, TASK_LOG_INCLUDE, TASK_COMMENT_INCLUDE } from './constants';
import isAfter from 'date-fns/isAfter';
import t from 'i18n';

export class TaskStore {
	@observable isLoading = true;
	@observable task = null;
	@observable history = [];
	@observable editNameAndDescription = false;
	@observable changed = false;

	prevDescr = null;
	prevName = null;
	logs = [];
	comments = [];

	constructor(id) {
		this.id = id;
		store.ui.title = t('task.title') + ' #' + id;
	}

	init = async () => {
		await this.fetchRecord();
		await this.fetchHistory();

		this.isLoading = false;
	};

	fetchRecord = async () => {
		this.task = await store.model.Task.findById(this.id, TASK_INCLUDE);
		this.labels = this.task.labels();
	};
	fetchHistory = async (full = false) => {
		const logs = await this.task.logs.find({
			...TASK_LOG_INCLUDE,
			skip: full ? 0 : this.logs.length,
		});

		const comments = await this.task.comments.find({
			...TASK_COMMENT_INCLUDE,
			where: { type: null },
			skip: full ? 0 : this.comments.length,
		});

		this.logs = full ? logs : [...this.logs, ...logs];
		this.comments = full ? comments : [...this.comments, ...comments];
		const items = [
			...this.logs.map((record, index) => ({ type: 'taskLog', record, index })),
			...this.comments.map((record, index) => ({ type: 'comment', record, index })),
		];
		items.sort((a, b) => (isAfter(new Date(a.record.createdAt), new Date(b.record.createdAt)) ? 1 : -1));
		this.history = items;
	};

	getInstance = (instance) => (value) => (this[instance] = value);

	onSaveNameAndDescription = async () => {
		if (!this.changed) {
			this.cancelEditNameAndDescription();
			return;
		}
		const payload = [];
		if (this.task.name !== this.prevName) payload.push('name');
		if (this.task.description !== this.prevDescr) payload.push('description');
		await this.onSave(payload);
		this.editNameAndDescription = false;
	};

	startEditNameAndDescription = () => {
		this.prevDescr = this.task.description;
		this.prevName = this.task.name;
		this.editNameAndDescription = true;
	};

	cancelEditNameAndDescription = () => {
		this.task.description = this.prevDescr;
		this.task.name = this.prevName;
		this.editNameAndDescription = false;
	};

	onDescriptionMount = (el) => {
		if (el) {
			const links = el.getElementsByClassName('lightbox');
			const imageLinks = [];
			for (let link of links) {
				if (link.tagName.toLowerCase() === 'a') imageLinks.push(link);
			}
			imageLinks.forEach((link, i) => {
				link.onclick = (e) => {
					e.preventDefault();
					this.onImageClick(imageLinks, i);
				};
			});
		}
	};

	onNameChange = (e) => {
		this.task.name = e.target.value;
		// this.autoHeight(e.target);
		this.changed = true;
	};

	onDescriptionChange = (content) => {
		this.task.description = content;
		this.changed = true;
	};

	onUserChange = async (user) => {
		this.task.user = user;
		await this.onSave(['userId']);
	};

	onSave = async (fields) => {
		this.changed = false;
		if (fields) {
			const payload = {};
			fields.forEach((property) => (payload[property] = this.task[property]));
			await this.task.patchAttributes(payload);
		} else {
			await this.task.save();
		}
		this.fetchHistory(true);
	};

	onLabelChange = (newLabels) => {
		if (newLabels.length > this.labels.length) {
			const labelId = newLabels[newLabels.length - 1].id;
			this.createTaskLog({ updated: ['addLabel'], labelId });
		} else {
			const labelId = this.labels.find((label) => !newLabels.includes(label)).id;
			this.createTaskLog({ updated: ['removeLabel'], labelId });
		}
		this.labels = newLabels;
	};

	createTaskLog = async (data) => {
		await store.model.TaskLog.create({
			taskId: this.task.id,
			...data,
		});
		this.fetchHistory(true);
	};
}
