import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Desktop } from '../../components';
import store from 'client/store';
import User from './User';
import './style.scss';
import logo from 'client/img/sp-logo.svg';

@observer
export default class Header extends React.Component {

	constructor(props) {
		super(props);
	}

	toggleMenu = () => {
		store.ui.menu = !store.ui.menu;
	};

	render() {
		return <header className="topbar">
			<div className="header">
				<div className="mobile-menu" onClick={this.toggleMenu}><FontAwesomeIcon icon={faBars} /></div>
				<div className="title">
					<div className="logo"><Link to="/"><img src={logo} alt="" /></Link></div>
					<div className="name">
						<Link to="/">Smart<strong>ERP</strong></Link>
						<small className="version">{VERSION} ({BUILD})</small>
					</div>
				</div>
			</div>
			<div className="toolbar">
				<Desktop><div className="toolbar-left">{store.ui.title || ''}</div></Desktop>
				{store.model.isAuthorized && <div className="toolbar-right">
					{/*<ThemeSwitch />*/}
					<User />
				</div>}
			</div>
		</header>;
	}

}
