export const TASK_INCLUDE = {
	include: [
		{
			relation: 'project',
			scope: {
				fields: ['id', 'name'],
				include: [
					{ relation: 'members', scope: { fields: ['id', 'userId'], where: { userId: { neq: null } } } },
					{ relation: 'lists', scope: { fields: ['id', 'name', 'color'] } },
				],
			},
		},
		{ relation: 'attachments' },
		{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] } },
	],
};

export const TASK_LOG_INCLUDE = {
	include: [
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'label', scope: { fields: ['id', 'name', 'color'] } },
	],
	order: 'createdAt asc',
};

export const TASK_COMMENT_INCLUDE = {
	include: [
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'attachments' },
	],
	order: 'createdAt asc',
};

