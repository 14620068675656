import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup, Row, Form, Field, MaskedInput } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class NewContact extends React.Component {

	@observable error = null;
	@observable isSaving = false;

	constructor(props) {
		super(props);
		this.contragent = new store.model.Contragent();
		this.contragent.contragentId = props.order.contragentId;
	}

	beforeSave = contragent => {
		this.error = null;

		if (!contragent.email && !contragent.phone && !contragent.personName) {
			this.error = t('contragent.empty');
			return false;
		}

		if (contragent.phone) {
			contragent.phone = contragent.phone.replace(/[^+\d]/g, '');
		}
	};

	onSave = async (contragent) => {
		this.isSaving = true;

		await this.props.order.contacts.add(contragent.id);
		this.props.onClose(true);
	};

	onClose = () => {
		this.props.onClose();
	};

	onError = (error) => {
		console.log(error,'form error')
		if (error && error.message && error.message.includes('Email already exists')) this.error = t('user.emailAlreadyExists');
	};

	render() {
		const controls = <span className="error" style={{ marginLeft: 15 }}>{this.error}</span>;

		return <Popup className='new-contact-popup' onClose={this.onClose} header={t('contact.new')}>
			{!this.isSaving &&
				<Form onError={this.onError} record={this.contragent} beforeSave={this.beforeSave} stay onSave={this.onSave} controls={controls}>
					<Row>
						<Field property="personName" label={t('contragents.card.personName')} />
						<Field property="personRole" label={t('contragents.card.personRole')} />
					</Row>
					<Row>
						<Field property="phone" label={t('user.phone')}>
							<MaskedInput mask="+7 (999) 999 9999" placeholder="Телефон" />
						</Field>
						<Field property="email" label={t('user.email')} />
					</Row>
				</Form>
			}
		</Popup>;
	}

}
