import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import {
	Popup,
	Form,
	Field,
	Row,
	NumberInput,
	Checkbox,
	Loader,
	Input
} from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

/**
 * TODO: вынести эту функцию
 * */
const calculateBalance = record => {
	const balance = record.balance();
	const rows = [ ...balance ];
	if(!rows.length)
		return 0;
	return rows.reduce((balance, operation) => {
		if(operation.debit.isAsset)
			balance+=operation.quantity;
		if(operation.credit.isAsset)
			balance-=operation.quantity;
		return balance;
	}, 0);
}

@observer
export default class EditWaybill extends React.Component {

	@observable isLoading = true;
	@observable error = null;
    @observable record = null;
	@observable nomenclatureGroup = null;
	constructor(props) {
		super(props);
		this.init();
	}
	init = async () => {
        this.order = this.props.order;
        this.record = this.props.record ||  new store.model.Waybill({ orderId: this.order.id });
		this.isLoading = false;
	};
    cancel = () => this.props.onClose && this.props.onClose();

	createNewWaybillLog = ({ id }) => {
		if (!id) {
			const orderLog = new store.model.OrderLog();
			orderLog.orderId = this.order.id;
			orderLog.updated = ['newWaybill'];
			orderLog.save();
		}
	};

    renderNomenclature = (r) => r && <>
        {r.name && <span style={{ display: 'inline-block', width: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{r.name}</span>}
        {<i style={{ color: 'lightgrey', float: 'right' }}>{calculateBalance(r)} {r.measure}.</i>}
    </>;

	render() {
        const isIncorrectCount = this.record.nomenclature?.balance && this.record.count > calculateBalance(this.record.nomenclature);
		return(
            <Popup onClose={this.cancel} className='edit-waybill-popup'>
                {
                    this.isLoading ?
                    <Loader /> :
						<Form record={this.record} stay beforeSave={this.createNewWaybillLog} onSave={this.props.onSave} onDelete={this.props.onDelete}>
                        <Row>
							<Field
								relation="nomenclature"
								computed={this.renderNomenclature}
								label={t('order.waybill.name')}
								filter={{
									include: {
										relation: 'balance',
										scope: {
											include: [ 'debit', 'credit' ]
										}
									}
								}}
							/>
						</Row>
                        <div className='row'>
							<div className='col-8'>
								<Field property="count" label='Количество' disabled={!this.record.nomenclature}>
									<NumberInput positiveOnly integerOnly style={isIncorrectCount ? { color: '#d00', fontWeight: 'bold' } : {}}/>
								</Field>
							</div>
							<div className='col-4'>
								<div class="form-field">
									<label>Ед. измерения </label>
									<Input disabled value={this.record.nomenclature?.measure} />
								</div>
							</div>
						</div>
                        <Row>
                            <Field property="gaveOut" disabled={!this.record.nomenclature}>
                                <Checkbox label={t('order.waybill.gaveOut')} />
                            </Field>
                            <Field property="defect" disabled={!this.record.nomenclature}>
                                <Checkbox label={t('order.waybill.defect')} />
                            </Field>
                            <Field property="return" disabled={!this.record.nomenclature}>
                                <Checkbox label={t('order.waybill.return')} />
                            </Field>
							<Field property="reserved" disabled={!this.record.nomenclature}>
								<Checkbox label='Резерв.' />
							</Field>
                        </Row>
                    </Form>
                }
            </Popup>
        );
	}

}
