import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default (props) => {
	return (
		<ModelList {...props} model={store.model[props.modelName]} disableCreate>
			{Object.keys(store.model[props.modelName].PROPERTIES)
				.filter((property) => property !== 'link' && !['At', 'Id', 'id'].includes(property.slice(-2)))
				.map((property) => (
					<Column key={property} property={property} label={t('contract.' + property)} />
				))}
			<Column relation='organization' property='name' label={t('contract.organization')} />
			<Column relation='contragent' property='name' label={t('contract.contragent')} />
		</ModelList>
	);
};
