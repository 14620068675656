import React from 'react';
import t from 'i18n';
import { RefreshButton } from 'components'
import { Button, Select } from '@smartplatform/ui';
import { format } from 'date-fns';

export default class Toolbar extends React.Component {

	constructor(props) {
		super(props);
		this.reportStore = this.props.reportStore;
	}


	render() {
		const { isLoading, limit, onEditStart, onLimitChange, downloadReport, editMode, refresh, onEditFinish, onEditCancel } = this.reportStore;

		const disabled = editMode || isLoading;
		const currentDate = format(new Date(), "dd.MM.yyyy");

		return <div className='toolbar'>
			<div className='d-flex flex-row'>
				<div className='form-field'>
					<label>{t('order.count')}</label>
					<Select
						items={[100, 500, 1000].map(limit => ({ label: limit, value: limit }))}
						value={limit}
						size='sm'
						onChange={onLimitChange}
						noSearch
						width={100}
						disabled={disabled}
					/>
				</div>
				<div className='actions'>
					<Button variant='primary' size='sm' disabled={disabled} onClick={() => downloadReport({ id: 'report-table', name: 'Отчет по заказам за ' + currentDate })}>{t('order.downloadReport')}</Button>
					{editMode ? <>
						<Button variant='primary' size='sm' onClick={onEditFinish}>{t('modify')}</Button>
						<Button variant='default' size='sm' onClick={onEditCancel}>{t('cancel')}</Button>
					</>
						:
						<>
							<Button variant='primary' size='sm' disabled={disabled} onClick={onEditStart}>{t('order.editReport')}</Button>
							<RefreshButton onClick={refresh} disabled={disabled} />
						</>

					}

				</div>
			</div>
		</div>
	}
}
