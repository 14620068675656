import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Input, NumberInput, Select, Checkbox, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class FeatureField extends React.Component {

	@observable feature = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		// const { priceCategoryFeature } = this.props;
		// this.feature = await priceCategoryFeature.feature;
	};

	onChange = value => {
		const { priceCategoryFeature } = this.props;
		const feature = priceCategoryFeature.feature;
		const property = feature.type === 'number' ? 'numberValue' : feature.type === 'boolean'? 'booleanValue': 'stringValue';

		this.props.orderPositionValue[property] = value;
		this.props.onChange && this.props.onChange(this.props.orderPositionValue);
	};

	onListValueChange = record => {
		this.props.orderPositionValue.featureValue = record;
		this.props.onChange && this.props.onChange(this.props.orderPositionValue);
	};

	render() {
		const { priceCategoryFeature, orderPositionValue } = this.props;

		const feature = priceCategoryFeature.feature;
		const property = feature.type === 'number' ? 'numberValue' : feature.type === 'boolean'? 'booleanValue': 'stringValue';

		const value = orderPositionValue[property];
		// console.log('>', feature.name, feature.type, value);

		const inputProps = { value, onChange: this.onChange };

		let input = <Input {...inputProps} />;
		if (feature.type === 'number') {
			input = <NumberInput {...inputProps} />;
		}
		else if (feature.type === 'boolean') {
			input = <Checkbox {...inputProps} />;
		}
		else if (feature.type === 'list') {
			// const types = feature.values.map(value => ({ label: value, value }));
			// input = <Select items={types} {...inputProps} />;
			input = <RecordSelect
				model={store.model.FeatureValue}
				property="name"
				value={orderPositionValue.featureValue}
				onChange={this.onListValueChange}
				filter={{ where: { featureId: feature.id }}}
			/>;
		}

		return <div className="form-field">
			<label>{feature ? feature.name : 'name?'} {/*{priceCategoryFeature.required && <span className="red">*</span>}*/}</label>
			{input}
		</div>;
	}

}
