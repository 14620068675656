export const INITIAL_LIMIT = 20;

export const TASK_FIELDS = [
	'id',
	'name',
	'priority',
	'boardListId',
	'userId',
	'closed',
	'closedAt',
	'updatedAt',
	'commentsCount',
	'attachmentsCount',
	'hasDescription',
	'fio',
	'username',
	'labelNames',
	'dueDate',
];

export const USER_FIELDS = ['id', 'avatar', 'lastName', 'firstName', 'middleName', 'username'];
export const TASK_INCLUDE = [
	{ relation: 'user', scope: { fields: USER_FIELDS } },
	{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] } },
];

export const LOCAL_STORAGE_FIELDS = ['mode'];
