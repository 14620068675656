import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field, FormContext, Button, Popconfirm } from '@smartplatform/ui';
import CustomField from './CustomField';
import store from 'client/store';
import './style.scss';

@observer
export default class FormTest extends React.Component {
	
	@observable record = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const tasks = await store.model.Task.find({ order: 'id desc', limit: 1 });
		this.record = tasks[0];
	};
	
	customSave = async (form) => {
		await form.save();
	};

	customDelete = async (form) => {
		await form.delete();
		this.record = null;
		this.init();
	};
	
	render() {
		if (!this.record) return '...';
		
		/**
		 * Используем контекст с помощью Consumer. Содержимое контекста см. в CustomField.
		 */
		
		const controls = <FormContext.Consumer>
			{context => <>
				<Button onClick={() => this.customSave(context.form)}>Custom Save</Button>
				<Popconfirm content="delete?" onConfirm={() => this.customDelete(context.form)}><Button>Custom Delete</Button></Popconfirm>
			</>}
		</FormContext.Consumer>;
		
		return <Form record={this.record} noSave noDelete controls={controls} stay>
			<Field property="id" label="id" disabled />
			<Field property="name" label="name" />
			<CustomField />
		</Form>;
	}
	
}
