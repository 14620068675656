import React, { useState } from 'react';
import { Field, Button, Checkbox } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';


export default class ViewImport extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);

		return (
			<ModelEdit model={store.model[modelName]} path={path} id={id} title="Журнал синхронизации" disabled noDelete noSave stay>
				<div className='row'>
					<div className='col-2'>
						<Field property="updatedAt" label="Дата и время" />
					</div>
					<div className='col-10'>
						<Field property='name' label='Имя файла'/>
					</div>
				</div>
				<Field property='name'>
					<FileLoad />
				</Field>
				<Field property='id'>
					<Reimport />
				</Field>
			</ModelEdit>
		);
	}

}
const FileLoad = ({ value }) => {
	return <a href={`/api/containers/edfiles/download/${value}`} download>{value}</a>
}
const Reimport = (props) => {
	const [ isLoad, setLoad ] = useState(false);
	const [ isFull, setFull ] = useState(false);
	const [ message, setMessage ] = useState('-');
	const { value } = props;
	const handleClick = async () => {
		setLoad(true);
		const jobId = await store.model.EdFile.reimportData(value, isFull);
		const checkerInterval = setInterval(async () => {
			const job = await store.model.EdFile.checkImportStatus(jobId);
			setMessage(job?.message);
			if(job?.status === 'success' || job?.status === 'error') {
				clearInterval(checkerInterval);
				setLoad(false);
			}
		}, 3500);
	};
	return (
		<>
			<Checkbox label='Полный импорт' disabled={isLoad} onChange={setFull} value={isFull}/>
			<div>Статус: {message}</div>
			<Button
				disabled={isLoad}
				onClick={handleClick}
			>
				Откатиться до этой версии
			</Button>
		</>
	);
}