import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import MenuItem from './MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';

@observer
export default class SubMenu extends React.Component {
	@observable isVisible = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		const { index } = this.props;

		if (!store.local.submenu) {
			store.local.submenu = {};
			store.local.save();
		}
		if (!store.local.submenu[index]) {
			store.local.submenu[index] = false;
			store.local.save();
		}

		if (store.local.submenu[index] === true) {
			this.isVisible = true;
		}
	};

	changeVisibility = (visible) => {
		const { index } = this.props;
		this.isVisible = visible ?? !this.isVisible;

		store.local.submenu[index] = this.isVisible;
		store.local.save();
	};

	render() {
		const { submenu } = this.props;

		return (
			<div className={'submenu ' + (this.isVisible ? '' : 'submenu-invisible')}>
				<div className='title' onClick={() => this.changeVisibility()}>
					<span>{submenu.label}</span>
					<FontAwesomeIcon icon={faChevronUp} />
				</div>
				<div className='items'>
					{submenu.items.map((item, i) => {
						return (
							<MenuItem
								key={submenu.id + '-' + i}
								item={item}
								badgeFunc={item.badgeFunc}
								changeVisibility={this.changeVisibility}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}
