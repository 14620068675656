import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { NumberValue, Table, Column } from '@smartplatform/ui';
import { AddButton, DeleteButton } from 'components';
import PositionCard from './PositionCard';
import PositionEdit from './PositionEdit';
import store from 'client/store';
import t from 'i18n';

export const POSITION_INCLUDE = [
	{
		relation: 'category',
		scope: {
			include: [
				{
					relation: 'priceCategoryFeatures',
					scope: {
						where: { featureId: { neq: null }},
						include: [
							{ relation: 'feature', scope: { fields: ['id', 'name', 'type', 'values'] }},
						],
						order: 'weight asc',
					},
				}
			],
		}
	},
	{
		relation: 'values',
		scope: {
			include: [
				{
					relation: 'priceCategoryFeature',
					scope: {
						where: { featureId: { neq: null }},
						include: [
							{ relation: 'feature', scope: { fields: ['id', 'name', 'type', 'values'] }},
						],
						order: 'weight asc',
					},
				},
				{
					relation: 'featureValue',
					scope: {
						fields: ['id', 'name'],
						order: 'id asc',
					},
				},
			],
		},
	},
	{ relation: 'members' },
	{ relation: 'attachments' },
];

@inject('store') @observer
export default class Positions extends React.Component {

	@observable positions = [];
	@observable selectedPosition = null;

	constructor(props) {
		super(props);
		this.store = props.store;
		this.order = this.store.order;
		this.init();
		setTimeout(() => {
			// this.selectedPosition = this.positions[0];
		}, 200);
	}

	init = async () => {
		this.positions = [];
		this.positions = await this.order.positions.find({
			include: POSITION_INCLUDE,
			order: 'id asc',
		});
	};

	calcTotal = async () => {
		this.order.total = this.positions.length > 0 ? this.positions.map((p) => p.final || 0).reduce((a, b) => a + b) : 0;
		await this.order.save();
	};

	addPosition = async () => {
		const newPosition = new store.model.OrderPosition();
		newPosition.order = this.order;
		await newPosition.save();
		const position = await store.model.OrderPosition.findById(newPosition.id, {
			include: POSITION_INCLUDE,
		});
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.positionId = newPosition.id;
		orderLog.updated = ['newPosition'];
		await orderLog.save();
		this.positions.push(position);
		this.selectedPosition = position;
	};

	deletePosition = async (position, i) => {
		this.positions.splice(i, 1);
		await position.delete();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.updated = ['deletedPosition'];
		await orderLog.save();
		await this.calcTotal();
	};

	onPositionClick = position => this.selectedPosition = position;

	closeEditPopup = async (isUpdated) => {
		if (isUpdated) await this.calcTotal();
		this.init();
		this.selectedPosition = null;
	};

	test = data => {
		console.log('data', data);
		return JSON.stringify(data);
	};

	/**
	 * rowData содержит объект вида { record, relation, row, column }
	 * см. комментарий в @smartplatform/ui/src/components/table/Column.js (строка 168)
	 */

	renderFeatures = async rowData => {
		const orderPosition = rowData.record;
		const priceCategoryFeatures = orderPosition.category ? await orderPosition.category.priceCategoryFeatures() : [];
		console.log('priceCategoryFeatures', priceCategoryFeatures);
		const priceCategoryFeaturesIds = priceCategoryFeatures.map(f => f.featureId);

		console.log('renderFeatures: priceCategoryFeatures', priceCategoryFeaturesIds);
		const orderPositionValues = rowData.relation;
		if (!orderPositionValues || orderPositionValues.length === 0) return '-';
		return orderPositionValues
			.filter(orderPositionValue => !!orderPositionValue.feature)
			.filter(orderPositionValue => !!orderPositionValue.priceCategoryFeatureId)
			.filter(orderPositionValue => priceCategoryFeaturesIds.includes(orderPositionValue.featureId))
			.map(orderPositionValue => {
				// if (!orderPositionValue.feature) return `no feature! ${orderPositionValue.featureId}`
				const feature = orderPositionValue.feature;
				// if (!priceCategoryFeaturesIds.includes(feature.id)) return `no feature! ${orderPositionValue.featureId}`
				if (feature.type === 'list') return orderPositionValue.featureValue ? orderPositionValue.featureValue.name : null;
				if (feature.type === 'boolean') return orderPositionValue.booleanValue ? feature.name : null;
				if (feature.type === 'number') return orderPositionValue.numberValue;
				if (feature.type === 'string') return orderPositionValue.stringValue;
			})
			.filter(v => !!v).join(', ');
	};

	relationCount = rowData => rowData.relation && rowData.relation.length || '-';

	renderActions = rowData => {
		const { record, row } = rowData;
		return <DeleteButton onConfirm={() => this.deletePosition(record, row)} />;
	};

	/**
	 * Переделано с использованием ui/Table
	 */

	render() {
		const total = this.positions.map(p => p.final || 0).reduce((a, b) => a + b, 0);

		return <div className="order-positions fixed-page">
			<Table rows={this.positions} onRowClick={this.onPositionClick}>
				<Column label={t('number')} width={50}>{data => data.row + 1}</Column>
				<Column relation="category" property="name" label={t('orderPosition.category')}/>
				<Column property="pricePositionName" label={t('orderPosition.pricePositionName')}/>
				<Column relation="values" label={t('feature.plural')}>{this.renderFeatures}</Column>
				<Column width={80} relation="members" label={t('orderPosition.membersShort')} className="text-center">{this.relationCount}</Column>
				<Column width={50} relation="attachments" label={t('files')} className="text-center">{this.relationCount}</Column>
				<Column width={80} property="amount" label={t('orderPosition.amount')} className="text-right" />
				<Column width={80} property="unitPrice" label={t('orderPosition.unitPrice')} className="text-right"><NumberValue type="currency" /></Column>
				<Column width={100} property="price" label={t('orderPosition.price')} className="text-right"><NumberValue type="currency" /></Column>
				<Column width={40} className="text-right">{this.renderActions}</Column>
			</Table>
			{this.positions.length > 0 && <div className="total-price">
				{t('pricePosition.total')}: <strong><NumberValue type="currency" value={total} /></strong>
			</div>}
			<AddButton onClick={this.addPosition}/>
			{this.selectedPosition && <PositionEdit positionId={this.selectedPosition.id} onClose={this.closeEditPopup} />}
		</div>;
	}

}
