import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';

import { Avatar } from '@smartplatform/ui';
import { fioShort, relative } from 'client/tools';
import { Attachments, Editor, SmallDeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Comment extends React.Component {
	static propTypes = {
		className: PropTypes.string,
	};

	@observable editing = false;
	@observable lightboxImages = [];
	@observable imageIndex = 0;
	@observable showImages = false;

	changed = false;

	constructor(props) {
		super(props);
	}

	onEditorInit = (editor) => {
		this.editor = editor;
	};

	onTextChange = (value) => {
		this.props.comment.text = value;
		this.changed = true;
	};

	edit = () => {
		this.editing = true;
		this.origValue = this.props.comment.text;
	};

	save = async () => {
		if (this.changed) {
			const images = await this.uploadImages();

			this.props.comment.text = this.editor.getContent();
			await this.props.comment.save();

			for (let imgData of images) {
				console.log('>', imgData);
				const { element, uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await this.props.comment.attachments.add(id);
			}
		}
		this.editing = false;
		this.changed = false;
	};

	cancel = () => {
		this.editing = false;
		this.changed = false;
		this.props.comment.text = this.origValue;
	};

	uploadImages = () =>
		new Promise((resolve, reject) => {
			this.editor.uploadImages((success) => {
				if (success) {
					resolve(success);
				} else {
					reject();
				}
			});
		});

	processText = (html) => {
		if (html) {
			// нельзя использовать lookbehind - (?<= ) и (?<! ) - https://caniuse.com/js-regexp-lookbehind
			// временно заменяем готовые гиперссылки (тэги <a>) на "--anchor[N]--" и сохраняем их в массиве
			const anchorMatch = html.match(/<a[^<]+<\/a>/gi);
			const anchors = [];
			if (anchorMatch) {
				anchorMatch.forEach((anchor, i) => {
					anchors.push(anchor);
					html = html.replace(anchor, `--anchor${i}--`);
				});
			}
			// заменяем голые URL на гиперссылки
			const urlMatch = html.match(/((https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
			if (urlMatch) {
				urlMatch.forEach((url) => {
					html = html.replace(url, `<a target="_blank" href="${url}">${url}</a>`);
				});
			}
			// возвращаем сохраненные ранее готовые гиперссылки
			anchors.forEach((anchor, i) => (html = html.replace(`--anchor${i}--`, anchor)));

			const imgMatch = html.match(/<img[^>]+>/gi);
			if (imgMatch) {
				imgMatch.forEach((imgTag, i) => {
					const srcMatch = imgTag.match(/src="([^"]+)"/i);
					if (srcMatch && srcMatch[1]) {
						const src = srcMatch[1];
						console.log('-', src);
						try {
							const re = new RegExp(`(${imgTag})`, 'g');
							html = html.replace(re, `<a class='lightbox' target='_blank' href='${src}'>$1</a>`);
						} catch (e) {
							console.warn(e);
						}
					}
				});
			}
		}
		return html;
	};

	onMount = (el) => {
		if (el) {
			const links = el.getElementsByClassName('lightbox');
			const imageLinks = [];
			for (let link of links) {
				if (link.tagName.toLowerCase() === 'a') imageLinks.push(link);
			}
			imageLinks.forEach((link, i) => {
				link.onclick = (e) => {
					e.preventDefault();
					// console.log('click', link.href, link.title);
					this.onImageClick(imageLinks, i);
				};
			});
		}
	};

	onImageClick = (imageLinks, index) => {
		console.log('onImageClick', imageLinks, index);
		this.lightboxImages = imageLinks;
		this.imageIndex = index;
		this.showImages = true;
	};

	hideImages = () => (this.showImages = false);

	render() {
		const { comment, className } = this.props;

		return (
			<div className={'comment' + (className ? ' ' + className : '')}>
				<div className='who'>
					<Avatar user={comment.owner} size={24} />
					<div className='border' />
				</div>
				<div className='what'>
					<span className='subject'>{comment.owner ? fioShort(comment.owner) : t('user.deletedUser')} </span>
					<span className='action'>{t('history.leftComment')}</span>
					<span className='date'>{relative(comment.createdAt)}</span>
					<div className='text'>
						{this.editing ? (
							<Editor
								key={comment.id}
								value={comment.text}
								onChange={this.onTextChange}
								mediaModel={store.model.Attachment}
								onInit={this.onEditorInit}
								height={250}
								menubar={false}
								toolbar='undo redo | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | code | link image media'
							/>
						) : (
							<div
								className='rich-text'
								dangerouslySetInnerHTML={{ __html: this.processText(comment.text) }}
								ref={this.onMount}
							/>
						)}
					</div>
					{store.model.user && store.model.user.id === comment.ownerId && (
						<div className='toggle'>
							{this.editing ? (
								<a href='#' onClick={this.save}>
									{t('save')}
								</a>
							) : (
								<a href='#' onClick={this.edit}>
									{t('edit')}
								</a>
							)}
							{this.editing && (
								<>
									{' '}
									<a href='#' onClick={this.cancel}>
										{t('cancel')}
									</a>
								</>
							)}
							<SmallDeleteButton onConfirm={() => this.props.onDelete(comment)} confirmMessage={t('comment.confirmDelete')} />
						</div>
					)}
				</div>
			</div>
		);
	}
}
