import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Input, MaskedInput } from '@smartplatform/ui';
import './style.scss';

@observer
export default class InputTest extends React.Component {

	@observable text = '';
	@observable masked = '';

	render() {
		return <div className="input-test">
			<div className="block">
				<Input value={this.text} onChange={value => this.text = value} placeholder="Input" />{' '}
				<input value={this.text} onChange={e => this.text = e.target.value} placeholder="input" />
			</div>
			<div className="block">
				<MaskedInput className="masked" mask="+7(999)999-99-99" value={this.masked} onChange={e => this.masked = e.target.value} placeholder="phone" />
			</div>
		</div>;
	}

}
