import React from 'react';
import { observer } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import store from 'client/store';
import { THEMES } from '../../ui';
import t from 'i18n';

@observer
export default class ThemeSwitch extends React.Component {

	onThemeChange = theme => {
		store.local.theme = theme;
		store.local.save();
		document.body.className = theme + '-theme';
	};

	render() {
		return <Select
			value={store.local.theme}
			items={THEMES.map(theme => ({ label: t(`ui.themes.${theme}`), value: theme }))}
			onChange={this.onThemeChange}
			isRequired
			className="theme-switch drop-down-md"
		/>;
	}

}
