import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Avatar, NumberValue, ColorUtils } from '@smartplatform/ui';
import { relative } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class OrderTile extends React.Component {

	constructor(props) {
		super(props);
	}

	gotoOrder = () => {
		store.route.push({ path: `/orders/${this.props.order.id}` });
	};

	render() {
		const { order, canViewSum } = this.props;

		const _positions = order.positions();

		const listStyle = {
			background: order.list.color || '#ddd',
			color: order.list.color ? ColorUtils.textColor(order.list.color, ColorUtils.formula(0)) : '#000',
		};

		return <div className="order-tile" onClick={this.gotoOrder}>
			<div className="top">
				<div className="id">#{order.id}</div>
				<div className="updated">{relative(order.updatedAt)}</div>
			</div>
			<div className="main">
				{order.manager && <Avatar className="manager" user={order.manager} />}
				{order.description && <div className="name">{order.description}</div>}
				<div className="client">{order.clientName}</div>
				<div className="list"><span style={listStyle}>{order.list.name}</span></div>
				{_positions.length > 0 && <div className="positions">
					{_positions.slice(0, 2).map((position, i) => <div key={position.id} className="position">
						<div className="category">{i + 1}. {position.category.name}</div>
						<div className="amount">{position.amount} шт.</div>
						{canViewSum && <div className="price"><NumberValue value={position.price} type="currency" /></div>}
					</div>)}
					{_positions.length > 2 && <div>...</div>}
				</div>}
				{_positions.length > 0 && <div className="total">
					{canViewSum && <div className="price">{t('order.totalShort')}: <em><NumberValue value={order.total} type="currency" /></em></div>}
					<div className="positions-count">{t('order.positionsCount')}: {_positions.length}</div>
				</div>}
			</div>
		</div>;
	}

}
