import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import throttle from 'lodash/throttle';

import { RecordSelect, Select, Pager, ButtonGroup, Button } from '@smartplatform/ui';

import ProjectItem from './ProjectItem';
import { User } from 'components';
import store from 'client/store';
import './style.scss';
import t from 'i18n';

const ALL = 'ALL';
const NONE = 'NONE';

@observer
export default class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable page = 1;
	@observable perPage = 20;
	@observable closed = false;
	@observable user = null;
	@observable projectList = ALL;
	@observable order = 'lastUpdate desc';

	constructor(props) {
		super(props);
		this.onResize = throttle(this.onResize, 500, { leading: true, trailing: true });
		this.user = store.model.user;
		this.innerHeight = window.innerHeight;
		this.getPerPage();
		this.store = props.store;
		props.store.reload = this.doSearch;
		this.init();
	}

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	init = async () => {
		const where = { and: [] };

		const trimmed = this.store.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach((word) =>
				where.and.push({
					or: [{ id: { like: `${word}%` } }, { name: { ilike: `%${word}%` } }],
				})
			);
		}

		if (this.closed !== null) {
			if (this.closed) {
				where.and.push({ closed: true });
			} else {
				where.and.push({
					or: [{ closed: false }, { closed: null }],
				});
			}
		}

		if (this.projectList !== null) where.and.push({ projectListId: this.projectList.id });

		if (this.store.user) {
			where.and.push({ membersIds: { like: `%[${this.store.user.id}]%` } });
		}

		this.records = await store.model.ViewProject.find({
			where,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			order: this.order,
			include: [
				{ relation: 'projectList', scope: { fields: ['id', 'name', 'color'] } },
				{ relation: 'tasks', scope: { fields: ['id'] } },
				{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] } },
				{
					relation: 'members',
					scope: {
						order: 'id asc',
						limit: 5,
						where: { userId: { neq: null } },
						include: [
							{
								relation: 'user',
								fields: ['id', 'avatar', 'lastName', 'firstName', 'middleName'],
							},
						],
					},
				},
				{ relation: 'client', scope: { fields: ['id', 'name'] } },
			],
		});
	};

	doSearch = () => {
		this.page = 1;
		this.init();
	};

	onClosedChange = (value) => {
		this.page = 1;
		this.closed = value;
		this.init();
	};

	onResize = () => {
		this.getPerPage();
		this.init();
	};

	getPerPage = () => {
		let delta;
		if (window.innerWidth < 768) delta = 305;
		else if (window.innerWidth < 992) delta = 280;
		else delta = 220;
		let cellHeight = window.innerWidth < 768 ? 92 : 70;
		this.perPage = Math.floor((this.innerHeight - delta) / cellHeight);
	};

	onPageChange = (page) => {
		this.page = page;
		this.init();
	};

	onUserChange = async (user) => {
		this.user = user;
		this.page = 1;
		this.init();
	};

	onOrderChange = async (order) => {
		this.order = order;
		this.page = 1;
		this.init();
	};

	onProjectListChange = async (projectList) => {
		this.projectList = projectList;
		if (projectList.closing) {
			this.onClosedChange(null, true);
		} else {
			this.onClosedChange(null, false);
		}
	};

	renderUser = (user) => (user ? <User user={user} size={18} /> : '-');

	renderStatus = (boardList) => (
		<>
			<span className='color' style={{ background: boardList.color }} /> {boardList.name}
		</>
	);

	render() {
		return (
			<div className='fixed-page projects-list'>
				<div className='filters'>
					<div className='closed-filter'>
						<ButtonGroup>
							{[
								{ name: 'active', value: false },
								{ name: 'closed', value: true },
								{ name: 'all', value: null },
							].map(({ name, value }, index) => (
								<Button
									variant={this.closed === value ? 'primary' : 'default'}
									key={index}
									onClick={() => this.onClosedChange(value)}
								>
									{t('project.' + name)}
								</Button>
							))}
						</ButtonGroup>
					</div>
					<div className='projectlist-filter'>
						<span className='mobile'>{t('project.projectListShort')}</span>
						<RecordSelect
							model={store.model.ProjectList}
							value={this.projectList}
							onChange={this.onProjectListChange}
							isRequired
							prependItems={[{ label: t('filter.all'), value: ALL }]}
							showValue={this.projectList === ALL ? t('filter.all') : undefined}
							computed={this.renderStatus}
							searchFields={['name']}
							filter={{ order: 'name asc' }}
							width={125}
						/>
					</div>
					<div className='projects-order'>
						<Select
							value={this.order}
							onChange={this.onOrderChange}
							isRequired
							noSearch
							items={[
								{ label: t('project.sortByUpdated'), value: 'lastUpdate desc' },
								{ label: t('project.sortByNew'), value: 'id desc' },
							]}
							width={165}
						/>
					</div>
				</div>
				<Pager
					className='pages'
					current={this.page}
					totalCount={this.records.totalCount}
					itemsPerPage={this.perPage}
					onChange={this.onPageChange}
				/>
				<div className='fixed-page projects-items'>
					{this.records.map((project) => (
						<ProjectItem key={project.id} project={project} />
					))}
				</div>
			</div>
		);
	}
}
