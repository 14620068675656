import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faFile,
	faFileImage,
	faFileAudio,
	faFileVideo,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFilePowerpoint,
	faFileAlt,
	faFileCode,
	faFileArchive,
} from '@fortawesome/free-solid-svg-icons';

import mimeTypes from './mime-types';

const iconClasses = {
	// Media
	'image': faFileImage,
	'audio': faFileAudio,
	'video': faFileVideo,
	// Documents
	'application/pdf': faFilePdf,
	'application/msword': faFileWord,
	'application/vnd.ms-word': faFileWord,
	'application/vnd.oasis.opendocument.text': faFileWord,
	'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
	'application/vnd.ms-excel': faFileExcel,
	'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
	'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
	'application/vnd.ms-powerpoint': faFilePowerpoint,
	'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
	'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
	'text/plain': faFileAlt,
	'text/html': faFileCode,
	'application/json': faFileCode,
	// Archives
	'application/gzip': faFileArchive,
	'application/zip': faFileArchive,
	'application/x-7z-compressed': faFileArchive,
};

export function getIconForMimeType(mimeType) {
	// List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
	for (let type in iconClasses) {
		if (mimeType.indexOf(type) === 0) {
			return <FontAwesomeIcon icon={iconClasses[type]} />;
		}
	}
	return <FontAwesomeIcon icon={faFile} />;
}

export function getIconForExtension(ext) {
	return ext && mimeTypes[ext] ? getIconForMimeType(mimeTypes[ext]) : <FontAwesomeIcon icon={faFile} />;
}
