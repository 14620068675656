import React from 'react';
import { observer } from 'mobx-react';
import OrdersTiles from './tiles/OrdersTiles';
import OrdersList from './list/OrdersList';
import OrdersKanban from './kanban/OrdersKanban';
import OrdersReport from './report/OrdersReport';
import { ORDERS_MODE_LIST, ORDERS_MODE_TILES, ORDERS_MODE_REPORT } from './Orders';
import store from 'client/store';
import './style.scss';

@observer
export default class Mode extends React.Component {

	render() {
		if (this.props.mode === ORDERS_MODE_REPORT) return <OrdersReport {...this.props} />;
		if (store.local.ordersMode === ORDERS_MODE_LIST) return <OrdersList {...this.props} />;
		if (store.local.ordersMode === ORDERS_MODE_TILES) return <OrdersTiles {...this.props} />;
		return <OrdersKanban {...this.props} />;
	}
}
