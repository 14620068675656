import React from "react";
import store from 'client/store';
import { ModelList } from 'components';
import { Column } from '@smartplatform/ui';

const List = ({ modelName, path }) => {
    return (
        <ModelList model={store.model[modelName]} path={path} >
            <Column property="name" label="Краткое наименование" width={100} />
			<Column property="fullName" label="Полное наименование" width={100} />
			<Column property="hiddenFields" label="Скрытые поля" width={100} >
				{ ({ record }) => (record?.hiddenFields || []).join(', ') }
			</Column>
		</ModelList>
    );
}

export default List;