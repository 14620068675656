import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from 'client/store';
import List from './List';
import { NomenclatureGroups } from 'components';
import './style.scss';
import Single from './single-page';
import { Loader, Select } from '@smartplatform/ui';
import { modelName, path, query } from './constants';
import t from 'i18n';
@observer
export class Nomenclature extends React.Component {
	@observable isLoading = true;
	@observable selectedGroup = null;
	@observable listGroup = null;

	constructor(props) {
		super(props);
		this.init();
		store.ui.title = t('nomenclature.title');
	}

	init = async () => {
		this.groups = await store.model.NomenclatureGroup.find(query);
		if (!this.groups.length) {
			this.error = t('nomenclature.noGroups');
		} else {
			const groupInStorage = store.local.nomenclature?.selectedGroupName;
			this.selectedGroup = this.groups.find((group) => group.name === groupInStorage) || this.groups[0];
			this.onListGroupChange(this.selectedGroup);
		}
		this.isLoading = false;
	};

	get selectGroups() {
		return this.groups.map((group) => (group.value = group.name));
	}

	onSelectGroupChange = async (name) => {
		this.selectedGroup = this.groups.find((group) => group.name === name);
		this.onListGroupChange(this.selectedGroup);
		this.saveToLocalStore();
	};

	onListGroupChange = (group) => {
		this.listGroup = group;
		console.log(this.listGroup, '123');
	};

	saveToLocalStore() {
		if (!store.local.nomenclature) store.local.nomenclature = {};
		store.local.nomenclature.selectedGroupName = this.selectedGroup.name;
		store.local.save();
	}

	render() {
		if (this.isLoading) return null;
		if (this.error) return <div className='error'>{this.error}</div>;
		const Filter = () =>
			this.groups ? (
				<div className={`nomenclature-layout__groups`}>
					<div className={'nomenclature-title'}>Выбрать категорию</div>
					<Select
						className={'nomenclature-selector'}
						items={this.selectGroups}
						value={this.selectedGroup.name}
						isRequired={true}
						noSearch={true}
						onChange={this.onSelectGroupChange}
					/>
					<NomenclatureGroups groups={[this.selectedGroup]} listGroupId={this.listGroup.id} onChange={this.onListGroupChange} />
				</div>
			) : (
				<Loader />
			);

		return (
			<div className={`nomenclature-layout`}>
				<Switch>
					<Route path={path} exact>
						<Filter />
						<div className={`nomenclature-layout__content`}>
							<List groupId={this.listGroup.id} key={this.listGroup.id} />
						</div>
					</Route>
					<Route path={`${path}/page/:page`}>
						<Filter />
						<div className={`nomenclature-layout__content`}>
							<List groupId={this.listGroup.id} key={this.listGroup.id} />
						</div>
					</Route>
					<Route path={`${path}/:id`} children={({ match }) => <Single match={match} path={path} modelName={modelName} />} />
				</Switch>
			</div>
		);
	}
}
