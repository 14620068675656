import React from 'react';
import { observer } from 'mobx-react';

import { Avatar, NumberValue } from '@smartplatform/ui';
import { fioShort, formatDate, relative } from 'client/tools';
import t from 'i18n';

const manualFields = [
	'description',
	'comment',
	'invoiceNumber',
	'contractNumber',
	'garant'
	// 'priority',
	// 'startDate',
	// 'dueDate',
];

@observer
export default class OrderLog extends React.Component {

	constructor(props) {
		super(props);
	}

	showDelta = (e, field, index) => {
		e.preventDefault();
		this.props.onShowPopup(field, index);
	};


	render() {
		const { logs, index, canViewSum } = this.props;

		const orderLog = logs[index];

		const isUpdated = field => orderLog.updated && orderLog.updated.find(_field => _field === field);

		const updatedFields = [];
		manualFields.forEach(field => isUpdated(field) && updatedFields.push(field));

		let actions = index === 0 ? [{ title: t('order.history.created'), subjects: [] }] : [];

		if (index > 0) {
			if (updatedFields.length > 0) {
				actions.push({
					title: t('history.updated') + ':',
					subject: updatedFields.map((field, i) => {
						const onClick = e => this.showDelta(e, field, index);
						return <React.Fragment key={i}>
							<a href="#" onClick={onClick}>{t(`order.${field}`).toLowerCase()}</a>
							{updatedFields.length > 0 && i < updatedFields.length - 1 ? ', ' : ''}
						</React.Fragment>;
					}),
				});
			}

			if (orderLog.list) {
				actions.push({
					title: t('order.history.status'),
					subject: <><span className="color" style={{ background: orderLog.list.color }} />{orderLog.list.name}</>,
				});
			}

			if (orderLog.client) {
				actions.push({
					title: t('order.history.client'),
					subject: orderLog.client.name,
				});
			}

			if (isUpdated('managerId')) {
				if (orderLog.managerId === orderLog.ownerId) {
					actions.push({ title: t('order.history.appointedSelf'), subject: null });
				}
				else if (orderLog.managerId) {
					actions.push({ title: t('order.history.appointed'), subject: fioShort(orderLog.manager) });
				}
				else {
					actions.push({ title: t('order.history.appointedNull'), subject: null });
				}
			}

			if (isUpdated('paid')) {
				actions.push({
					title: t('order.history.paid'),
					subject: orderLog.paid ? t('order.history.paidYes') : t('order.history.paidNo'),
				});
			}

			if (isUpdated('priority') && !orderLog.list) {
				actions.push({
					title: t('history.priority'),
					subject: null,
				});
			}

			if (isUpdated('newPosition')) {
				actions.push({
					title: t('order.history.newPosition'),
					subject: null,
				});
			}

			if (isUpdated('deletedPosition')) {
				actions.push({
					title: t('order.history.deletedPosition'),
					subject: null,
				});
			}

			if (isUpdated('newWaybill')) {
				actions.push({
					title: t('order.history.newWaybill'),
					subject: null,
				});
			}

			if (isUpdated('deletedWaybill')) {
				actions.push({
					title: t('order.history.deletedWaybill'),
					subject: null,
				});
			}

			if (isUpdated('total')) {
				actions.push({
					title: t('order.history.total'),
					subject: canViewSum && <NumberValue value={orderLog.total} type="currency" />,
				});
			}

			if (isUpdated('invoiceDate')) {
				actions.push({
					title: t('order.history.invoiceDate'),
					subject: formatDate(orderLog.invoiceDate, 'dd.MM.yyyy'),
				});
			}

			if (isUpdated('contractDate')) {
				actions.push({
					title: t('order.history.contractDate'),
					subject: formatDate(orderLog.contractDate, 'dd.MM.yyyy'),
				});
			}

			if (isUpdated('startDate')) {
				actions.push({
					title: t('order.history.startDate'),
					subject: formatDate(orderLog.startDate, 'dd.MM.yyyy'),
				});
			}

			if (isUpdated('endDate')) {
				actions.push({
					title: t('order.history.endDate'),
					subject: formatDate(orderLog.endDate, 'dd.MM.yyyy'),
				});
			}

			if (isUpdated('prepaid')) {
				actions.push({
					title: orderLog.prepaid ? t('order.history.prepaid') : t('order.history.prepaidCancel'),
					subject: orderLog.prepaid ? <NumberValue value={orderLog.prepaid} type="currency" /> : undefined,
				});
			}
			if (isUpdated('newProcess')) {
				actions.push({
					title: t('order.history.newProcess'),
					subject: null,
				});
			}
			if (isUpdated('deletedProcess')) {
				actions.push({
					title: t('order.history.deletedProcess'),
					subject: null,
				});
			}
			if (isUpdated('newMaterial')) {
				actions.push({
					title: t('order.history.newMaterial'),
					subject: null,
				});
			}
			if (isUpdated('deletedMaterial')) {
				actions.push({
					title: t('order.history.deletedMaterial'),
					subject: null,
				});
			}
			if (isUpdated('deletedPositionAttachment')) {
            		actions.push({
            			title: t('order.history.deletedPositionAttachment'),
            			subject: orderLog.deletedPositionAttachment,
            		});
        	}
			if (isUpdated('addedPositionAttachment')) {
				actions.push({
					title: t('order.history.addedPositionAttachment'),
					subject: orderLog.addedPositionAttachment,
				});
			}
		}

		return <div className="order-log">
			<span className="who"><Avatar user={orderLog.owner} size={24} /> </span>
			<div className="what">
				<span className="subject">{fioShort(orderLog.owner)}</span>
				{actions.map((action, i) => {
					return <span className="action" key={i}>
						<> {action.title} </>
						<span className="subject">{action.subject}</span>
						{actions.length > 0 && i < actions.length - 1 ? ', ' : ''}
					</span>
				})}
				<span className="date">{relative(orderLog.createdAt)}</span>
			</div>
		</div>;
	}

}
