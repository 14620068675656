import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';

export const Handbook = ({ path, modelName, title, relation, relationLabel, searchValue }) => (
	<Switch>
		<Route
			path={path}
			exact
			children={({ match }) => (
				<List
					searchValue={searchValue}
					title={title}
					match={match}
					modelName={modelName}
					relationLabel={relationLabel}
					relation={relation}
				/>
			)}
		/>
		<Route
			path={`${path}/page/:page`}
			children={({ match }) => (
				<List title={title} match={match} modelName={modelName} relationLabel={relationLabel} relation={relation} />
			)}
		/>
		<Route
			path={`${path}/:id`}
			children={({ match }) => (
				<Edit title={title} match={match} path={path} modelName={modelName} relationLabel={relationLabel} relation={relation} />
			)}
		/>
	</Switch>
);
