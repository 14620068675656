import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';

import pStore from '../store';
import { Toolbar } from 'components';
import { formatDate, fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Summary extends React.Component {
	@observable isLoading = true;
	@observable tasksCount = null;
	@observable members = [];

	constructor(props) {
		super(props);
		if (pStore.project) this.init();
	}

	init = async () => {
		this.tasksCount = await store.model.Task.count({ projectId: pStore.project.id });
		this.members = await store.model.ProjectMember.find({
			where: {
				projectId: pStore.project.id,
				userId: { neq: null },
			},
			include: [
				{
					relation: 'user',
					scope: {
						include: [
							{
								relation: 'tasks',
								scope: {
									where: {
										projectId: pStore.project.id,
										closed: false,
									},
									fields: ['id'],
								},
							},
						],
					},
				},
				{
					relation: 'role',
					scope: {
						fields: ['id', 'name'],
					},
				},
			],
		});
		this.isLoading = false;
	};

	exportAll = async () => {
		const tasks = await store.model.ViewTask.find({
			where: {
				projectId: pStore.project.id,
			},
			include: ['user'],
			order: 'updatedAt desc',
		});
		console.log('exportAll', tasks.length);
		if (tasks.length > 0) {
			let csvContent = '#;"Номер";"Название";"Отвественный";"Обновлено"\r\n';
			tasks.forEach((task, i) => {
				const data = [
					i + 1,
					task.id,
					'"' + (task.name ? task.name.replace(/"/gm, '""') : '') + '"',
					task.user ? '"' + fio(task.user) + '"' : '',
					formatDate(task.updatedAt),
				];
				const row = data.join(';');
				csvContent += row + '\r\n';
			});
			let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([csvContent]);
			let blob = new Blob([csv], { type: 'text/csv;charset=windows-1251' });
			saveAs(blob, `${pStore.project.name}.csv`);
		}
	};

	onMemberClick = async (member) => {
		pStore.user = pStore?.user === member.user.id ? null : member.user.id;
		const id = parseInt(member.projectId);
		if (id) await pStore.load(id);
		if (pStore.kanban) {
			pStore.kanban.reload();
		}
	};

	render() {
		if (this.isLoading) return <div className='project-summary' />;

		const sortedMembers = this.members.sort((a, b) => b.user.tasks().length - a.user.tasks().length);

		return (
			<Toolbar store={this.pStore} className='toolbar-project'>
				<Toolbar.CreateBtn url={`/projects/${pStore.id}/createTask`} innerText={t('task.create').toUpperCase()} />
				<Toolbar.Count text={t('task.total')} count={this.tasksCount} />
				<Toolbar.ExportBtn onClick={this.exportAll} />
				<Toolbar.Members members={sortedMembers} onMemberClick={this.onMemberClick} selectedId={pStore?.user} />
			</Toolbar>
		);
	}
}

