import React, { Component } from 'react';
import { Column, ColorValue } from '@smartplatform/ui';
import { ModelList, Toolbar } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class List extends Component {
	@observable search = '';

	constructor(props) {
		super(props);
	}

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { modelName, path } = this.props;
		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url={store.route.path + '/create'} innerText={t('label.create')} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
				</Toolbar>
				<ModelList
					noHeader
					noFilters
					searchValue={this.search}
					model={store.model[modelName]}
					path={path}
					title={t('label.plural')}
				>
					<Column width={200} property='name' label={t('name')} />
					<Column width={50} property='color' className='text-center' label={t('color')}>
						<ColorValue />
					</Column>
					<Column width={120} property='priority' label={t('priority')} />
					<Column property='description' label={t('description')} />
				</ModelList>
			</>
		);
	}
}

