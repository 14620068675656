import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Row, Field, ImageInput, MaskedInput } from '@smartplatform/ui';
import { validateEmail } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable password = '';
	@observable password2 = '';

	constructor (props) {
		super(props);
		store.ui.title = t('user.title');
		this.init();
	}

	@action init = async () => {
		this.error = null;
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			try {
				this.record = await store.model.User.findById(this.id);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.record = new store.model.User();
		}
		this.isLoading = false;
	};

	beforeSave = user => {
		this.error = null;
		if (!user.email) {
			this.error = t('user.emptyEmail');
			return false;
		}
		else if (!validateEmail(user.email)) {
			this.error = t('user.invalidEmail');
			return false;
		}
		if (!user.id) {
			if (this.password.length === 0) {
				this.error = t('password.empty');
				return false;
			}
			else if (this.password2.length === 0) {
				this.error = t('password.repeat');
				return false;
			}
		}
		if (this.password.length > 0 || this.password2.length > 0) {
			if (this.password !== this.password2){
				this.error = t('password.noMatch');
				return false;
			}
			else {
				user.password = this.password;
			}
		}
		if (user.phone) {
			user.phone = user.phone.replace(/[^+\d]/g, '');
		}
	};

	onError = (error) => {
		console.log(error,'form error')
		if (error && error.message && error.message.includes('Email already exists')) this.error = t('user.emailAlreadyExists');
	};

	onPasswordChange = e => this.password = e.target.value;

	onPassword2Change = e => this.password2 = e.target.value;

	back = () => store.route.push({ path: '/users' });

	render() {
		if (this.isLoading) return '...';
		if (!this.record && this.error) return <div className="error">{this.error}</div>;
		console.log(this.error,'r')

		const canWrite = store.model.User.INFO.WRITE || store.model.user.id === this.record.id;

		return <div className="fixed-page">
			<Form record={this.record} stay onError={this.onError} onDelete={this.back} onSave={this.back} beforeSave={this.beforeSave}>
				<Row>
					<Field property="lastName" label={t('user.lastName')} disabled={!canWrite} />
					<Field property="firstName" label={t('user.firstName')} disabled={!canWrite} />
					<Field property="middleName" label={t('user.middleName')} disabled={!canWrite} />
				</Row>
				<Row>
					<Field property="username" label={t('user.name')} disabled={!canWrite} />
					<Field property="email" label={t('user.email')} disabled={!canWrite} />
					<div/>
				</Row>
				<Row>
					<Field property="phone" label={t('user.phone')} disabled={!canWrite}>
						<MaskedInput mask="+7 (999) 999 9999" placeholder="Телефон" />
					</Field>
					<Field property="telegram" label={t('user.telegram')} disabled={!canWrite} />
					<div />
				</Row>
				{canWrite && <Row>
					<div className="form-field">
						<label>{t('password.field')}</label>
						<input type="password" value={this.password} onChange={this.onPasswordChange} autoComplete="new-password" />
						<div><small>{t('password.newHint')}</small></div>
					</div>
					<div className="form-field">
						<label>{t('password.repeat')}</label>
						<input type="password" value={this.password2} onChange={this.onPassword2Change} autoComplete="new-password" />
					</div>
					<div />
				</Row>}
				<Field property="avatar" label={t('avatar')} disabled={!canWrite}><ImageInput width={300} height={300} /></Field>
				{this.error && <div className="error">{this.error}</div>}
			</Form>
		</div>;
	}
};
