import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import PropTypes from 'prop-types';
import { ExportButton } from '../buttons';

@observer
export class ToolbarExportBtn extends React.Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	render() {
		return <ExportButton onClick={this.props.onClick} />;
	}
}

