import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import { SaveButton, DeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class FeatureValue extends React.Component {

	@observable count = null;
	@observable priceValues = [];
	@observable orderValues = [];
	@observable changed = false;
	@observable error = null;

	constructor(props) {
		super(props);
		this.origName = props.featureValue.name || '';
		this.init();
	}

	init = async () => {
		const { featureValue } = this.props;
		if (featureValue.id) {
			const priceValues = await store.model.PricePositionValue.count({
				featureValueId: featureValue.id,
			});
			const orderValues = await store.model.OrderPositionValue.count({
				featureValueId: featureValue.id,
			});
			this.setObservables(priceValues, orderValues);
		}
	};

	@action setObservables = (priceValues, orderValues) => {
		this.priceValues = priceValues;
		this.orderValues = orderValues;
		this.count = priceValues + orderValues;
	};

	onNameChange = e => {
		this.props.featureValue.name = e.target.value;
		this.changed = this.props.featureValue.name !== this.origName;
	};

	delete = async () => {
		await this.props.featureValue.delete();
		this.props.onDelete();
	};

	save = async () => {
		const isNew = !this.props.featureValue.id;
		await this.props.featureValue.save();
		this.origName = this.props.featureValue.name;
		this.changed = false;
		if (isNew) this.props.onCreate();
	};

	render() {
		const { featureValue, index, onCancel } = this.props;

		const usages = [];
		if (this.priceValues > 0) usages.push(<span key={0}>позиций прайс-листа: <strong>{this.priceValues}</strong></span>);
		if (this.priceValues > 0 && this.orderValues > 0) usages.push(<span key={1}>, </span>);
		if (this.orderValues > 0) usages.push(<span key={2}>позиций заказов: <strong>{this.orderValues}</strong></span>);

		return <tr>
			<td width={50}>{index + 1}</td>
			<td>
				<input
					type="text"
					value={featureValue.name || ''}
					autoFocus={!featureValue.id}
					onChange={this.onNameChange}
					// disabled={this.count === null || this.count > 0}
				/>
			</td>
			<td className="actions">
				{this.changed && <SaveButton onClick={this.save} />}
				{(this.count === 0 && !!featureValue.id) && <DeleteButton onConfirm={this.delete} />}
				{!featureValue.id && <Button onClick={onCancel} size={36} fontSize={14}>Отмена</Button>}
			</td>
			<td className="info">
				{this.count > 0 ? usages : null}
			</td>
		</tr>;
	}
	
}
