import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toolbar, Mode } from 'components';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export default class ProjectsToolbar extends React.Component {
	render() {
		const { mode, onModeChange, onSearch, onChange, count, search, user } = this.props.store;
		return (
			<div className='projects-toolbar'>
				<Toolbar>
					<Toolbar.CreateBtn url='/projects/create' innerText={t('project.create').toUpperCase()} />
					<Toolbar.Search inputValue={search} onSearch={onSearch} />
					<Toolbar.User user={user} onChange={onChange('user')} />
					<Toolbar.Count count={count} text={t('project.total')} />
				</Toolbar>
				<Mode mode={mode} onChange={onModeChange}>
					<Mode.List />
					<Mode.Kanban />
				</Mode>
			</div>
		);
	}
}

