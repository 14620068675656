import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@observer
export class ToolbarSearch extends React.Component {
	static propTypes = {
		content: PropTypes.any,
		onSearch: PropTypes.func.isRequired,
		inputValue: PropTypes.any,
		inputPlaceholder: PropTypes.any,
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		inputPlaceholder: t('document.search'),
	};

	render() {
		const { content, className, inputPlaceholder, inputValue, onSearch } = this.props;

		const input = (
			<>
				<input value={inputValue} onChange={onSearch} placeholder={inputPlaceholder} />
				<span className='icon'>
					<FontAwesomeIcon icon={faSearch} />
				</span>
			</>
		);

		const _className = classNames('toolbar-search', className);

		return content ? (
			<Popover content={content} contentClassName='toolbar-filter-popup' position='bottom' trigger='click' className={_className}>
				{input}
			</Popover>
		) : (
			<div className={_className}>{input}</div>
		);
	}
}

