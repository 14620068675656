import { Field, Row } from '@smartplatform/ui';
import store from 'client/store';
import { ModelEdit } from 'components';
import t from 'i18n';
import React from 'react';
import { observable } from "mobx";
import { observer } from "mobx-react";
import Values from "../Values";
import { checkModelAccess } from 'client/tools/checkModelAccess';

const FILTER = {
    include: ['operations', 'equipments']
}
@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable error = false;

	constructor(props) {
		super(props)
		this.error = checkModelAccess(this.props.modelName);
	}

    getRecord = async record => {
        this.record = record;
        if (!record.operations) {
            this.record.operations = [];
        }
        if (!record.equipment) {
            this.record.equipments = [];
        }
    };

    render() {
		if (this.error) return <div className="error">{this.error}</div>;
		const {modelName, path, match, title} = this.props;
        const id = parseInt(match.params.id);
        return <ModelEdit model={store.model[modelName]} stay getRecord={this.getRecord} filter={FILTER} path={path}
                          id={id}
                          title={title}>
            <Field property="name" label={t('name')}/>
            {this.record && this.record.id ?
                <Row>
                    <div className="form-field">
                        <label>{t('process.operation.plural')}: </label>
                        <Values record={this.record} relation={'operations'} modelName={'ProcessOperation'}
                                foreignKey={'workshopId'}/>
                    </div>
                    <div className="form-field">
                        <label>{t('process.equipment')}: </label>
                        <Values record={this.record} relation={'equipments'} modelName={'ProcessEquipment'}
                                foreignKey={'workshopId'}/>
                    </div>
                </Row>
                : <div className='workshop-warning'>{t('process.workshop.new')}</div>}
        </ModelEdit>;
    }
};