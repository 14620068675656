import React, { Component } from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import t from 'i18n';
import { Toolbar } from 'components';
import { tabs, path } from './tabs';

import store from 'client/store';

import './style.scss';

@observer
export class TechnicalRoadmap extends Component {
	@observable search = '';

	resetSearch = () => {
		this.search = '';
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		return (
			<>
				<Route path={path} exact>
					<Redirect to={tabs[0].path} />
				</Route>
				<Toolbar className='toolbar-techmap'>
					<Toolbar.CreateBtn url={store.route.path + '/create'} innerText={`${t('add')} ${store.ui.title}`} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
				</Toolbar>
				<Tabs className='technicalroadmap-tabs'>
					{tabs.map(({ Component, modelName, title, path, iconTitle, relation, relationLabel }, i) => {
						return (
							<Tab path={path} title={iconTitle} onClick={this.resetSearch} key={i}>
								<Component
									searchValue={this.search}
									path={path}
									modelName={modelName}
									title={title}
									relationLabel={relationLabel || null}
									relation={relation || null}
								/>
							</Tab>
						);
					})}
				</Tabs>
			</>
		);
	}
}

