import React, { useEffect, useState } from "react";
import store from 'client/store';
import { Table, Input, Button, Row, Column, DateValue } from '@smartplatform/ui';
import { FontAwesomeIcon,  } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { Popconfirm } from '@smartplatform/ui';

const Versions = props => {
    const [ version, setVersion] = useState({
        id: null,
        date: new Date(),
        number: '',
        comment: ''
    });
    const [ records, setRecords ] = useState();

    const init = async () => {
        try {
            const data = await store.model.EdVersion.find();
            setRecords(data);
        } catch(e) {
            console.log(e);
        }
    }
    const onVersionChange = (prop, val) => {
        console.log(version.number);
        setVersion(prev => ({
            ...prev,
            [prop]: val
        }))
    }
    const saveVersion = async () => {
        if(version.id) {
            await store.model.EdVersion.replaceById(version.id, {
                ...version,
                date: new Date()
            });
        } else {
            await store.model.EdVersion.create({
                ...version,
                date: new Date()
            });
        }
        await init();
        setVersion({
            id: null,
            date: new Date(),
            number: '',
            comment: ''
        });
    }
    const deleteVersion = async () => {
        if(version.id){
            await store.model.EdVersion.deleteById(version.id);
            await init();
            setVersion({
                id: null,
                date: new Date(),
                number: '',
                comment: ''
            });
        }
    }
    useEffect(() => {
        init();
    }, []);
    return(
        <Row>
            <div>
                <div className="d-flex align-items-end w-100 position-relative">
                    <div className="form-field mr-2">
                        <label>№ версии</label>
                        <Input onChange={val => onVersionChange("number", val)} value={version.number} style={{ width: "80px" }}/>
                    </div>
                    <div className="form-field mr-2" >
                        <label style={{ width: "120px" }}>Дата обновления</label>
                        <DateValue value={version.date} disabled/>
                    </div>
                    <div className="form-field mr-2 w-100">
                        <label>Комментарий ( почему добавляется? )</label>
                        <Input onChange={val => onVersionChange("comment", val)} value={version.comment}/>
                    </div>
                    <div className="form-field">
                        <Button disabled={!(version.number && version.comment)} onClick={saveVersion}>
                            {version.id ? 'Сохранить' : 'Добавить'}
                        </Button>
                    </div>
                    {
                        version.id ?
                            <div className="delete-ver"> 
                            <Popconfirm content="Удалить версию?" onConfirm={deleteVersion}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Popconfirm>
                        </div> :
                        ''
                    }
                </div>
                <Table className="mb-3 mt-1" rows={records} onRowClick={val => setVersion({
                    id: val.id,
                    number: val.number,
                    date: val.date,
                    comment: val.comment
                })}>
                    <Column label="№ версии" property="number" width={60}/>
                    <Column label="Дата обновления" property="date" width={110}/>
                    <Column label="Комментарий" property="comment"/>
                </Table>  
            </div>
            <div></div>
        </Row>
    );
}

export default Versions;