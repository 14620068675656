import React, { Component } from 'react';
import { ColorValue, Column } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { ModelList, Toolbar } from 'components';
import store from 'client/store';

@observer
export default class List extends Component {
	@observable count = null;
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model[this.props.modelName].count();
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { modelName, path } = this.props;
		const renderRoles = ({ roleNames }) => roleNames && roleNames.map((role, i) => <div key={i}>{role}</div>);

		return (
			<>
				<Toolbar className='toolbar-page'>
					<Toolbar.CreateBtn url='/orderlists/create' innerText={t('states.new')} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
					<Toolbar.Count text={t('total')} count={this.count} />
				</Toolbar>
				<ModelList
					noHeader
					noFilters
					model={store.model[modelName]}
					path={path}
					searchValue={this.search}
					title={t('orderList.plural')}
					order='priority desc'
				>
					<Column width={200} property='name' label={t('name')} />
					<Column width={50} property='color' className='text-center' label={t('color')}>
						<ColorValue />
					</Column>
					<Column property='priority' label={t('priority')} className='text-center' />
					<Column width={120} property='default' label={t('orderList.default')} className='text-center' />
					<Column width={120} property='closing' label={t('orderList.closing')} className='text-center' />
					<Column property='description' label={t('description')} />
					<Column property='roleNames' computed={renderRoles} label={t('orderList.roleNames')} />
				</ModelList>
			</>
		);
	}
}

