import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { Button, RecordSelect } from '@smartplatform/ui';
import { DeleteButton } from 'components';
import Members from './members/Members';
import Payments from './payments/Payments';
import { percentLeft } from '.';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@withRouter @observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable error = null;
	@observable nameError = null;
	@observable editComment = false;
	
	constructor(props) {
		super(props);
		store.ui.title = t('budget.title');
		this.record = new store.model.Budget();
		this.record.name = '';
	}
	
	onNameChange = e => {
		this.nameError = null;
		this.nameChanged = true;
		this.record.name = e.target.value;
	}
	
	onNameBlur = async e => {
		if (this.record.name.trim().length === 0) {
			this.nameError = t('budget.emptyName');
			e.target.focus();
		}
	};
	
	onProjectChange = record => {
		this.record.project = record;
	};
	
	save = async () => {
		await this.record.save();
		// store.route.push({ path: `/budgets/${this.record.id}` });
		this.props.history.push(`/budgets/${this.record.id}`);
	};
	
	render() {
		return <div className="budget-edit create">
			<div className="name">
				<input
					className={this.nameError ? 'error' : ''}
					value={this.record.name}
					onChange={this.onNameChange}
					onBlur={this.onNameBlur}
					placeholder={t('budget.name')}
					autoFocus
				/>
				<div className="error">{this.nameError}</div>
			</div>
			<div className="form-field project">
				<label>{t('project.title')}</label>
				<RecordSelect
					model={store.model.Project}
					property="name"
					value={this.record.project}
					onChange={this.onProjectChange}
					isRequired
				/>
			</div>
			<Button
				variant="primary"
				onClick={this.save}
			    disabled={this.record.name.trim().length === 0 || !this.record.projectId}>
				{t('create')}
			</Button>
		</div>;
	}
	
}
