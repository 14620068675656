import React from 'react';
import t from 'i18n';
import classNames from 'classnames';

import './style.scss';

export { default as AddButton } from './AddButton';
export { default as SaveButton } from './SaveButton';
export { default as DeleteButton } from './DeleteButton';
export { default as SmallDeleteButton } from './SmallDeleteButton';
export { default as RefreshButton } from './RefreshButton';
export { default as CreateButton } from './CreateButton';

import ImportIcon from '!!svg-react-loader!client/img/icons/import.svg';
import ExportIcon from '!!svg-react-loader!client/img/icons/export.svg';
import ChartIcon from '!!svg-react-loader!client/img/icons/chart.svg';
import SearchIcon from '!!svg-react-loader!client/img/icons/search.svg';

import { IconButton } from './IconButton';

const makeButton =
	({ className, ...rest }) =>
	(props) =>
		<IconButton {...rest} {...props} className={classNames(props.className, className)} />;

export const CancelButton = makeButton({ className: 'transparent', text: t(t('cancel')) });
export const CleanButton = makeButton({ className: 'transparent', text: t(t('clean')) });
export const ExportButton = makeButton({ icon: <ExportIcon />, className: 'transparent', text: t('export') });
export const ImportButton = makeButton({ icon: <ImportIcon />, className: 'transparent', text: t('import') });
export const ChartButton = makeButton({ icon: <ChartIcon />, className: 'transparent' });
export const SearchButton = makeButton({ icon: <SearchIcon />, className: 'transparent' });

