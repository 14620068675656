import { Column, Pager, Table } from '@smartplatform/ui';
import React from 'react';
import appStore from 'client/store';
import t from 'i18n';
import { COSTS, INCOME, PER_PAGE, TOTAL_COLORS, TRANSFER } from '../constants';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { renderMoney } from 'client/tools';
import { renderBankAccount } from 'client/tools';

@inject('store')
@observer
export class List extends React.Component {
	renderTotal = ({ paymentType, total, currencyName }) => {
		const { color, prependSymbol } = TOTAL_COLORS[paymentType] || TOTAL_COLORS['default'];
		return (
			<span style={{ color }}>
				{total ? prependSymbol : ''}
				{renderMoney(total, 2)}
				{/* {currencyName} */}
			</span>
		);
	};

	renderCashFlow = ({ paymentType, cashFlowName }) => (paymentType === TRANSFER ? t('payment.transferCashFlow') : cashFlowName);
	renderContragent = ({ recipient, payer, paymentType, payerBankAccount, recipientBankAccount }) => {
		if ([INCOME, COSTS].includes(paymentType)) {
			const isContragentPayer = !payer.myCompany;
			const contragent = isContragentPayer ? payer : recipient;
			const bankAccount = isContragentPayer ? payerBankAccount : recipientBankAccount;
			return (
				<div>
					<div>{contragent.name}</div>
					<div className='bankAccount'>{renderBankAccount(bankAccount)}</div>
				</div>
			);
		}
	};
	renderBankAccount = ({ paymentType, payerBankAccount, recipientBankAccount }) => (
		<div>
			{[TRANSFER, COSTS].includes(paymentType) && renderBankAccount(payerBankAccount)}
			{[TRANSFER, INCOME].includes(paymentType) && renderBankAccount(recipientBankAccount)}
		</div>
	);

	render() {
		const { onChange, query, page, onQueryUpdate, onRowClick, queryFilter, getReloadFunc } = this.props.store;
		return (
			<div className='table'>
				<Pager current={page} totalCount={query._totalCount || 0} onChange={onChange('page')} itemsPerPage={PER_PAGE} />
				<Table
					model={appStore.model.ViewPayment}
					query={queryFilter}
					onQueryUpdate={onQueryUpdate}
					getInstance={getReloadFunc}
					onRowClick={onRowClick}
				>
					<Column property='date' label={t('date')} width={100} />
					<Column property='total' label={t('payment.total')} computed={this.renderTotal} width={100} />
					<Column computed={this.renderBankAccount} label={t('payment.bankAccount')} />
					<Column computed={this.renderCashFlow} label={t('cashFlow.shortTitle')} />
					<Column computed={this.renderContragent} label={t('contragent.title')} />
					<Column relation='project' property='name' label={t('project.title')} />
					<Column relation='direction' property='name' label={t('payment.direction')} />
				</Table>
				{query._totalCount === 0 && <span className='not-found'>{t('operation.notFound')}</span>}
			</div>
		);
	}
}
