import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Tabs, Tab } from '@smartplatform/ui';
import './style.scss';

@observer
export default class TabsTest extends React.Component {

	@observable tab = 'two';

	switchTab = tab => {
		console.log('switchTab', tab);
		this.tab = tab;
	};

	render() {
		return <div className="tabs-test">
			<div>
				<Tabs>
					<Tab path="/test/tabs" title="tab number one" exact>content one</Tab>
					<Tab path="/test/tabs/two" title="tab number two" badge={2}>content two</Tab>
					<Tab path="/test/tabs/three" title="tab number three" badge={3}>content three</Tab>
				</Tabs>
			</div>
			<br />
			<div>
				<Tabs type="manual">
					<Tab onClick={() => this.switchTab('one')} title="tab number one" />
					<Tab initial onClick={() => this.switchTab('two')} title="tab number two" />
					<Tab onClick={() => this.switchTab('three')} title="tab number three" />
				</Tabs>
				<div>{this.tab}</div>
			</div>
		</div>;
	}

}
