import React from 'react';
import { observable } from 'mobx';
import { addDays } from 'date-fns';
import { observer } from 'mobx-react';

import { DatePicker, Button } from '@smartplatform/ui';
import './style.scss';

@observer
export default class DatePickerTest extends React.Component {

	@observable date = new Date();

	onChange = (value) => this.date = value;
	
	reset = () => this.date = null;
	
	newDate = () => this.date = this.date ? addDays(this.date, 1) : new Date();
	
	render() {
		return <div>
			<DatePicker value={this.date} onChange={this.onChange} />
			<Button onClick={this.reset}>reset</Button>
			<Button onClick={this.newDate}>new date</Button>
		</div>;
	}

}
