import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	return <ModelList model={store.model[modelName]} path={path} match={match} title={t('user.plural')}>
		<Column width={70} property="id" label={t('id')} />
		<Column property="lastName" label={t('user.lastName')} />
		<Column property="firstName" label={t('user.firstName')} />
		<Column property="middleName" label={t('user.middleName')} />
		<Column property="username" label={t('user.name')} />
		<Column property='phone' label={t('user.phone')} />
		<Column property='email' label={t('user.email')} />
	</ModelList>;
};
