import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Table, Column, Loader } from '@smartplatform/ui';
import { AddButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import EditWaybill from './EditWaybill';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@inject('store') @observer
export default class Waybill extends React.Component {

	@observable positions = [];
	@observable rows = null;
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.store = props.store;
		this.order = this.store.order;
        this.state = {
            showEditPopup: false,
			editRecord: null,
			editIndex: null
        };
		this.openEditPopup = this.openEditPopup.bind(this);
		this.closeEditPopup = this.closeEditPopup.bind(this);
		this.onSave = this.onSave.bind(this);
		this.init();
	}

	init = async () => {
        this.rows = await store.model.Waybill.find({
            where: { orderId: this.order.id },
			include: {
				relation: 'nomenclature',
				scope: {
					include: {
						relation: 'balance',
						scope: {
							include: [ 'debit', 'credit' ]
						}
					}
				}
			}
        });
        this.isLoading = false;
    };

    closeEditPopup() {
        this.setState({
			editRecord: null,
            showEditPopup: false,
			editIndex: null
        });
		this.init()
    }
    openEditPopup() {
        this.setState({
            showEditPopup: true
        });
    }
	editRow = (record, i) => {
		this.setState(prev => ({
			...prev,
			editRecord: record,
			showEditPopup: true,
			editIndex: i
		}));
	}
	onSave(record) {
		if(this.state.editIndex === null) {
			this.setState(prev => ({
				...prev,
				editIndex: this.rows.push(record) - 1
			}));
		} else {
			this.rows[this.state.editIndex] = record;
		}
		this.closeEditPopup();
	}
	onDelete = () => {
        const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.updated = ['deletedWaybill'];
		orderLog.save();
        this.closeEditPopup();
		this.init();
    }
	render() {
		if(this.isLoading) return <Loader />
		return(
			<div className="order-positions fixed-page">
				<Table rows={this.rows} onRowClick={this.editRow}>
					<Column label={t('number')} width={50}>
						{ data => data.row + 1 }
					</Column>
					<Column
						relation="nomenclature"
						property="name"
						label={t('order.waybill.name')}
						width={300}
					/>
					<Column property="count"   label={t('order.waybill.count')}   />
					<Column property="gaveOut" label={t('order.waybill.gaveOut')} width={60} />
					<Column property="defect"  label={t('order.waybill.defect')}  width={60} />
					<Column property="return"  label={t('order.waybill.return')}  width={60} />
				</Table>
				<div className="waybill-table-footer">
					<AddButton onClick={this.openEditPopup}/>
					<div className="add-row">
						<a className="add-btn" href={`/api/waybills/getPdfInvoice?order_id=${this.order.id}`}>
							<FontAwesomeIcon icon={faPrint} color="#33CE65"/>
						</a>
					</div>
				</div>
				{
					this.state.showEditPopup &&
					<EditWaybill
						onClose={this.closeEditPopup}
						record={this.state.editRecord}
						order={this.order}
						onSave={this.onSave}
						onDelete={this.onDelete}
					/>
				}
				{/* {this.selectedPosition && <PositionEdit positionId={this.selectedPosition.id} onClose={this.closeEditPopup} />} */}
			</div>
		);
	}

}
