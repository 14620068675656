import React from 'react';
import { Filters } from './Filters';
import { ActionBar } from './ActionBar';
export class Toolbar extends React.Component {
	render() {
		return (
			<div className='toolbar'>
				<Filters />
				<ActionBar />
			</div>
		);
	}
}
