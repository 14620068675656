import React from 'react';
import List from './List';
import Edit from './Edit';
import { Route, Switch } from "react-router-dom";

const modelName = 'BankAccount';

export default class Bank extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const path = this.props?.match?.url;
		const contragentId = parseInt(this.props?.match?.params?.contragentId);
		const tabProps = {
			contragentId,
			modelName,
			path
		}
		return (
			<Switch>
				<Route path={path} exact children={({ match }) => <List {...tabProps} match={match} />}/>
				<Route path={`${path}/create`} exact children={({ match }) => <Edit {...tabProps} match={match} />} />
				<Route path={`${path}/:id`} exact children={({ match }) => <Edit {...tabProps} match={match} />} />
			</Switch>
		);
	}

}