import React from 'react';
import { Column } from '@smartplatform/ui';
import { ModelList, Toolbar, DeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export default class extends React.Component {
	@observable count = null;
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.count = await store.model[this.props.modelName].count({
			contragentId: this.props.contragentId,
		});
	};

	onCreate = () => {
		store.route.push({
			path: `${this.props?.match?.url}/create`,
		});
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	onDelete = async (record) => {
		await record.delete();
		this.tableInstance.reload();
		this.init();
	};

	renderActions = ({ record }) => <DeleteButton onConfirm={() => this.onDelete(record)} />;

	getInstance = (instance) => (this.tableInstance = instance);

	render() {
		return (
			<div>
				<Toolbar>
					<Toolbar.CreateBtn onClick={this.onCreate} />
					<Toolbar.Search inputValue={this.search} onSearch={this.onSearch} />
					<Toolbar.Count count={this.count} text={t('total')} />
				</Toolbar>
				<ModelList
					noHeader
					noFilters
					model={store.model[this.props.modelName]}
					searchValue={this.search}
					title={t('contragents.card.tabs.bank')}
					filter={{
						where: { contragentId: this.props.contragentId },
						include: ['currency'],
					}}
					getInstance={this.getInstance}
				>
					<Column property='bank' label={t('bankAccount.bank')} />
					<Column property='bic' label={t('bankAccount.bik')} />
					<Column property='checkingAccount' label={t('bankAccount.checkingAccount')} />
					<Column relation='currency' property='name' label={t('currency.title')} />
					<Column>{this.renderActions}</Column>
				</ModelList>
			</div>
		);
	}
}

