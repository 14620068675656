import { action, observable } from 'mobx';
import store from 'client/store';
import { HEADER_FIELDS, ORDER_INCLUDE, REPORT_FIELDS } from './constants';



export default class ReportStore {

	@observable query = {};
	@observable totalCount = 0;
	@observable records = null;
	@observable isLoading = false;
	@observable editMode = false;
	@observable selectedIds = {};
	@observable isToggleAllIds = false;
	@observable selectedColumns = {};
	@observable limit = 100;
	headerFields = HEADER_FIELDS;

	constructor(store) {
		this.store = store;
		this.store.reload = this.reload;
		this.query = {
			where: this.store.compileFilter(this.store.availableListIds),
			include: ORDER_INCLUDE,
			fields: REPORT_FIELDS,
			order: 'id desc',
			limit: this.limit
		};
	}

	@action init = async () => {
		this.isLoading = true;
		this.editMode = false;
		this.selectedIds = {};
		try {
			this.query.limit = this.limit ? this.limit : undefined;
			this.records = await store.model.ViewOrder.find(this.query);
			this.selectedRecords = this.records;
		} catch (e) {
			console.error('Fetching orders error:', e)
		}
		this.isLoading = false;
	}

	reload = () => {
		this.query = {
			...this.query,
			where: this.store.compileFilter(),
			skip: 0,
		};
		this.init()
	};

	refresh = () => {
		this.headerFields = HEADER_FIELDS;
		this.init();
	};

	downloadReport = ({ id, name }) => {
		const htmlTable = document.getElementById(id);
		if (htmlTable) {
			const html = htmlTable.outerHTML;
			const downloadLink = document.createElement('a');
			downloadLink.href = 'data:application/vnd.ms-excel, ' + '\uFEFF' + encodeURIComponent(html);
			downloadLink.download = `${name}.xls`;
			downloadLink.click();
		}
	};

	// функция редактирования строк
	onToggleRowCheckbox = (id) => {
		this.selectedIds[id] = !this.selectedIds[id];
		this.selectedIds = { ...this.selectedIds };
	};
	onEditModeChange = (boolean) => {
		this.editMode = boolean;
	};
	onEditStart = () => {
		this.isToggleAllIds = true;
		this.selectedColumns = {};
		this.prevSelectedIds = { ...this.selectedIds }
		this.onEditModeChange(true);
	};
	onEditFinish = () => {
		this.records = this.records.filter(rec => !this.selectedIds[rec.id]);
		this.headerFields = this.headerFields.map((rec, i) => !this.selectedColumns[i] ? rec : null);
		this.onEditModeChange(false);
	};
	onEditCancel = () => {
		this.selectedIds = { ...this.prevSelectedIds };
		this.onEditModeChange(false);
	};
	onToggleAllIds = (boolean) => {
		if (boolean) {
			this.selectedIds = {}
		}
		else this.records.forEach(r => this.selectedIds[r.id] = true);
		this.isToggleAllIds = boolean;
	};

	onToggleColumnCheckbox = (index) => {
		this.selectedColumns[index] = !this.selectedColumns[index];
		this.selectedColumns = { ...this.selectedColumns };
	};

	onLimitChange = (limit) => {
		this.limit = limit;
		this.init();
	}
}