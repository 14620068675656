import { Field, Form, Popup } from '@smartplatform/ui';
import t from 'i18n';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';

@observer
export class EditBankAccountPopup extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isNew = !this.props.record;
		if (this.isNew) {
			this.record = new store.model.BankAccount({ contragentId: this.props.contragentId });
		} else {
			this.record = new store.model.BankAccount(this.props.record);
		}
	};

	render() {
		const { onClose } = this.props;
		const onAction = () => onClose(true);
		const header = (this.isNew ? t('add') : t('edit')) + ` ${t('bankAccount.title').toLowerCase()}`;
		return (
			<Popup className='edit-material-popup' onClose={onClose} header={header} width={400}>
				<Form record={this.record} stay onSave={onAction} onDelete={onAction}>
					<Field property='name' label={t('name')} />
					<Field property='bank' label={t('bankAccount.bank')} />
					<Field property='checkingAccount' label={t('bankAccount.checkingAccount')} />
				</Form>
			</Popup>
		);
	}
}

