import React from 'react';
import Processes from './processes';
import Materials from './materials'

export const TechnologyRoadmap = () => {
	return <div className="technicaldepartment-page">
		<Processes/>
		<Materials/>
	</div>;
}
