import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Avatar } from '@smartplatform/ui';
import { formatDate, fioShort } from 'client/tools';
import t from 'i18n';

const user = user => <><Avatar user={user} size={18} /> {fioShort(user)}</>;

@observer
export default class Popup extends React.Component {

	@observable index = 0;
	
	constructor(props) {
		super(props);
		this.index = props.index;
	}
	
	componentDidMount() {
		document.addEventListener('keydown', this.onKeyDown);
	}
	
	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyDown);
	}
	
	onKeyDown = e => {
		e.keyCode === 27 && this.props.onClose();
	}
	
	goto = index => this.index = index;
	
	processText = text => (text || '').replace(/[\r\n]/g, '<br />');
	
	render() {
		const { order, logs, field, onClose } = this.props;
		
		const index = this.index;
		
		let prev = null;
		let prevIndex = null;
		for (let i = index - 1; i >= 0; i--) {
			if (logs[i][field]) {
				prev = logs[i];
				prevIndex = i;
				break;
			}
		}
		// if (!prev) prev = order;
		
		let nextIndex = null;
		if (index < logs.length - 1) {
			for (let i = index + 1; i < logs.length; i++) {
				if (logs[i][field]) {
					nextIndex = i;
					break;
				}
			}
		}
		
		const current = logs[index];
		const prevUpdater = prev ? prev.owner : logs[0].owner;
		
		return <div className="log-popup" onClick={onClose}>
			<div className="log-popup-content" onClick={e => e.stopPropagation()}>
				<div className="left">
					<div className="field">
						<FontAwesomeIcon icon={faChevronLeft} className={'prev-btn' + (prevIndex ? '' : ' disabled')} onClick={() => this.goto(prevIndex)} />
						<div className="info"><strong>{t(`order.${field}`)}</strong>, {prevIndex > 0 ? t('history.prev') : t('history.initial')} </div>
						<div className="owner">{user(prevUpdater)} <em>{formatDate(prev ? prev.createdAt : order.createdAt, 'dd.MM.yyyy HH:mm')}</em></div>
					</div>
					<div className={'log-content' + (prev ? '' : ' empty')} dangerouslySetInnerHTML={{ __html: this.processText(prev ? prev[field] : t('history.empty')) }}/>
				</div>
				<div className="right">
					<div className="field">
						<div className="info"><strong>{t(`order.${field}`)}</strong>, {t('history.updatedTo')}</div>
						<div className="owner">{user(current.owner)} <em>{formatDate(prev ? prev.createdAt : order.createdAt, 'dd.MM.yyyy HH:mm')}</em></div>
						<FontAwesomeIcon icon={faChevronRight} className={'next-btn' + (nextIndex ? '' : ' disabled')} onClick={() => this.goto(nextIndex)} />
					</div>
					<div className={'log-content' + (current[field] ? '' : ' empty')} dangerouslySetInnerHTML={{ __html: this.processText(current[field] || t('history.nulled')) }}/>
				</div>
			</div>
		</div>;
	}
	
}
