import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import t from '../../../../../../i18n';
import EditMaterial from './EditMaterial';
import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import { Column, Table } from '@smartplatform/ui';
import { DeleteButton } from 'components';

@inject('store')
@observer
export default class Materials extends React.Component {
	@observable editPopup = false;
	@observable materials = [];
	@observable selectedMaterial = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.order = this.props.store.order;
		this.init();
	}

	init = async () => {
		this.materials = await this.order.materials.find({
			include: [
				'workshop',
				{ relation: 'material', scope: { include: ['group'] } },
				{ relation: 'position', scope: { include: ['category'] } },
			],
		});
		this.isLoading = false;
	};

	deletedMaterial = async (materialLog) => {
		await materialLog.delete();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.updated = ['deletedMaterial'];
		await orderLog.save();
		await this.order.save();
		await this.init();
	};

	closeEditPopup = async () => {
		this.editPopup = false;
		this.selectedMaterial = null;
		await this.init();
	};

	openEditPopup = async () => {
		this.editPopup = true;
	};

	onRowClick = (material) => {
		this.selectedMaterial = material;
		this.openEditPopup();
	};

	renderActions = (rowData) => {
		const { record } = rowData;
		return <DeleteButton onConfirm={() => this.deletedMaterial(record)} />;
	};

	renderCategory = (position) => position.category && position.category.name;

	render() {
		if (this.isLoading) return null;
		return (
			<div className='materials-section'>
				<div className='form-field'>
					<label>{t('material.plural')}: </label>
				</div>
				{this.materials.length > 0 && (
					<div className='materials-list'>
						<Table multiline rows={this.materials} onRowClick={this.onRowClick}>
							<Column relation='position' computed={this.renderCategory} label={t('material.position')} />
							<Column relation='workshop' property='name' label={t('material.workshop')} />
							<Column relation='material' property='name' label={t('material.title')} />
							<Column property='feature' label={t('material.feature')} />
							<Column property='format' label={t('material.format')} />
							<Column property='units' label={t('material.units')} />
							<Column property='copiesNumber' label={t('material.copiesNumber')} />
							<Column property='adjustmentsNumber' label={t('material.adjustmentsNumber')} />
							<Column property='totalNumber' label={t('material.totalNumber')} />
							<Column width={40}>{this.renderActions}</Column>
						</Table>
					</div>
				)}
				<div className='add-row'>
					<AddIcon onClick={this.openEditPopup} className='add-btn'>
						add
					</AddIcon>
				</div>
				{this.editPopup && <EditMaterial onClose={this.closeEditPopup} record={this.selectedMaterial} orderId={this.order.id} />}
			</div>
		);
	}
}
