import React from 'react';
import { observer } from 'mobx-react';
import logo from 'client/img/sp-logo.svg';
import { Link } from 'react-router-dom';
import t from 'i18n';
import { observable } from 'mobx';
import { Button } from '@smartplatform/ui';

@observer
export default class Restore extends React.Component {
	@observable email = '';

	onEmailChange = (e) => {
		this.email = e.target.value;
	};

	render() {
		const yearNow = new Date().getFullYear();

		return (
			<div className='auth-layout'>
				<div className='auth-form login'>
					<div className='title'>
						<div className='logo'>
							<img src={logo} alt='' />
						</div>
						<div className='name'>
							<Link to='/'>
								Smart<strong>ERP</strong>
							</Link>
							<small className='version'>
								{VERSION} ({BUILD})
							</small>
						</div>
					</div>
					<h1>{t('auth.restore')}</h1>
					<div className='field'>
						<label>{t('user.email')}</label>
						<input type='text' name='email' placeholder='Введите E-mail' value={this.email} onChange={this.onEmailChange} />
					</div>
					<div className='submit'>
						<Button variant='primary'>{t('auth.next')}</Button>
					</div>
					<div className='copyright'>© 2015-{yearNow} ООО «Смарт Юнит»</div>
				</div>
			</div>
		);
	}
}
