import React from 'react';
import { Filters } from './Filters';

export class Toolbar extends React.Component {
	render() {
		return (
			<div className='toolbar'>
				<Filters />
			</div>
		);
	}
}
