import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { Button, NumberInput, RecordSelect, Select, DatePicker } from '@smartplatform/ui';
import CreateButton from '../../../components/buttons/CreateButton';
import ModeTiles from '!!svg-react-loader!client/img/mode-tiles.svg';
import ModeList from '!!svg-react-loader!client/img/mode-list.svg';
import ModeKanban from '!!svg-react-loader!client/img/mode-kanban.svg';
import { ORDERS_MODE_LIST, ORDERS_MODE_TILES, ORDERS_MODE_KANBAN, ORDERS_MODE_REPORT } from '../Orders';
import store from 'client/store';
import { renderList } from '..';
import t from 'i18n';
import './style.scss';
import { DEFAULT_PRIORITY } from '../kanban/OrdersKanban';

@inject('store')
@observer
export default class Toolbar extends React.Component {
	@observable edit = false;
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	switch = (e, mode) => {
		console.log('switch', mode);
		e.preventDefault();
		store.local.ordersMode = mode;
		store.local.save();
	};
	create = async () => {
		const order = new store.model.Order();
		const orders = this.store.list.orders() || [];
		order.priority = orders[0] && orders[0].priority ? orders[0].priority * 2 : DEFAULT_PRIORITY;
		order.listId = this.store.list.id;
		order.description = this.store.description;
		await order.save();
		store.route.push({ path: `/orders/${order.id}` });
	};
	toggleEdit = (boolean) => {
		if (!boolean) this.store.clearOrderData();
		this.edit = boolean;
	};

	render() {
		const isReportMode = this.props.mode === ORDERS_MODE_REPORT;
		const filtersEmpty = this.store.areFiltersEmpty();
		const { canViewSum } = this.props.store;
		return (
			<div className='orders-toolbar'>
				<div className='filters'>
					<div className='filters-row'>
						<input
							type='text'
							className='text-search'
							value={this.store.search}
							onChange={this.store.onSearch}
							placeholder={t('order.searchHint')}
						/>
						{canViewSum && (
							<NumberInput
								positiveOnly
								value={this.store.price0}
								onChange={this.store.onPrice0Change}
								className='price'
								placeholder={t('order.priceFrom')}
							/>
						)}
						{canViewSum && (
							<NumberInput
								positiveOnly
								value={this.store.price1}
								onChange={this.store.onPrice1Change}
								className='price'
								placeholder={t('order.priceTo')}
							/>
						)}
						<RecordSelect
							model={store.model.OrderList}
							value={this.store.orderList}
							onChange={this.store.onOrderListChange}
							computed={renderList}
							filter={{ where: this.store.canViewSum ? undefined : { id: { inq: this.store.availableListIds } } }}
							placeholder={t('order.status')}
							searchPlaceholder={t('search')}
							// empty={t('all')}
						/>
						<Select
							items={[
								// { label: t('all'), value: ALL },
								{ label: t('order.paid'), value: true },
								{ label: t('order.notPaid'), value: 'false' },
							]}
							value={this.store.paid}
							onChange={this.store.onPaidChange}
							placeholder={t('order.paidStatus')}
							// empty={t('all')}
							noSearch
						/>
					</div>
					<div className='filters-row'>
						<DatePicker
							value={this.store.startDate0}
							onChange={this.store.onStartDate0Change}
							showTimeSelect={false}
							format='dd.MM.yyyy'
							placeholder={t('order.startDateFrom')}
						/>
						<DatePicker
							value={this.store.startDate1}
							onChange={this.store.onStartDate1Change}
							showTimeSelect={false}
							format='dd.MM.yyyy'
							placeholder={t('order.startDateTo')}
						/>
						<DatePicker
							value={this.store.endDate0}
							onChange={this.store.onEndDate0Change}
							showTimeSelect={false}
							format='dd.MM.yyyy'
							placeholder={t('order.endDateFrom')}
						/>
						<DatePicker
							value={this.store.endDate1}
							onChange={this.store.onEndDate1Change}
							showTimeSelect={false}
							format='dd.MM.yyyy'
							placeholder={t('order.endDateTo')}
						/>
						<RecordSelect
							model={store.model.PriceCategory}
							property='name'
							value={this.store.category}
							onChange={this.store.onCategoryChange}
							placeholder={t('order.category')}
							searchPlaceholder={t('search')}
							searchFields={['name']}
						/>
						{!filtersEmpty && (
							<Button onClick={this.store.clearFilters} variant='primary'>
								{t('clear')}
							</Button>
						)}
					</div>

					{!this.edit ? (
						<div className='new-order'>
							{canViewSum && <CreateButton onClick={() => this.toggleEdit(true)} text={t('order.new')} type='create' />}
						</div>
					) : (
						<div className='new-order'>
							<div className='fields'>
								<textarea
									type='text'
									className='description'
									value={this.store.description}
									onChange={this.store.onDescriptionChange}
									rows='2'
								/>
								<RecordSelect
									model={store.model.OrderList}
									value={this.store.list}
									onChange={this.store.onListChange}
									filter={{ where: this.store.canViewSum ? undefined : { id: { inq: this.store.availableListIds } } }}
									computed={renderList}
									isRequired
								/>
							</div>
							<div className='action'>
								<Button
									className='btn-primary'
									onClick={this.create}
									disabled={this.store.description.length === 0 || !this.store.list}
								>
									{t('add')}
								</Button>
								<Button className='btn-primary' onClick={() => this.toggleEdit(false)}>
									{t('cancel')}
								</Button>
							</div>
						</div>
					)}
				</div>
				{!isReportMode && (
					<div className='orders-mode'>
						<span>{t('order.mode')}:</span>
						<ModeTiles
							className={store.local.ordersMode === ORDERS_MODE_TILES ? 'active' : ''}
							onClick={(e) => this.switch(e, ORDERS_MODE_TILES)}
						/>
						<ModeList
							className={store.local.ordersMode === ORDERS_MODE_LIST ? 'active' : ''}
							onClick={(e) => this.switch(e, ORDERS_MODE_LIST)}
						/>
						<ModeKanban
							className={store.local.ordersMode === ORDERS_MODE_KANBAN ? 'active' : ''}
							onClick={(e) => this.switch(e, ORDERS_MODE_KANBAN)}
						/>
					</div>
				)}
			</div>
		);
	}
}
