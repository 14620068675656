import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Avatar, Popover, Loader } from '@smartplatform/ui';
import { fio } from 'client/tools';
import './style.scss';

@observer
export default class UserAvatar extends React.Component {

	static propTypes = {
		user: PropTypes.object.isRequired,
		size: PropTypes.number,
	};

	static defaultProps = {
		size: 18,
	};

	render() {
		const { user, size, style } = this.props;

		const popup = <>
			<div className="avatar"><Avatar user={user} size={20} /></div>
			<div className="info">
				<div className="fio">{fio(user)}</div>
			</div>
		</>;

		return (
			<Popover
				className="project-member-avatar"
				contentClassName="project-member-popup"
				content={popup}
				style={style}>
				<Avatar user={user} size={size} />
			</Popover>
		);
	}

}
