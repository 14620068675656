import React, { useState } from 'react';
import { Field, Button, Select } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';


export default class ViewImport extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { modelName, path, match } = this.props;
		const modelProperties = store.model?.Contragent?.PROPERTIES;
		const id = parseInt(match.params.id);

		return (
			<ModelEdit model={store.model[modelName]} path={path} id={id} stay>
				<Field property='name' label='Краткое наименование'/>
				<Field property='fullName' label='Полное наименованеи'/>
				<Field property='hiddenFields' label='Скрытые поля'>
					<Select
						items={Object.keys(modelProperties).map(prop => ({
							label: modelProperties[prop]?.description || prop,
							value: prop
						}))}

					/>
				</Field>
			</ModelEdit>
		);
	}

}
const FileLoad = ({ value }) => {
	return <a href={`/api/containers/edfiles/download/${value}`} download>{value}</a>
}
const Reimport = (props) => {
	const [ isLoad, setLoad ] = useState(false);
	const [ message, setMessage ] = useState('-');
	const { value } = props;
	const handleClick = async () => {
		setLoad(true);
		const jobId = await store.model.EdFile.reimportData(value);
		const checkerInterval = setInterval(async () => {
			const job = await store.model.EdFile.checkImportStatus(jobId);
			setMessage(job?.message);
			if(job?.status === 'success' || job?.status === 'error') {
				clearInterval(checkerInterval);
				setLoad(false);
			}
		}, 3500);
	};
	return (
		<>
			<div>Статус: {message}</div>
			<Button
				disabled={isLoad}
				onClick={handleClick}
			>
				Откатиться до этой версии
			</Button>
		</>
	);
}