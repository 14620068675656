import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFile,
	faFolder,
	faInfoCircle,
	faUniversity,
	faUserFriends
} from "@fortawesome/free-solid-svg-icons";
import Info from "./Info";
import Projects from "./Projects";

export default path => ([
	{
		title: <><FontAwesomeIcon icon={faInfoCircle} /> Общая информация</>,
		path: `${path}/:clientId`,
		exact: true,
		component: Info
	},
	// {
	// 	title: <><FontAwesomeIcon icon={faUserFriends} /> Сотрудники</>,
	// 	path: `${path}/:clientId/staff`,
	// 	component: () => <>Сотрудники</>,
	// },
	// {
	// 	title: <><FontAwesomeIcon icon={faUniversity} /> Банковские реквизиты</>,
	// 	path: `${path}/:clientId/bank-details`,
	// 	component: () => <>Банк</>,
	// },
	// {
	// 	title: <><FontAwesomeIcon icon={faFolder} /> Проекты</>,
	// 	path: `${path}/:clientId/projects`,
	// 	component: Projects
	// },
	// {
	// 	title: <><FontAwesomeIcon icon={faFile} /> Документы</>,
	// 	path: `${path}/:clientId/documents`,
	// 	component: () => <>Документы</>,
	// },
]);