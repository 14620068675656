import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import { GroupSelect } from '@smartplatform/ui';
import { User } from 'components';
import store from 'client/store';

// Функция сравнения двух элементов, на основе которой GroupSelect делает вывод, выбран ли элемент.
// В случае с записями из model-store, необходимо передать ее GroupSelect.
// Это нужно для того чтобы GroupSelect не был завязан исключительно на model-store и его моделях.
const comparator = (a, b) => a && b && a.id === b.id;

@observer
export default class GroupSelectTest extends React.Component {

	@observable groups = [];
	@observable selected = [];
	@observable single = null;
	@observable search = '';
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init = debounce(this.init, 250, { leading: true, trailing: true });
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		const users = await store.model.User.find({
			where: this.search.trim() ? { email: { ilike: `%${this.search.trim()}%` }} : undefined,
			order: 'id asc',
		});

		this.groups = [
			{
				title: 'Юзеры с четными id',
				items: users.filter(user => user.id % 2 === 0),
			},
			{
				title: 'Юзеры с нечетными id',
				items: users.filter(user => user.id % 2 !== 0),
			},
		];

		this.isLoading = false;
	};

	onChange = (selected) => {
		console.log('selected count:', selected.length);
		selected.forEach(r => console.log('-', r.id, r.email));
		this.selected = selected;
	};

	renderUser = (user) => <User user={user} />;

	onSearch = (str) => {
		console.log('onSearch', str)
		this.search = str;
		this.init();
	};

	render() {
		return <div>
			<GroupSelect
				groups={this.groups}
				selected={this.selected}
				onChange={this.onChange}
				isLoading={this.isLoading}
				comparator={comparator}
				computed={this.renderUser}
				onSearch={this.onSearch}
				showValue={`Выбрано: ${this.selected.length}`}
				searchPlaceholder="Поиск по email"
				width={300}
			/>
			<GroupSelect
				groups={this.groups}
				selected={this.single}
				onChange={v => this.single = v}
				isLoading={this.isLoading}
				comparator={comparator}
				computed={this.renderUser}
				onSearch={this.onSearch}
				showValue={this.single ? this.renderUser(this.single) : '-'}
				searchPlaceholder="Поиск по email"
				width={300}
			/>
		</div>;
	}

}
