import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Popover, Popconfirm, Portal } from '@smartplatform/ui';
import { fakeDelay } from 'client/tools';
import './style.scss';

@observer
export default class PopoverTest extends React.Component {

	@observable asyncPopup = 'loading...';

	onActivate = async () => {
		await fakeDelay(500);
		this.asyncPopup = 'Popup loaded! long long string'
	};

	onDeactivate = () => {
		this.asyncPopup = 'loading...'
	};

	onConfirm = () => {
		// throw new Error('Thrown error!')
		// return false;
		return { error: 'Error message' };
		console.log('onConfirm');
	};

	render() {
		const props = {
			title: 'title',
			trigger: 'click',
			content: <div><p>content1</p><p>content2</p></div>,
		};

		return <div className="popover-test">
			<div className="block">
				<label>Popconfirm</label>
				<Popconfirm content="Are you sure?" onConfirm={this.onConfirm}>
					<Button>Popconfirm</Button>
				</Popconfirm>
			</div>
			<div className="block">
				<label>Async popup</label>
				<Popover content={this.asyncPopup} onActivate={this.onActivate} onClose={this.onDeactivate}><Button>Popover</Button></Popover>
			</div>
			<Portal className="popover-test-frame" style={{ width: 500, height: 300, left: 1170, top: 100 }}>
				<div className="test-row">
					<div className="block"/>
					<div className="block"><Popover position="topLeft" {...props}><Button>topLeft</Button></Popover></div>
					<div className="block center"><Popover position="top" {...props}><Button>top</Button></Popover></div>
					<div className="block right"><Popover position="topRight" {...props}><Button>topRight</Button></Popover></div>
					<div className="block"/>
				</div>
				<div className="test-row">
					<div className="block"><Popover position="leftTop" {...props}><Button>leftTop</Button></Popover></div>
					<div className="block"/>
					<div className="block"/>
					<div className="block"/>
					<div className="block right"><Popover position="rightTop" {...props}><Button>rightTop</Button></Popover></div>
				</div>
				<div className="test-row">
					<div className="block"><Popover position="left" {...props}><Button>left</Button></Popover></div>
					<div className="block"/>
					<div className="block"/>
					<div className="block"/>
					<div className="block right"><Popover position="right" {...props}><Button>right</Button></Popover></div>
				</div>
				<div className="test-row">
					<div className="block"><Popover position="leftBottom" {...props}><Button>leftBottom</Button></Popover></div>
					<div className="block"/>
					<div className="block"/>
					<div className="block"/>
					<div className="block right"><Popover position="rightBottom" {...props}><Button>rightBottom</Button></Popover></div>
				</div>
				<div className="test-row">
					<div className="block"/>
					<div className="block"><Popover position="bottomLeft"{...props}><Button>bottomLeft</Button></Popover></div>
					<div className="block center"><Popover position="bottom"{...props}><Button>bottom</Button></Popover></div>
					<div className="block right"><Popover position="bottomRight"{...props}><Button>bottomRight</Button></Popover></div>
					<div className="block"/>
				</div>
			</Portal>
		</div>;
	}

}
