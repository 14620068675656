import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';

import { Kanban } from '@smartplatform/ui';
import ProjectCard from './ProjectCard';
import store from 'client/store';
import { formatDate, fio } from 'client/tools';
import t from 'i18n';
import './style.scss';

export const INITIAL_LIMIT = 20;
export const DEFAULT_PRIORITY = 10000;
const PROJECT_INCLUDE = [
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color'],
		},
	},
	{
		relation: 'members',
		scope: {
			order: 'id asc',
			limit: 5,
			where: { userId: { neq: null } },
			include: [
				{
					relation: 'user',
					fields: ['id', 'avatar', 'lastName', 'firstName', 'middleName'],
				},
			],
		},
	},
];

@inject('store')
@observer
export default class ProjectsKanban extends React.Component {
	@observable lists = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.doSearch;
		this.init();
	}

	init = async () => {
		const projectsWhere = this.compileFilter();
		const lists = await store.model.ViewProjectList.find({
			include: [
				{
					relation: 'projects',
					scope: {
						where: projectsWhere,
						limit: INITIAL_LIMIT,
						order: 'priority desc',
						include: PROJECT_INCLUDE,
					},
				},
			],
			order: 'priority desc',
		});

		for (let list of lists) {
			list.totalCount = await store.model.ViewProject.count({
				and: [...projectsWhere.and, { projectListId: list.id }],
			});
			// console.log('count', list.totalCount);
		}
		this.lists = lists;
		this.isLoading = false;
	};

	doSearch = async () => {
		await this.init();
		if (this.kanban) {
			this.kanban.reload();
		}
	};

	renderProject = (project, list, options) => <ProjectCard project={project} list={list} {...options} />;

	@action onChange = async ({ item, text, prev, next, list, index }) => {
		console.log('onChange', item, prev, next, list, index);
		this.saving = true;

		// const project = new store.model.Project(item);
		const project = !item
			? new store.model.Project(item)
			: await store.model.Project.findById(item.id, {
					include: PROJECT_INCLUDE,
			  });

		project.projectListId = list.id;
		project.closed = list.closing || false;
		if (list.closing) project.closedAt = new Date();

		if (!item) {
			project.name = text;
		} else {
			project.membersCount = item.membersCount;
			project.tasksCount = item.tasksCount;
			project.openedCount = item.openedCount;
			project.closedCount = item.closedCount;
		}

		if (!prev) {
			project.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		} else if (!next) {
			project.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		} else {
			project.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await project.save();
			this.saving = false;
		} catch (e) {
			this.saving = false;
			throw e;
		}

		// TODO: возвращать данные из item, а не project?

		return {
			id: project.id,
			listId: project.boardListId,
			path: `/projects/${project.id}`,
			data: project,
		};
	};

	gotoItem = (path) => store.route.push({ path });

	fakeDelay = (delay = 1000) => new Promise((resolve) => setTimeout(resolve, delay));

	getInstance = (instance) => (this.kanban = instance);

	compileFilter = () => {
		const where = {
			and: [{ projectListId: { neq: null } }],
		};

		const trimmed = this.store.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach((word) =>
				where.and.push({
					or: [{ id: { like: `${word}%` } }, { name: { ilike: `%${word}%` } }],
				})
			);
		}

		if (this.store.user) {
			where.and.push({ membersIds: { like: `%[${this.store.user.id}]%` } });
		}

		return where;
	};

	loadMore = async (list) => {
		const projectsWhere = this.compileFilter();
		console.log('loadMore', list.items.length);

		const moreProjects = await store.model.ViewProject.find({
			where: {
				and: [...projectsWhere.and, { projectListId: list.id }],
			},
			order: 'priority desc',
			limit: INITIAL_LIMIT,
			skip: list.items.length,
			include: PROJECT_INCLUDE,
		});

		return moreProjects.map((project) => ({
			id: project.id,
			listId: project.projectListId,
			path: `/projects/${project.id}`,
			data: project,
		}));
	};

	render() {
		if (this.isLoading) return null;

		const project = {
			name: t('project.plural'),
			lists: this.lists.map((list) => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.totalCount,
					data: list,
					items: list.projects().map((project) => {
						// console.log('>', project.members(), project.labels());
						return {
							id: project.id,
							listId: project.projectListId,
							path: `/projects/${project.id}`,
							data: project,
						};
					}),
				};
			}),
		};

		return (
			<div className='projects-kanban'>
				{/*
			<div className="filters">
				<input value={this.search} onChange={this.onSearch} placeholder={t('project.filterHint')} />
			</div>
*/}
				<div className='projects-kanban-container'>
					{this.lists.length > 0 ? (
						<Kanban
							project={project}
							renderItem={this.renderProject}
							itemClassName='project-kanban-card'
							onChange={this.onChange}
							getInstance={this.getInstance}
							loadMore={this.loadMore}
							onError={this.onError}
							gotoItem={this.gotoItem}
							itemsLimit={20}
						/>
					) : (
						<>
							Не заданы <Link to={`/projectslists`}>состояния проектов</Link>
						</>
					)}
				</div>
			</div>
		);
	}
}

