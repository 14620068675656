import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ToolbarCreateBtn } from './ToolbarCreateBtn';
import { ToolbarExportBtn } from './ToolbarExportBtn';
import { ToolbarCount } from './ToolbarCount';
import { ToolbarSearch } from './ToolbarSearch';
import { ToolbarMembers } from './ToolbarMembers';
import { ToolbarUser } from './ToolbarUser';
import './style.scss';

export const Toolbar = observer(({ children, className }) => (
	<div className={classNames('toolbar', { [`${className}`]: className ? true : false })}>{children}</div>
));

Toolbar.CreateBtn = ToolbarCreateBtn;
Toolbar.ExportBtn = ToolbarExportBtn;
Toolbar.Count = ToolbarCount;
Toolbar.Search = ToolbarSearch;
Toolbar.Members = ToolbarMembers;
Toolbar.User = ToolbarUser;

