import { observable } from 'mobx';
import store from 'client/store';
import { ALL, LOCAL_STORAGE_FIELDS, NONE, TASKS_INCLUDE } from './constants';

export class TasksStore {
	@observable page = 1;
	@observable closed = false;
	@observable user = store.model.user;
	@observable boardLists = [];
	@observable records = [];
	@observable isLoading = true;
	@observable order = 'updatedAt desc';

	constructor() {
		this.loadLocalStorage();
	}

	fetchRecords = async () => {
		const where = { and: [] };

		if (this.closed === true) {
			where.and.push({ closed: true });
		} else if (this.closed === false) {
			where.and.push({
				or: [{ closed: false }, { closed: null }],
			});
		}

		if (this.boardLists.length) where.and.push({ boardListId: { inq: this.boardLists.map((i) => i.id) } });
		if (this.user !== ALL) where.and.push({ userId: this.user === NONE ? null : this.user.id });

		this.records = await store.model.ViewTask.find({
			where,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			order: this.order,
			include: TASKS_INCLUDE,
		});
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		if (prop !== 'page') {
			this.page = 1;
		}
		if (prop === 'boardLists') {
			const closedIndex = this[prop].findIndex((i) => i.closing);
			closedIndex === -1 ? this.onChange('closed')(false) : this.onChange('closed')(null);
		}
		this.saveToLocalStorage();
		this.fetchRecords();
	};

	cleanFilters = () => {
		this.user = store.model.user;
		this.order = 'updatedAt desc';
		this.boardLists = [];
		this.closed = false;
		this.page = 1;
		this.saveToLocalStorage();
		this.fetchRecords();
	};

	get perPage() {
		let delta;
		if (window.innerWidth < 768) delta = 285;
		else if (window.innerWidth < 992) delta = 225;
		else delta = 155;
		const cellHeight = window.innerWidth < 992 ? 80 : 65;
		return Math.floor((window.innerHeight - delta) / cellHeight);
	}

	//localstorage
	localStorage = store.local.tasks;
	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		if (storageKeys.length) {
			storageKeys.forEach((key) => {
				const storageValue = this.localStorage[key];
				if (['boardLists'].includes(key) && !storageValue) {
					this[key] = [];
				} else this[key] = storageValue;
				if (key === 'user' && ![ALL, NONE].includes(storageValue)) {
					this[key] = new store.model.User(storageValue);
				} else this[key] = storageValue;
			});
		}
	};
}

