import React from 'react';
import { observer, Provider } from 'mobx-react';
import DocumentsStore from './store';
import DocumentsList from './documents-list/DocumentsList';
import DocumentsToolbar from './documents-toolbar/DocumentsToolbar';
import DocumentsKanban from './documents-kanban/DocumentsKanban';
import { MODES } from 'client/constants';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Documents extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('document.plural');
		this.store = new DocumentsStore();
		this.store.init();
	}

	render() {
		if (this.store.isLoading) return null;
		const content = this.store.mode === MODES.KANBAN ? <DocumentsKanban /> : <DocumentsList {...this.props} />;
		return (
			<Provider store={this.store}>
				<div className='documents-root'>
					<DocumentsToolbar />
					<hr />
					{content}
				</div>
			</Provider>
		);
	}
}

