import React from 'react';

import { Field, ColorPicker } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title={t('label.title')}>
		<Field property="date" label={t('name')} />
		<Field property="status" label={t('color')}><ColorPicker /></Field>
		<Field property="message" label={t('description')}><textarea rows={5} /></Field>
	</ModelEdit>;
};
