import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Table, Column, RelationSelect, Checkbox } from '@smartplatform/ui';
import { DeleteButton, AddButton } from 'components';
import DownIcon from '!!svg-react-loader!client/img/down-btn.svg';
import store from 'client/store';
import TYPES from '../features/types';
import t from 'i18n';

@withRouter @observer
export default class Features extends React.Component {

	@observable priceCategory = null;
	@observable priceCategoryFeatures = [];
	@observable error = null;

	constructor(props) {
		super(props);
		this.id = parseInt(this.props.match.params.id);
		this.init();
	}

	init = async () => {
		if (this.id) {
			try {
				this.priceCategory = await store.model.PriceCategory.findById(this.id, {
					include: [
						{
							relation: 'priceCategoryFeatures',
							scope: {
								where: { featureId: { neq: null }},
								include: [
									{ relation: 'feature', scope: { fields: ['id', 'name', 'type', 'values'] }},
								],
								order: 'weight asc',
							}
						},
					],
				});
				this.priceCategoryFeatures = this.priceCategory.priceCategoryFeatures();
				// console.log('init', this.priceCategoryFeatures.map(f => f.id));

			} catch (e) {
				this.error = e.message;
			}
		}
	}

	addFeature = async () => {
		const priceCategoryFeature = new store.model.PriceCategoryFeature();
		priceCategoryFeature.feature = null;
		priceCategoryFeature.category = this.priceCategory;
		await priceCategoryFeature.save();
		priceCategoryFeature.weight = priceCategoryFeature.id;
		this.priceCategoryFeatures.push(priceCategoryFeature);
	};

	swap = async (priceCategoryFeature, other) => {
		// console.log('swap', priceCategoryFeature.weight, other.weight);
		const weight = priceCategoryFeature.weight;
		priceCategoryFeature.weight = other.weight;
		await priceCategoryFeature.save();
		other.weight = weight;
		await other.save();
		await this.init();
	};

	deleteFeature = async (i, priceCategoryFeature) => {
		this.priceCategoryFeatures.splice(i, 1);
		await priceCategoryFeature.delete();
		await this.init();
	};

	renderWeightControl = ({ record, row }) => {
		const prev = row > 0 ? this.priceCategoryFeatures[row - 1] : null;
		const next = row < this.priceCategoryFeatures.length - 1 ? this.priceCategoryFeatures[row + 1] : null;
		return <>
			<DownIcon className="up-btn" onClick={() => this.swap(record, prev)} disabled={!prev}/>
			<DownIcon className="down-btn" onClick={() => this.swap(record, next)} disabled={!next}/>
		</>;
	};

	onRequiredChange = async (record, value) => {
		record.required = value;
		await record.save();
	};

	renderRequiredControl = ({ record, row }) => {
		const priceCategoryFeature = this.priceCategoryFeatures[row];
		const feature = priceCategoryFeature.feature;
		if (!feature) return null;
		return feature.type !== TYPES.BOOLEAN ? <Checkbox value={record.required} onChange={v => this.onRequiredChange(record, v)} /> : null;
	};

	renderActions = ({ record, row }) => {
		return <DeleteButton onConfirm={() => this.deleteFeature(row, record)} />
	};

	featureItem = feature => {
		return feature ?
			<span className="feature-item"><strong>{feature.name}</strong><small>{t(`feature.${feature.type}`)}</small></span>
			:
			'';
	}

	render() {
		if (!this.id) return null;
		if (this.error) return <div className="error">{this.error}</div>;
		// console.log('render', this.priceCategoryFeatures.length);

		return <div className="table-list">
			<Table rows={this.priceCategoryFeatures} key={this.priceCategoryFeatures.length}>
				<Column width={50} label={t('number')}>{params => params.row + 1}</Column>
				<Column relation="feature" label={t('feature.title')} saveOnChange><RelationSelect computed={this.featureItem} /></Column>
				<Column width={100} property="required" label={t('feature.required')} saveOnChange className="text-center">{this.renderRequiredControl}</Column>
				<Column width={120} label={t('feature.weight')} className="text-center">{this.renderWeightControl}</Column>
				<Column width={100} className="text-right">{this.renderActions}</Column>
			</Table>
			<AddButton onClick={this.addFeature} />
		</div>;
	}
};
