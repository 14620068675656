import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Loader } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class ConvertFeatures extends React.Component {

	@observable features = [];
	@observable pricePositions = [];
	@observable orderPositions = [];
	@observable isLoading = true;
	@observable isConverting = false;

	constructor(props) {
		super(props);
		store.ui.title = 'FeatureValues';
		this.init();
	}

	init = async () => {
		const features = await store.model.Feature.find({
			where: { type: 'list' },
			order: 'id asc',
		});
		for (let feature of features) {
			feature.__priceCount = await store.model.PricePositionValue.count({
				featureId: feature.id,
			});
			feature.__orderCount = await store.model.OrderPositionValue.count({
				featureId: feature.id,
			});
		}
		this.features = features;
		this.isLoading = false;
	};

	convert = async () => {
		this.isConverting = true;
		for (const feature of this.features) {
			if (feature.values && feature.values.length > 0) {
				console.log('>>> checking feature', feature);
				for (const value of feature.values) {
					let featureValueId = null;
					const existingValues = await store.model.FeatureValue.find({
						where: {
							featureId: feature.id,
							name: value,
						},
					});
					if (existingValues.length > 0) {
						console.log('>>', value, 'exists', existingValues[0]);
						featureValueId = existingValues[0].id;
					}
					else {
						const featureValue = new store.model.FeatureValue();
						featureValue.name = value;
						featureValue.featureId = feature.id;
						await featureValue.save();
						const { id, name, featureId } = featureValue;
						console.log('>> created FeatureValue', { id, name, featureId });
						featureValueId = id;
					}

					const pricePositionValues = await store.model.PricePositionValue.find({
						where: {
							featureId: feature.id,
							stringValue: value,
							featureValueId: null,
						},
						fields: [ 'id', 'featureId', 'stringValue', 'featureValueId' ],
					})
					if (pricePositionValues.length > 0) {
						console.log('> converting', pricePositionValues.length, 'pricePositionValues...');
						for (const pricePositionValue of pricePositionValues) {
							await pricePositionValue.patchAttributes({ featureValueId });
						}
						console.log('...done');
					}

					const orderPositionValues = await store.model.OrderPositionValue.find({
						where: {
							featureId: feature.id,
							stringValue: value,
							featureValueId: null,
						},
						fields: [ 'id', 'featureId', 'stringValue', 'featureValueId' ],
					})
					if (orderPositionValues.length > 0) {
						console.log('> converting', orderPositionValues.length, 'orderPositionValues...');
						for (const orderPositionValue of orderPositionValues) {
							await orderPositionValue.patchAttributes({ featureValueId });
						}
						console.log('...done');
					}
				}
			}
		}
		console.log('...ALL DONE');
		this.isConverting = false;
	};

	render() {
		if (this.isLoading) return '...';

		return <div className="convert-features">
			<div>
				<Button onClick={this.init}>Reload</Button>
				<Button onClick={this.convert} variant="primary">Convert</Button>
				{this.isConverting && <span><Loader size={24} /> Converting...</span>}
				<br/><br/>
			</div>

			<table className="features">
				<thead>
				<tr>
					<th>Название</th>
					<th>Значения</th>
					<th>Позиций прайс-листа</th>
					<th>Позиций заказов</th>
				</tr>
				</thead>
				<tbody>
					{this.features.map(feature => <tr key={feature.id}>
						<td>{feature.name}</td>
						<td>{feature.values ? feature.values.join(', ') : '-'}</td>
						<td>{feature.__priceCount}</td>
						<td>{feature.__orderCount}</td>
					</tr>)}
				</tbody>
			</table>
		</div>;
	}

}
