import React from "react";
import { Switch, Route } from 'react-router-dom';
import store from 'client/store';
import List from './List';
import Edit from './Edit';
const path = '/1c-nodes';
const modelName = 'EdNode';

export const EdNodes = () => {
    store.ui.title = "Узлы обмена";
    return (
        <Switch>
            <Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />}/>
            <Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />}/>
        </Switch>
    );
};
