import React, { useEffect, useState } from 'react';
import t from 'i18n';
import store from 'client/store';
import { Form,	Field, Loader, Row } from '@smartplatform/ui';
import Versions from './Versions';
import './style.scss';
export const EdOptions = props => {
    const [ record, setRecord ] = useState();
    const [ error, setError ] = useState();
    store.ui.title = t('enterprisedata.settings.title');
    const init = async () => {
        console.log(store);
        try {
            const data = await store.model.EdOption.find()
            if (data.length > 0) {
                setRecord(data[0]);
            } else {
                setRecord(new store.model.EdOption());
            }
        } catch(e) {
            setError(e);
        }
    }
    useEffect(() => {
        init();
    }, []);

    if(error) return <div>{JSON.stringify(error)}</div>
    if(!record) return <Loader />
    return(
        <div className="fixed-page">
            <h2 className="mb-4 mt-2">Поддерживаемые версии формата обмена</h2>
            <Versions />
            <h2 className="mb-4 mt-3">Общие параметры данного узла</h2>
            <Form record={record} stay noDelete>
                <Row>
                    <div>
                        <Row className="mt-3">
                            <Field property="nodeName" label="Наименование информационной базы" />
                            <Field property="nodeDefaultName" label="Наименование по умолчанию" />
                        </Row>
                        <Row>
                            <Field property="nodePrefix" label="Префикс информационной базы" />
                            <Field property="nodeCode" label="Код этого узла" />
                        </Row>
                        <Row>
                            <Field 
                                property="dataMappingSupported" 
                                label="Использовать префиксы"
                                className="d-flex flex-direction flex-row-reverse justify-content-end"
                            />
                            <Field 
                                property="usePrefixes" 
                                label="Поддерживается сопоставление данных" 
                                className="d-flex flex-direction flex-row-reverse justify-content-end"
                            />
                        </Row>
                    </div>
                    <div></div>
                </Row>
            </Form>
        </div>
    );
}
