import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ModelList } from 'components';
import t from 'i18n';
import React from 'react';

export default ({ modelName, path, title, relation, relationLabel, searchValue }) => {
	return (
		<ModelList
			noHeader
			noFilters
			searchValue={searchValue}
			model={store.model[modelName]}
			path={path}
			title={title}
			filter={{ include: relation }}
		>
			<Column property='name' label={t('name')} />
			<Column relation={relation} property='name' label={t(relationLabel)} />
		</ModelList>
	);
};
