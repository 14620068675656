import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import History from './history/History';
import MainEdit from './MainEdit';
import Client from './Client';

@inject('store') @observer
export default class Main extends React.Component {

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	historyInstance = instance => this.store.historyInstance = instance;

	render() {
		const { availableListIds } = this.props;
		return <div className="order-main fixed-page">
			<div className="left">
				<MainEdit store={this.store} availableListIds={availableListIds} />
				<History order={this.store.order} canViewSum={this.store.canViewSum} instance={this.historyInstance} onChange={this.store.updateHistory} />
			</div>
			<div className="right">
				<Client />
			</div>
		</div>;
	}

}
