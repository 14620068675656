import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DocumentsList from './Documents';
import './style.scss';
import SinglePage from './single-page';

const path = '/documents';
const modelNameView = 'ViewDocument';
const modelNameEdit = 'Document';

export const Documents = () => <Switch>
	<Route path={path} exact><DocumentsList path="" modelName={modelNameView} /></Route>
	<Route path={`${path}/page/:page`} children={({ match }) => <DocumentsList path="" match={match} modelName={modelNameView} />}/>
	<Route path={`${path}/:id`} children={({ match }) => <SinglePage match={match} path={path} modelName={modelNameEdit} />}/>
	<Route path={`${path}/create`} children={({ match }) => <SinglePage match={match} path={path} modelName={modelNameEdit} />}/> />
</Switch>;
