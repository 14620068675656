import React from 'react';

import { Row, Field, ImageInput } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title={t('person.title')}>
		<Row>
			<Field property="lastName" label={t('lastName')}/>
			<Field property="firstName" label={t('firstName')}/>
			<Field property="middleName" label={t('middleName')}/>
		</Row>
		<Field rich={true} property="paymentInfo" label={t('paymentInfo')}><textarea rows={5} /></Field>
		<Field property="comment" label={t('comment.title')}><textarea rows={5} /></Field>
		<Field><ImageInput property="avatar" width={256} height={256} label={t('avatar')}/></Field>
	</ModelEdit>;
};
