import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Pager, Select } from '@smartplatform/ui';
import store from 'client/store';
import OrderTile from './OrderTile';
import t from 'i18n';
import './style.scss';

const ORDER_INCLUDE = [
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'list', scope: { fields: ['id', 'name', 'color'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } }
			],
			order: 'id asc',
		}
	},
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color']
		}
	},
];

const SORTING_VARIANTS = [
	{ label: <>&uarr; {t('sorting.newFirst')}</>, value: 'id desc' },
	{ label: <>&darr; {t('sorting.oldFirst')}</>, value: 'id asc' },
	{ label: <>&uarr; {t('sorting.priceAsc')}</>, value: 'total desc' },
	{ label: <>&darr; {t('sorting.priceDesc')}</>, value: 'total asc' },
	{ label: <>&uarr; {t('order.startDate')}</>, value: 'startDate desc' },
	{ label: <>&darr; {t('order.startDate')}</>, value: 'startDate asc' },
	{ label: <>&uarr; {t('order.endDate')}</>, value: 'endDate desc' },
	{ label: <>&darr; {t('order.endDate')}</>, value: 'endDate asc' },
];

const PER_PAGE = 20;

@inject('store') @observer
export default class OrdersTiles extends React.Component {

	@observable orders = [];
	@observable pageSize = PER_PAGE;
	@observable page = 1;
	@observable totalCount = 0;
	@observable sorting = SORTING_VARIANTS[0].value;

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.init;
		this.init();
	}

	init = async () => {
		this.orders = await store.model.ViewOrder.find({
			where: this.store.compileFilter(this.store.availableListIds),
			include: ORDER_INCLUDE,
			skip: (this.page - 1) * this.pageSize,
			limit: this.pageSize,
			order: this.sorting,
		});
	};

	@action onPageChange = page => {
		this.page = page;
		this.init();
	};

	@action onSortingChange = value => {
		this.sorting = value;
		this.page = 1;
		this.init();
	};

	render() {
		return <div className="orders-tiles">
			<div className="pager-and-sort">
				<Pager itemsPerPage={this.pageSize} current={this.page} onChange={this.onPageChange} totalCount={this.orders.totalCount} />
				<Select items={SORTING_VARIANTS} value={this.sorting} onChange={this.onSortingChange} isRequired />
			</div>
			{this.orders.map(order => <OrderTile key={order.id} order={order} canViewSum={this.props.store.canViewSum} />)}
		</div>;
	}

}
